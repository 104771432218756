
import get from 'lodash/get';

export const downloadBlob = (fileName, mimeType, data) => {
    const blob = new Blob([data], {type: mimeType});
    const url = window.URL.createObjectURL(blob);
    downloadURL(url, fileName, mimeType);
    setTimeout(() => window.URL.revokeObjectURL(url), 1000);
};

export const downloadURL = (data, fileName) => {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
};

export const getCSV = (header, arr) => {
    const stringify = str => '"' + (str == null ? '' : str).toString().replace(/"/g, '""') + '"'
    const makeRow = val => header.map(key => stringify(val[key])).join(',')
    const headerObj = {}
    header.forEach(key => { headerObj[key] = key })
    return [makeRow(headerObj)].concat(arr.map(makeRow)).join('\n')
}

export const downloadCSV = (name, header, arr) =>
    downloadBlob(name, 'text/csv', getCSV(header, arr));
