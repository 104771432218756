
import _ from 'lodash';

const colors = {
    offwhite: 'rgb(246, 246, 246)',
    green: 'rgb(0, 224, 139)',
    blue: 'rgb(0, 197, 254)',
    offblack50: 'rgb(142, 148, 170)',
    yellow: 'rgb(255, 183, 0)',
    magenta: 'rgb(255, 37, 110)',
};

export const flagInfo = {
    none: {
        key: 'none',
        name: 'None',
        sort: 0,
        label: null,
        color: colors.offwhite,
    },
    good: {
        key: 'good',
        name: 'Good',
        sort: 1,
        label: 'Highlighted for you',
        color: colors.green,
    },
    amazing: {
        key: 'amazing',
        name: 'Exceptional',
        sort: 2,
        label: 'Exceptional for you',
        color: colors.blue,
    },
    invalid: {
        key: 'invalid',
        name: 'Invalid',
        sort: 3,
        label: 'No opinion',
        color: colors.offblack50,
    },
    warn: {
        key: 'warn',
        name: 'Warn',
        sort: 4,
        label: 'Warning for you',
        color: colors.yellow,
    },
    bad: {
        key: 'bad',
        name: 'Bad',
        sort: 5,
        label: 'Flagged for you',
        color: colors.magenta,
    },
    terrible: {
        key: 'terrible',
        name: 'Terrible',
        sort: 6,
        label: 'Terrible for you',
        color: colors.magenta,
    },
};

export const flagEnum = Object.keys(flagInfo);

export const flagCompare = (a, b) => getFlagInfo(b.key).sort - getFlagInfo(a.key).sort;

const mapping = { true: 'good', false: 'bad' };

export const getFlagInfo = type => flagInfo[type] || flagInfo[mapping[type]];
