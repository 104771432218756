import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import {withPropsOnChange, withState} from 'recompose';

import { parseQuery, getExpression, getRandomName } from '../../utils/utils';
import WithFormulaData from '../../decorators/WithFormulaData';

import {CheckerMessage} from '../CheckerExpression';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea/TextArea';
import TextField from '../../components/TextField/TextField';
import TextInput from '../../components/TextInput';
import { InlineCheckerMessage, Label as CheckerLabel } from '../CheckerExpression';

import './ExpressionSelector.scss';
import {
    AddArtifacts,
    Content,
    Expression,
    Extended,
    ExtendedContent,
    Label,
    LabelCollapse,
    Log,
    Remove,
    RootContainer,
    Toggle,
} from './styles';
import ExpressionOptions from './ExpressionOptions';

const DEBUG = parseQuery().debug === 'true';

const blockOperations = _.keyBy(['and', 'or', 'if', 'for', 'call', 'checker-list', 'checker-table']);
export const opIsBlock = ($op) => !!blockOperations[$op];

@WithFormulaData
@withPropsOnChange(['data'], ({data}) => ({
    isReadOnly: !!data.state.readOnly,
}))
class ExpressionSelector extends PureComponent {
    static propTypes = {
        noRemove: PropTypes.bool,
        path: PropTypes.string.isRequired,
        data: PropTypes.shape({
            $op: PropTypes.string.isRequired,
            log: PropTypes.object.isRequired,
            availableFunctions: PropTypes.object.isRequired,
            availableVariables: PropTypes.object.isRequired,
            state: PropTypes.shape({
                isChecker: PropTypes.bool.isRequired,
                otherOperations: PropTypes.array,
                actions: PropTypes.object.isRequired,
            }).isRequired,
        }).isRequired,
        isReadOnly: PropTypes.bool.isRequired,
        depth: PropTypes.number,
    }

    state = {
        showOptions: false,
        highlight: false,
        showArtifacts: false,
        forceShowArtifacts: false,
        selfCollapsed: null,
    }

   UNSAFE_componentWillReceiveProps (nextProps) {
        if (nextProps.data !== this.props.data && (!nextProps.data.log || !nextProps.data.log.hasLog)) {
            this.setState({ showArtifacts: false, forceShowArtifacts: false });
        }
    }

    get isCollapsed() {
        const { selfCollapsed } = this.state;
        const { data: { defaultCollapsed } } = this.props;

        return selfCollapsed != null ? selfCollapsed : defaultCollapsed;
    }

    updateComment () {
        const {data} = this.props;
        if (!this._update || !Object.keys(this._update).length) return;
        data.change(this._update);
    }

    onChange = ({target: {value}}) => {
        const {data} = this.props;

        if (value === 'clipboard') return data.clipboardPaste();

        if (!value.includes(':')) return data.replace(value);

        const [name, ...args] = value.split(':');
        data.replace(name, args);
    }

    onNotChange = ({target: {checked}}) => this.props.data.change('$not', checked)

    getInputHandler (key) { return event => this._update[key] = event.target.value.trim(); }

    handleShowMenu = () => {
        if (this.props.isReadOnly) return;
        this._update = {};
        this.setState({showOptions: true});
    }

    handleHideMenu = () => {
        this.setState({showOptions: false});
        this.updateComment();
    }

    handleUpdateComment = () => this.updateComment()

    handleCollapseToggle = () => {
        const next = !this.isCollapsed;

        this.setState({
            selfCollapsed: next === this.props.data.defaultCollapsed ? null : next,
        });
    }

    renderLabel({ Klass, displayProps, isBlock }) {
        const { data, noRemove, isReadOnly } = this.props;
        const { showOptions, showArtifacts, forceShowArtifacts } = this.state;

        return <Label
            key='label'
            {...displayProps}
            {...(!data.log.hasLog ? null : {
                onMouseEnter: event => {
                    event.stopPropagation();
                    this.setState({ showArtifacts: true });
                },
                onMouseLeave: event => {
                    event.stopPropagation();
                    this.setState({ showArtifacts: false });
                },
                onClick: () => this.setState({ forceShowArtifacts: !forceShowArtifacts }),
            })}
        >
            {/* <code>{data.path}{' | '}</code>{' '} */}
            {isBlock &&
                <LabelCollapse onClick={this.handleCollapseToggle}>
                    {this.isCollapsed ? '[+]' : '[-]'}
                </LabelCollapse>
            }
            <Actions
                data={data}
                noRemove={noRemove}
                isReadOnly={isReadOnly}
                showOptions={showOptions}
                handleShowMenu={this.handleShowMenu}
                handleHideMenu={this.handleHideMenu}
                onChange={this.onChange}
                onNotChange={this.onNotChange}
                extra={!Klass.renderActions ? null : Klass.renderActions(data)}
            />

            {data.log.hasLog && (showArtifacts || forceShowArtifacts)
                ? <Artifacts key='log' log={data.log} />
                : null
            }
        </Label>;
    }

    render () {
        const { data, noRemove, isReadOnly, path, dispatch, isRoot: iDontCare, dontIndent, ...rest } = this.props;

        const Klass = getExpression(data.$op);

        const isRoot = dontIndent || !data.state.parentType;
        const extendedLayout = !!(data.$comment || data.$checker || data.$artifacts);
        const isBlock = opIsBlock(data.$op);
        const isCollapsed = isBlock && this.isCollapsed;

        const displayArgs = {
            type: data.$op,
            parentType: data.state.parentType,
            display: isBlock && !isCollapsed ? 'block' : 'inline',
            root: isRoot,
            result: !data.log.hasLog ? '' : data.log.result,
            parentResult: !data.log.hasLog ? '' : data.log.parentResult,
            lastChild: isRoot ? '' : this.props['data-last-child'],
            extendedLayout,
        };

        const displayProps = getDisplayProps(displayArgs);

        const renderLabel = () => this.renderLabel({ Klass, displayProps, isBlock });

        const renderContent = () =>
            isCollapsed
                ? <Content Tag='i' key='content' {...displayProps} data-display='inline'>(collapsed)</Content>
                : <Content key='content' {...displayProps}>
                    <Klass data={data} />
                </Content>;

        const child = (
            <Container
                key={data.ID}
                displayArgs={displayArgs}
                {...rest}
            >
                {!extendedLayout ? [renderLabel(), renderContent()] :
                    <div>
                        <Extended {...displayProps}>
                            {!data.$comment ? null :
                                <TextArea
                                    defaultValue={data.$comment}
                                    onChange={text => data.change('$comment', text || undefined)}
                                    className='ExpressionSelector-comment'
                                    readOnly={isReadOnly}
                                />
                            }

                            {!data.$checker ? null : <InlineChecker data={data} /> }

                            {!data.$artifacts ? null :
                                <div className='row' style={{ margin: 0 }}>
                                    <AddArtifacts>
                                        <Remove onClick={() => data.change('$artifacts', undefined)} />
                                        <span>Store artifacts in:</span>
                                        <TextInput
                                            defaultValue={data.$artifacts}
                                            onChange={value => {
                                                value = value.replace(/[^a-z_]/gi, '').trim();
                                                value = value || getRandomName();
                                                data.change('$artifacts', value);
                                            }}
                                        />
                                    </AddArtifacts>
                                </div>
                            }
                        </Extended>

                        <ExtendedContent {...displayProps}>
                            {renderLabel()}
                            {renderContent()}
                        </ExtendedContent>
                    </div>
                }
            </Container>
        );

        if (!isRoot) return child;

        return <RootContainer data-colors={data.log.hasLog ? 'log' : 'colors'}>
            {child}
        </RootContainer>;
    }
}

export default ExpressionSelector;

const getDisplayProps = ({ type, parentType, display, root, result, parentResult, lastChild, extendedLayout }) => ({
    'data-type': type,
    'data-parent-type': !parentType ? 'null' : parentType,
    'data-display': display,
    'data-root': root ? '' : undefined,
    'data-result': result == null ? 'null' : String(Boolean(result)),
    'data-parent-result': parentResult == null ? 'null' : String(Boolean(parentResult)),
    'data-last-child': root ? '' : lastChild,
    'data-extended': extendedLayout ? '' : undefined,
})

export const Container = ({
    displayArgs = {},
    label,
    children,
    ...props
}) =>
    <Expression
        {...getDisplayProps(displayArgs)}
        {...props}
    >
        {!label ? null :
            <Label {...getDisplayProps(displayArgs)}>{label}</Label>
        }
        {children}
    </Expression>;

const Actions = ({
    data,
    isReadOnly,
    handleShowMenu,
    handleHideMenu,
    onNotChange,
    onChange,
    noRemove,
    showOptions,
    extra
}) => {
    const opValue = data.$op === 'fn' ? `fn:${data.$value}` :
        data.$op === 'call' ? `call:${data.$value}` :
        data.$op;

    return (
        <div className={'ExpressionSelector-actions'}>
            <div
                onMouseLeave={handleHideMenu}
                className={cx('ExpressionSelector-actionsInner')}
            >
                {!data.$not ? null : <div className='ExpressionSelector-not'>not</div>}

                <Select
                    value={opValue}
                    onChange={onChange}
                    options={data.operationOptions.filter(x => x[2] !== 'Deprecated' || x[0] === 'schema')}
                    onMouseEnter={handleShowMenu}
                    readOnly={isReadOnly}
                />

                {extra}

                {showOptions &&
                    <ExpressionOptions data={data} onNotChange={onNotChange} noRemove={noRemove} />
                }
            </div>
        </div>
    );
}

const Artifacts = withState('extended', 'setExtended', false)(
    ({log, extended, setExtended}) => {
        const print = obj =>
            JSON.stringify(obj, null, 4)
                .split('\n')
                .slice(1, -1)
                .map(line => line.slice(4))
                .join('\n');

        const getVal = obj =>
            extended || !obj || typeof obj !== 'object' ? obj : ('$value' in obj ? obj.$value : obj);

        return (
            <Log onClick={event => event.stopPropagation()}>
                {!DEBUG ? null :
                    <div className='debug'>{log.path}</div>
                }

                <Toggle onClick={() => setExtended(!extended)}>
                    { extended ? '-' : '+'}
                </Toggle>

                <b>{extended ? 'Extended value: ' : ''}</b>

                {
                    log.artifacts == null ? '<<No Artifacts>>' :
                    typeof log.artifacts !== 'object' ? log.artifacts :
                    Array.isArray(log.artifacts) ? print(log.artifacts.map(getVal)) :
                    print(getVal(log.artifacts))
                }
            </Log>
        );
    }
);

const deleteCheckerKey = (data, key) => {
    let result = _.omit(data.$checker, [key]);

    if (_.isEmpty(result)) result = undefined;

    data.change('$checker', result)
}

class InlineChecker extends PureComponent {

    static propTypes = {

    }

    render () {
        const { data, ...rest } = this.props;

        return (
            <div {...rest} className={'InlineChecker-root'}>
                {_.map(data.$checker, (value, key) =>
                    key === 'template' ? null :
                    typeof value === 'string' ?
                        <div key={key} className={'InlineChecker-item'}>
                            <span className='InlineChecker-type CheckerSelectMessage' data-type={key}>
                                {_.get(_.find(CheckerMessage.options, {0: key}), [1])}
                            </span>

                            <TextField
                                defaultValue={value}
                                className={'InlineChecker-value'}
                                onChange={val => data.change(`$checker[${key}]`, val)}
                            />

                            <div className={'InlineChecker-actionList'}>
                                <div
                                    onClick={() => deleteCheckerKey(data, key)}
                                    className={cx('InlineChecker-action', 'InlineChecker-remove')}
                                >×</div>
                            </div>
                        </div> :
                    <div key={key} className='row-center'>
                        <CheckerLabel type={key}>{key}</CheckerLabel>
                        <InlineCheckerMessage
                            path={data.getChildPath(`$checker[${key}]`)}
                            placeholder={`Message when ${key}`}
                        />
                        <div className={'InlineChecker-actionList'}>
                            <div
                                onClick={() => deleteCheckerKey(data, key)}
                                className={cx('InlineChecker-action', 'InlineChecker-remove')}
                            >×</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

}
