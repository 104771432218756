import React from 'react';
import PropTypes from 'prop-types';
import { TextArea, InputGroup, IInputGroupProps } from '@blueprintjs/core';
import { FieldInput, FieldMeta } from '@pinto/react-form';

const getValue = (value?: string | null) => value == null || value === '' ? null : value;

export interface TextInputProps extends Omit<IInputGroupProps, 'onChange' | 'value'> {
    value: string | null;
    onChange: (value: string | null) => void;
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    multiline?: boolean;
    changeOnBlur?: boolean;
    changeOnEnter?: boolean;
    minLength?: number;
    maxLength?: number;
    style?: React.CSSProperties;
};

export interface TextInputFieldProps extends Omit<TextInputProps, 'value' | 'onChange'> {
    input: FieldInput<string | null>;
    meta: FieldMeta;
}

/**
 * Extension of @blueprint InputGroup and TextArea and switches between the two based on if multiline=true
 * Adds a couple of extra commonly-used features like changeOnBlur and disabling autocomplete by default
 */
export class TextInput extends React.PureComponent<TextInputProps & { [key: string]: any }> {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        multiline: PropTypes.bool,
        changeOnBlur: PropTypes.bool,
        changeOnEnter: PropTypes.bool,
        minLength: PropTypes.number.isRequired,
        maxLength: PropTypes.number.isRequired,
    }

    static defaultProps = {
        minLength: 0,
        maxLength: 0,
    }

    render() {
        const {
            value: currentValue,
            onChange,
            multiline,
            changeOnBlur,
            changeOnEnter,
            minLength,
            maxLength,
            ...props
        } = this.props as Required<TextInputProps>;

        const defaultValue = currentValue == null ? '' : currentValue;
        const baseProps = {
            fill: true,
            autoComplete: 'off-for-realsies',
            minLength,
            maxLength: maxLength > minLength ? maxLength : undefined,
            ...(props as any),
        };

        if (changeOnEnter) {
            baseProps.onKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'Enter' && (!multiline || event.metaKey || event.ctrlKey)) {
                    event.preventDefault();
                    event.stopPropagation();
                    onChange?.(getValue((event.target as any).value));
                    return
                }
                (props as any).onKeyDown?.(event);
            }
        }

        if (changeOnBlur) {
            Object.assign(baseProps, {
                defaultValue,
                onBlur: (event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = getValue(event.target.value) as any;
                    if (value !== getValue(currentValue)) onChange(value);
                    if (this.props.onBlur) this.props.onBlur(event);
                },
            });
        } else {
            Object.assign(baseProps, {
                value: defaultValue,
                onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(getValue(value)),
            });
        }

        if (multiline) {
            return <TextArea {...baseProps as any} growVertically />;
        }

        return <InputGroup {...baseProps} />;
    }
}

export default TextInput;

export const TextInputField: React.FC<TextInputFieldProps & { [key: string]: any }> = ({ input, meta, ...props }) =>
    <TextInput
        intent={meta.touched && meta.error ? 'danger' : 'none'}
        {...props as any}
        value={input.value}
        onChange={input.onChange}
    />
