import {render} from 'react-dom';

import './utils/setupBlueprint';
import setupApiClient from './utils/setupApiClient';

setupApiClient();

import {store} from './redux/store';
import App from './App';

const { Provider } = require('react-redux');

const renderApp = (TheApp=App) =>
    render(
        <Provider store={store}>
            <TheApp />
        </Provider>,
        document.querySelector('#app')
    );

renderApp();
