import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import {connect} from 'react-redux';

import {AndExpression} from '../../../expressions/ListExpression';
import BuilderFunction from './BuilderFunction';
import {getRandomHash} from '../../../utils/utils';
import Select from '../../../components/Select';
import getFunctionSelectOption from '../../../utils/getFunctionSelectOption';
import {Actions} from '../../../redux/Formula';

import './FunctionManager.scss';

@connect(state => ({
    functions: state.formula.data.functions,
}), {
    setFunctions: Actions.setFunctions,
})
class FunctionManager extends PureComponent {

    static propTypes = {
        setFunctions: PropTypes.func.isRequired,
    }

    static defaultProps = {
        functions: [],
    }

    state = {
        current: null,
    }

    handleAdd = () => {
        const {setFunctions, functions} = this.props;
        const id = getRandomHash();
        setFunctions([
            ...(functions || []),
            {
                id,
                name: `function_${id}`,
                args: [],
                structure: AndExpression.getStub(),
            },
        ]);
        this.setState({ current: id });
    }

    handleSwap = (a, b) => {
        const {functions, setFunctions} = this.props;
        const result = [...(functions || [])];
        const old = result[a];
        result.splice(a, 1);
        result.splice(b, 0, old);
        setFunctions(result);
    }

    onClone = id => {
        const {functions, setFunctions} = this.props;
        const index = _.findIndex(functions, {id});
        const cloneId = getRandomHash();

        if (index < 0) return;

        const newFunctions = [...functions];
        newFunctions.splice(index, 0, {
          ...functions[index],
          id: cloneId,
          name: `Clone of: ${functions[index].name}`,
        })

        setFunctions(newFunctions);
        this.setState({ current: cloneId })
    }

    onRemove = id => {
        const {functions, setFunctions} = this.props;
        setFunctions(functions.filter(item => item.id !== id));
        this.setState({ current: null });
    }

    render () {
        const {setFunctions, functions, className, ...rest} = this.props;
        let { current } = this.state;
        const list = _.sortBy(functions.map((x, index) => ({ ...x, index })), x => x.name.toLowerCase());
        let match = _.find(list, { id: current });

        if (!match) match = list[0];

        return (
            <div {...rest} className={cx('FunctionManager-root', className)}>
                {/* <SortableList onSwap={this.handleSwap}>
                    {functions.map((formulaState, index) =>
                        <BuilderFunction
                            key={formulaState.id}
                            path={`functions[${index}]`}
                            onClone={() => this.onClone(formulaState.id)}
                            onRemove={() => this.onRemove(formulaState.id)}
                        />
                    )}
                </SortableList> */}
                <div className='row' style={{ alignItems: 'center' }}>
                    {!!list.length &&
                        <div className='row' style={{ alignItems: 'center' }}>
                            <div style={{ marginRight: '.5rem' }}>Selected:</div>
                            <b>
                                <Select
                                    value={match.id}
                                    options={list.map(func => [
                                        func.id,
                                        ...getFunctionSelectOption(func.name),
                                    ])}
                                    onChange={e => this.setState({ current: e.target.value })}
                                />
                            </b>
                        </div>
                    }
                    <button
                        className='FunctionManager-add'
                        onClick={this.handleAdd}
                    >new function</button>
                </div>
                <hr style={{ margin: '.5rem 0 .75rem 0' }}/>

                {match &&
                    <BuilderFunction
                        key={match.id}
                        path={`functions[${match.index}]`}
                        onClone={() => this.onClone(match.id)}
                        onRemove={() => this.onRemove(match.id)}
                    />
                }
            </div>
        );
    }

}

export default FunctionManager;
