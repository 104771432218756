import { Button } from '@blueprintjs/core';
import _ from 'lodash';
import React from 'react';
import external from 'src/external';
import BuilderContext from 'src/utils/BuilderContext';
import type { ResourceDrawerProps } from './ResourceDrawer';

export interface GlobalResourceDrawerHandleProps extends Omit<ResourceDrawerProps, 'isOpen' | 'onClose'> {
    readOnly?: boolean;
    emptyLabel?: string;
    allowEmptyValue: boolean;
}

let idCounter = 0;

export class GlobalResourceDrawerHandle extends React.PureComponent<GlobalResourceDrawerHandleProps> {
    static contextType = BuilderContext;
    context!: React.ContextType<typeof BuilderContext>;

    handleId = String(++idCounter);

    getProps() {
        const props: ResourceDrawerProps = {
            ...this.props,
            handleId: this.handleId,
            isOpen: true,
            onClose: () => {
                if (!this.context.resourceDrawer) return;
                this.props.onChange(this.context.resourceDrawer.value);
                this.context.setResourceDrawer(null);
            },
            onChange: value => {
                this.context.setResourceDrawer({ ...props, value });
            },
        };

        return props;
    }

    handleOpenDrawer = () => this.context.setResourceDrawer(this.getProps());

    getButtonText() {
        const { value, emptyLabel = '[empty]' } = this.props;

        if (!Array.isArray(value)) return null;
        if (!value.length) return emptyLabel;

        const resource = external.refMap[value[0]];
        if (!resource) return `INVALID RESOURCE: ${value[0]}`;

        const name = _.truncate(resource.name, { length: 20, omission: '…' });
        if (value.length === 1) return name;

        return `${name} +${value.length - 1} more`;
    }

    render() {
        const { readOnly, allowEmptyValue = true } = this.props;
        const isActive = this.handleId === this.context.resourceDrawer?.handleId;

        return <Button
            small
            icon='cube'
            minimal={!isActive}
            onClick={this.handleOpenDrawer}
            disabled={readOnly}
            intent={allowEmptyValue || this.props.value?.length
                ? isActive
                    ? 'primary'
                    : 'none'
                : 'danger'
            }
            text={this.getButtonText()}
        />;
    }
}
