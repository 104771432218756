import _ from 'lodash';
import { connect } from 'react-redux';
import { mapProps, compose } from 'recompose';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import WithPropsOnce from './WithPropsOnce';
import { formulaIterate } from '../utils/formula';

import { getExpression } from '../utils/utils';
import { getAllMethods, getGroupMethods, getMethod } from '../utils/methods';
import { isBlock } from '../utils/formula';
import getFunctionSelectOption from '../utils/getFunctionSelectOption';

// used for checking availableFunction equality
const createIdEqualitySelector = createSelectorCreator(
    defaultMemoize,
    (a, b) => {
        const hash = arr => _.map(arr, item => item.id + '-' + item.name).sort().join(';');
        return hash(a) === hash(b);
    }
);

const getAvailableFunctions = createIdEqualitySelector(
    state => state.formula.data.functions,
    arr => _.keyBy(arr, 'id')
);

const getAvailableVariables = state => {
    const map = {};
    formulaIterate(state.formula.data, ({ $op, $value, $args }) => {
        if (
            $op === 'fn' &&
            [
                'varSet',
                'varRefSet',
                'stringRegExp',
                'arrayFilter',
                'arrayFindStringAtIndex',
                'stringContainsWord'
            ].includes($value)
        ) {
            if ($value === 'varSet') map[$args[0]] = 'number';
            else if ($value === 'arrayFindStringAtIndex') map[$args[0]] = 'string';
            else map[$args[0]] = 'list';
        }
    });
    return map;
}

const getHasClipboard = state => !!_.get(state, 'formula.clipboard');

const getLog = state => state.formula.log;

const getStruct        = (state, props) => _.get(state, 'formula.data.' + props.path);
const getCollapseDepth = state => _.get(state, 'formula.uiOptions.collapseDepth', 6);
const getDispatch      = (_state, props) => props.dispatch;
const getPath          = (_state, props) => props.path;
const getDepth         = (_state, props) => props.depth || 0;

export default compose(
    // We @connect() twice since the 2nd connect needs access to dispatch
    connect(),
    WithPropsOnce(() => {
        const dataSelector = createSelector(
            [
                getPath,
                getStruct,
                getLog,
                getDispatch,
                getAvailableFunctions,
                getAvailableVariables,
                getHasClipboard,
                getDepth,
                getCollapseDepth,
            ],
            (
                path,
                struct,
                log,
                dispatch,
                availableFunctions,
                availableVariables,
                hasClipboard,
                depth,
                collapseDepth,
            ) =>
                createData({
                    dispatch,
                    struct,
                    log,
                    path,
                    availableFunctions,
                    availableVariables,
                    hasClipboard,
                    depth,
                    collapseDepth,
                })
        );

        return {
            dataSelector,
        };
    }),
    connect((state, props) => ({
        data: props.dataSelector(state, props),
    })),
    mapProps(({dataSelector, ...props}) => props),
);

const concatPath = (a, b) =>
    a == null || a === '' ? b :
    b == null || b === '' ? a :
    a + '.' + b;

const ID_SYMBOL = Symbol('id');
let uuid = 0;
const createData = ({dispatch, struct, log, path, availableFunctions, availableVariables, hasClipboard, depth, collapseDepth}) => {
    const {Actions} = require('../redux/Formula');

    const hasLog = !!log;

    if (!struct) console.warn(`NO STRUCT: "${path}"`);

    const ID = struct[ID_SYMBOL] || 'id-' + (++uuid);
    struct[ID_SYMBOL] = ID;

    const isCall = struct.$op === 'call';

    const getResult = (log, parentPath) =>
        parentPath == null ? null :
        _.get(log[concatPath(path.split('.')[0], parentPath)], ['result']);

    return _.defaults({}, struct, {
        dispatch,
        ID,
        plain: struct,
        path,
        availableFunctions,
        availableVariables,
        hasClipboard,
        depth,
        collapseDepth,
        defaultCollapsed: depth >= collapseDepth,

        log: Object.assign(
            {
                hasLog: hasLog && !path.includes('functions'),
                parentResult: !hasLog ? null : getResult(log, _.get(struct, ['state', 'parentPath'])),
            },
            !hasLog ? null : log[path],
            !hasLog || !isCall ? null : {
                artifacts: _.get(log[`${path}_nested`], 'artifacts', null),
                nested: log[path + '_nested'],
            }
        ),

        operationOptions: getOperations(
            availableFunctions,
            _.get(struct, 'state.otherOperations', []),
            { hasClipboard }
        ),

        getChildPath: subPath => concatPath(path, subPath),

        getChildLogResult: subPath =>
            !hasLog ? null :
            _.get(
                log[concatPath(path, subPath)] || log[concatPath(path, subPath) + '_nested'],
                'result'
            ),

        change: (subPath, value) => {
            if (subPath && typeof subPath === 'object') return dispatch(Actions.setInMultiple(path, subPath));
            return dispatch(Actions.setIn(concatPath(path, subPath), value));
        },

        remove: () => dispatch(Actions.remove(path)),

        replace: (key, args=[]) => {
            if (typeof key !== 'string') throw new Error(`Invalid key: '${key}' (${typeof key})`);

            // If swapping from an $and to an $or or vice-versa, keep everything in place
            if (isBlock(key) && isBlock(struct.$op)) {
                if (key === struct.$op) return;
                return dispatch(Actions.setIn(concatPath(path, '$op'), key));
            }

            // If replacing a non-block with a block. wrap it instead
            if (!isBlock(struct.$op)) {
                if (isBlock(key)) return dispatch(Actions.setIn(path, { $op: key, $value: [struct] }));
                if (key === 'if') {
                    return dispatch(Actions.setIn(path, {
                        $op: 'if',
                        $value: struct,
                        $then: { $op: 'fn', $value: 'constant', $args: [ 'false' ] },
                        $else: { $op: 'fn', $value: 'constant', $args: [ 'false' ] },
                    }));
                }
            }

            return dispatch(Actions.setIn(path, getExpression(key).getStub(args)));
        },

        unwrap: () => !struct.state.actions.unwrap ? null :
            dispatch(Actions.setIn(path, struct.$value[0])),

        setState: (state) => dispatch(Actions.setState(path, state)),

        makeFunction: () => struct.$op === 'call' ? null : dispatch(Actions.makeFunction(path)),

        clipboardAdd: () => dispatch(Actions.clipboardAdd(struct)),

        clipboardPaste: (subPath) => dispatch(Actions.clipboardPaste(concatPath(path, subPath))),
    });
}

const getOperations = (functions, other=[], { hasClipboard }) => [
    ...(!hasClipboard ? [] : [
        ['clipboard', 'CLIPBOARD', 'Operations'],
    ]),

    ..._.map(getGroupMethods('Basic'), ({key, label, group}) => [
        key,
        label,
        group,
        { title: getMethod(key).description },
    ]),

    ..._.map(getGroupMethods('Variable-Related'), ({key, label, group}) => [
        `fn:${key}`,
        label,
        group,
        { title: getMethod(key).description },
    ]),

    ..._.map(getGroupMethods('Operators'), ({key, label, group}) => [
        `fn:${key}`,
        label,
        group,
        { title: getMethod(key).description },
    ]),

    ..._.map(getGroupMethods('Read/Write'), ({key, label, group}) => [
        key === 'ref' ? key : `fn:${key}`,
        label,
        group,
        { title: getMethod(key).description },
    ]),

    ..._.sortBy(
        _.map(functions, ({id, name}) => {
            const [n, g] = getFunctionSelectOption(name);
            return ['call:' + id, n, 'Custom' + (g ? ': ' + g : '')];
        }),
        2,
    ),

    ...(other || []),

    ..._.map(getGroupMethods('Deprecated'), ({key, label, group}) => [
        key === 'schema' ? key : `fn:${key}`,
        label,
        group,
        { title: getMethod(key).description },
    ]),

    ..._.map(_.filter(getAllMethods(), m => !m.group), ({key, label}) => [
        `fn:${key}`,
        label,
        'Deprecated',
        { title: getMethod(key).description },
    ]),
];
