import { AnchorButton, AnchorButtonProps, Classes, Dialog, DialogProps } from '@blueprintjs/core';
import { Popover2, Popover2Props, Tooltip2 } from '@blueprintjs/popover2';
import React from 'react';

export interface AdvancedButtonProps extends AnchorButtonProps {
    tooltip?: React.ReactNode;
    dialog?: Partial<DialogProps> & {
        body: React.ReactNode;
        footer?: React.ReactNode | ((config: { onClose: () => void }) => React.ReactNode);
    };
    hideTextOnSmallScreen?: boolean;
    popover?: Popover2Props;
    style?: React.CSSProperties;
    className?: string;
}

export default class AdvancedButton extends React.PureComponent<AdvancedButtonProps> {
    state = {
        isSmallWindow: false,
        isDialogOpen: false,
    }

    windowResizeCallback = () => {
        this.setState({ isSmallWindow: window.innerWidth < 1200 });
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResizeCallback);
        this.windowResizeCallback();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResizeCallback)
    }

    handleClick = (event: any) => {
        const { dialog, onClick } = this.props;
        onClick?.(event);
        if (dialog) this.setState({ isDialogOpen: !this.state.isDialogOpen });
    }

    render() {
        const { tooltip, dialog, popover, hideTextOnSmallScreen, onClick, text, ...props } = this.props;
        const { isSmallWindow, isDialogOpen } = this.state;

        let content = <AnchorButton
            {...props}
            onClick={this.handleClick}
            text={hideTextOnSmallScreen && isSmallWindow ? null : text}
        />;

        if (tooltip) {
            content = <Tooltip2 content={tooltip as any}>{content}</Tooltip2>;
        }

        if (popover) {
            content = <Popover2 canEscapeKeyClose interactionKind='click' {...popover}>{content}</Popover2>;
        }

        if (dialog) {
            const { body, footer, ...dialogProps } = dialog;
            const onClose = () => this.setState({ isDialogOpen: false });
            const config = { onClose };
            const invokeRender = (item: React.ReactNode | ((cfg: typeof config) => React.ReactNode)) =>
                typeof item === 'function' ? item(config) : item;

            content = <>
                {content}

                <Dialog
                    canEscapeKeyClose
                    autoFocus
                    shouldReturnFocusOnClose={false}
                    {...dialogProps}
                    isOpen={isDialogOpen}
                    onClose={onClose}
                >
                    <div className={Classes.DIALOG_BODY}>
                        {body}
                    </div>

                    {footer &&
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                {invokeRender(dialog.footer)}
                            </div>
                        </div>
                    }
                </Dialog>
            </>
        }

        return content;
    }
}
