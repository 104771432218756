
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import {IntField, BooleanField} from './Options';

import {Label, LabelName} from '../../ui';

export default class EditConfig extends PureComponent {

    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        })).isRequired,
        values: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    render () {
        const {options, values, onChange, ...rest} = this.props;

        return (
            <div {...rest}>
                {options.map((optData, index) => {
                    const { key, type } = optData;
                    const FieldKlass = type === 'boolean' ? BooleanField :
                        type === 'int' ? IntField :
                        null;

                    return (
                        <Label table key={`${index}-${key}`}>
                            <LabelName size={7} table>{key}:</LabelName>
                            <FieldKlass
                                defaultValue={values[key]}
                                onChange={val =>
                                    onChange({
                                        ...values,
                                        [key]: val,
                                    })
                                }
                                data={optData}
                            />
                        </Label>
                    );
                })}
            </div>
        );
    }

}

const EditConfigWrapper = styled.div`
    border: 1px solid #ccc;
    background: wheat;
    margin-top: 1rem;
    padding: 1rem;
    max-width: 30rem;
`;

export const EditConfigDialog = ({options, ...rest}) =>
    !_.size(options) ? null :
    <EditConfigWrapper>
        <h2 style={{marginTop: 0}}>Change Run Options</h2>
        <EditConfig options={options} {...rest} />
    </EditConfigWrapper>;
