import React from 'react';
import PropTypes from 'prop-types';
import { IButtonProps, IMenuProps, Menu, MenuItem } from '@blueprintjs/core';
import fuzzaldrin from 'fuzzaldrin-plus';
import { IMultiSelectProps, ISelectProps, renderFilteredItems } from '@blueprintjs/select';

import markdownToReact from '../../utils/markdownToReact';
import type { SelectMenuOption } from 'src/typeDefs';
import SelectOptionType, { SelectOptionKeyType } from 'src/propTypes/SelectOptionType';
import { FieldT, FormFieldInputType, FormFieldMetaType } from '@pinto/react-form';
import _ from 'lodash';

export interface BaseSearchProps<T> {
    value?: T;
    onChange: (key: T, option?: SelectMenuOption) => void;
    options: SelectMenuOption[];
    allowCreate?: boolean;
    /**
     * If using custom filtering (e.g. doing async query and creating options) you can disable the components filtering to not have 2 filter systems
     */
    disableFilter?: boolean;
    menuProps?: Partial<IMenuProps>;
    [key: string]: any;
};

export interface SearchOption extends SelectMenuOption {
    /**
     * The part of the label to highlight
     */
    match?: string;
    isNew?: boolean;
    buttonProps?: IButtonProps;
}

export interface CompiledSearchOption extends SearchOption {
    search: string;
}

/**
 * This shouldn't be needed, but TS does not fully support types for abstract classes
 *
 * Until the following works, this workaround is needed:
 *
 *  abstract class Base {
 *      abstract someMethod: (input: string) => number;
 *  }
 *
 *  class Foo extends Base {
 *      someMethod = input => 123;
 *  }
 *
 * The above right now throws a type error that `Foo.someMethod` has "input" arg as "any", even though it extends the abstract Base class which has it defined
 *
 */
export type BaseType<Key extends keyof typeof BaseSearch['prototype']> = typeof BaseSearch['prototype'][Key];

export abstract class BaseSearch<K, T extends BaseSearchProps<K>> extends React.PureComponent<T> {
    static propTypes = {
        value: SelectOptionKeyType,
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(SelectOptionType).isRequired,
        allowCreate: PropTypes.bool,
        disableFilter: PropTypes.bool,
    }

    abstract readonly SelectComponent: React.ComponentType<any>;

    abstract isOptionSelected: (option: SearchOption) => boolean;
    abstract handleClear: () => void;
    abstract onItemSelect: (option: SearchOption) => void;
    abstract createGetItems: () => ((options: SearchOption[], inputValue: K) => any);
    getItems: any;

    constructor(props: T) {
        super(props);

        const baseGetItems = _.once(() => this.createGetItems());
        this.getItems = () => baseGetItems()(this.props.options, this.props.value as any);
    }

    private isCreateItemFirst(): boolean {
        return this.props.createNewItemPosition === "first";
    }

    // copy-pasted from: https://github.com/palantir/blueprint/blob/develop/packages/select/src/components/query-list/queryList.tsx
    // added support for passing menuProps
    itemListRenderer: ISelectProps<CompiledSearchOption>['itemListRenderer'] = (listProps) => {
        const { initialContent, noResults, menuProps } = this.props;

        // omit noResults if createNewItemFromQuery and createNewItemRenderer are both supplied, and query is not empty
        const createItemView = listProps.renderCreateItem();
        const maybeNoResults = createItemView != null ? null : noResults;
        const menuContent = renderFilteredItems(listProps, maybeNoResults, initialContent);
        if (menuContent == null && createItemView == null) {
            return null;
        }
        const createFirst = this.isCreateItemFirst();
        return (
            <Menu {...menuProps} ulRef={listProps.itemsParentRef}>
                {createFirst && createItemView}
                {menuContent}
                {!createFirst && createItemView}
            </Menu>
        );
    }

    itemRenderer: ISelectProps<CompiledSearchOption>['itemRenderer'] = (option, { handleClick, modifiers: { active } }) =>
        <MenuItem
            key={option.key}
            text={option.match ? markdownToReact(option.match) : option.label}
            onClick={handleClick}
            active={active}
            icon={option.icon}
            intent={this.isOptionSelected(option) ? 'primary' : 'none'}
            {...option.props}
        />

    itemPredicate: ISelectProps<CompiledSearchOption>['itemPredicate'] = (query, option) => {
        if (this.props.disableFilter) return true;

        query = query.trim().toLowerCase();
        return option.search.includes(query);
    }

    itemListPredicate: ISelectProps<CompiledSearchOption>['itemListPredicate'] = (query, items) => {
        if (this.props.disableFilter) return items;

        query = query.trim().toLowerCase();
        if (!query) return items;

        return items
            .map(option => ({
                score: fuzzaldrin.score(option.search, query),
                option,
            }))
            .filter(item => item.score > 0)
            .map(item => ({
                ...item.option,
                match: fuzzaldrin.wrap(
                    item.option.label,
                    query,
                    { wrap: { tagOpen: '**', tagClose: '**' } },
                ),
            }));
    }

    tagRenderer: IMultiSelectProps<CompiledSearchOption>['tagRenderer'] = option => option.label;

    createNewItemFromQuery: ISelectProps<SearchOption>['createNewItemFromQuery'] = query =>
        ({ key: query, label: query.trim() })

    createNewItemRenderer: ISelectProps<CompiledSearchOption>['createNewItemRenderer'] = (query, active, onClick) =>
        <MenuItem
            text={markdownToReact(`**Create new entry:** ${query.trim()}`)}
            onClick={onClick}
            active={active}
            icon='insert'
            intent='warning'
        />

    itemsEqual: ISelectProps<CompiledSearchOption>['itemsEqual'] = (a, b) => a.key === b.key;

    getComponentProps() {
        const { value, onChange, options, allowCreate, menuProps, ...props } = this.props;

        return props;
    }

    render() {
        const { SelectComponent } = this;
        const { allowCreate } = this.props;

        return <SelectComponent
            {...this.getItems()}
            fill
            noResults={<MenuItem disabled text='No results.' />}
            resetOnSelect
            {...this.getComponentProps()}
            itemRenderer={this.itemRenderer}
            itemListRenderer={this.itemListRenderer}
            itemsEqual={this.itemsEqual}
            onItemSelect={this.onItemSelect}
            tagRenderer={this.tagRenderer}
            itemPredicate={this.itemPredicate}
            itemListPredicate={this.itemListPredicate}
            createNewItemFromQuery={allowCreate ? this.createNewItemFromQuery : null}
            createNewItemRenderer={allowCreate ? this.createNewItemRenderer : null}
        />;
    }
}

export default BaseSearch;

export function createBaseSearchField<K> (SearchField: React.ComponentType<any>) {
    return class BaseSearchField extends React.PureComponent<FieldT<K>> {
        static propTypes = {
            input: FormFieldInputType.isRequired,
            meta: FormFieldMetaType.isRequired,
        }

        onChange = (value: K) => this.props.input.onChange(value);

        render() {
            const { input, meta, ...props } = this.props;

            return <SearchField
                {...props as any}
                onChange={this.onChange}
                value={input.value}
            />;
        }
    };
};
