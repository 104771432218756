import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, MenuItem, Tag } from '@blueprintjs/core';
import _ from 'lodash';

import ResourceIcon from 'src/components/ResourceIcon';
import type { SearchResource } from './ResourceDrawer';

export const DrawerItem: React.FC<{
    resource: SearchResource;
    selected: boolean;
    focused: boolean;
    visible: boolean;
    expanded: boolean;
    onToggle: (item: SearchResource) => void;
    onExpand: (item: string | null) => void;
}> = React.memo(({ resource, onToggle, onExpand, focused, expanded, selected, visible }) => {
    const [hover, setHover] = useState(false);

    return <MenuItem
        selected={focused}
        text={resource.name}
        onClick={() => onToggle(resource)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        icon={
            hover || expanded
                ? <Button
                    icon='eye-open'
                    small
                    minimal
                    intent={expanded ? 'primary' : 'none'}
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onExpand(expanded ? null : resource._id);
                    }}
                />
                : <Button icon='blank' disabled minimal small />
        }
        labelElement={
            <>
                {resource.tags?.map((tag, i) => <Tag key={i} minimal>{tag.name}</Tag>)}
                <ResourceIcon resource={resource} className='ml05' />
            </>
        }
        ref={focused
            ? el => (ReactDOM.findDOMNode(el) as any)?.scrollIntoViewIfNeeded()
            : undefined
        }
        style={{
            ...(selected ? { fontWeight: 'bold' } : null),
            ...(visible ? null : { display: 'none' }),
        }}
    />;
});
