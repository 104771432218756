import { S1_form } from 'file:///root/project/node_modules/.cache/react-style-loader/components_LandingPage-l5V4.styl';
import { Button, Callout, Card, FormGroup, H1 } from '@blueprintjs/core';
import { api, publicApi, UserOutput } from '@pinto/api-client';
import { TextInput } from '@pinto/ui';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie'

import setupApiClient from 'src/utils/setupApiClient';

interface Session {
    isAuthenticated: boolean;
    user?: UserOutput | null;
}

const LandingPage: React.FC<{ render: () => React.ReactNode }> = ({ render }) => {
    const [session, setSession] = useState<Session | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [animationStage, setAnimationStage] = useState<null | 'start' | 'finish'>(null);

    const errorHandler = (error: any) => {
        setError(String(error?.error || error));
        setLoading(false);
    }

    const fetchSession = ({ logError = true, withAnimation = false } = {}) => {
        const jwt = Cookies.get('x-pinto-jwt');
        if (!jwt) return;

        setupApiClient(jwt);
        setLoading(true);
        api.get('/auth/session', {}, { headers: { JWT: jwt } })
            .then(session => {
                withAnimation && setAnimationStage('start');
                setSession(session);
                withAnimation && setTimeout(() => setAnimationStage('finish'), 2000);
            })
            .catch(error => {
                if (logError) errorHandler(error);
                setSession({ isAuthenticated: false });
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => fetchSession({ logError: false }), []);

    const isInitializing = Cookies.get('x-pinto-jwt') && !session;
    const extraClass = isInitializing ? 'bp4-skeleton' : '';

    const renderLoginPage = () =>
        <Root
            data-animation={animationStage}
            onSubmit={(e: any) => {
                e.preventDefault();
                setLoading(true);
                publicApi.post('/auth/login', { email, password })
                    .then(jwt => {
                        if (jwt) {
                            Cookies.set('x-pinto-jwt', jwt, { expires: 30 });
                            fetchSession({ withAnimation: true });
                        } else {
                            setSession({ isAuthenticated: false });
                        }
                    })
                    .catch(errorHandler)
                    .finally(() => setLoading(false));
            }}
        >
            <Card elevation={2}>
                <H1 className='mb1'>👷‍♀️ Papers, please</H1>
                <FormGroup label='Email' labelInfo='(pinto account)' className={extraClass}>
                    <TextInput
                        placeholder='judy@hopps.com'
                        value={email}
                        onChange={setEmail}
                        large
                    />
                </FormGroup>

                <FormGroup label='Password' className={extraClass}>
                    <TextInput
                        placeholder='password'
                        type='password'
                        value={password}
                        onChange={setPassword}
                        large
                    />
                </FormGroup>

                {error &&
                    <Callout
                        intent='danger'
                        title="Something ain't right, chief"
                        className='mb1'
                    >{error}</Callout>
                }

                <div style={{ flex: '1 1 0' }} />

                <Button
                    type='submit'
                    intent='primary'
                    text='Beam me up, Scotty!'
                    fill
                    large
                    loading={loading}
                    className={extraClass}
                />
            </Card>
        </Root>;

    return <>
        {session?.isAuthenticated && render()}
        {animationStage === 'start' || !session?.isAuthenticated
            ? renderLoginPage()
            : null
        }
    </>
}

export default LandingPage;

const Root = S1_form.withComponent('form');