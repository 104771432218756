import React from 'react';
import { downloadJSON, TextInput, EditableMarkdown } from '@pinto/ui';
import type { RawFormulaData } from 'src/typeDefs';
import _ from 'lodash';
import AdvancedButton from 'src/components/AdvancedButton';
import { FormGroup, HTMLTable } from '@blueprintjs/core';
import { cleanFormula } from 'src/utils/formula';

export interface FileInfoProps {
    onChange: (data: Pick<RawFormulaData, 'name' | 'fields' | 'description'>) => void;
    formula: RawFormulaData;
};

export default class FileInfo extends React.PureComponent<FileInfoProps> {
    state = {
        name: '',
        description: '',
        fields: [] as string[],
    };

    constructor(props: FileInfoProps) {
        super(props);
        if (props.formula) this.state = { ...this.state, ...props.formula };
    }

    UNSAFE_componentWillReceiveProps(nextProps: FileInfoProps) {
        if (nextProps.formula !== this.props.formula) {
            this.setState(nextProps.formula);
        }
    }

    change(diff: Partial<typeof this.state>) {
        const { onChange } = this.props;
        this.setState(diff, () => { onChange(_.pick(this.state, ['name', 'fields', 'description'])); });
    }

    private fieldFormatter(value: string) { return (value || '').split(/[\s;,|]+/) }

    renderContent() {
        const { onChange, formula } = this.props;
        const { name, description, fields } = this.state;

        return (
            <div style={{ padding: '1rem', width: '35rem', maxHeight: '92vh', overflowY: 'auto' }}>
                <div style={{ display: 'flex', alignItems: 'center' }} className='mb05'>
                    <FormGroup label='Formula _id' labelInfo={formula._id || 'no-id'} className='mb0'/>
                    <div style={{ flex: '1 1 0' }} />
                    <AdvancedButton
                        text='Download to JSON'
                        intent='primary'
                        className='mb025'
                        onClick={() => {
                            const data = cleanFormula(formula);

                            downloadJSON(
                                data.name + '-structure.json',
                                {
                                    ..._.pick(data, ['name', 'description', 'fields']),
                                    ..._.omit(data,['_nested']),
                                },
                                { pretty: true },
                            );
                        }}
                    />
                </div>
                <FormGroup label='Formula name'>
                    <TextInput
                        type='text'
                        placeholder='File Name'
                        onChange={value => onChange({ name: value! })}
                        value={name}
                        changeOnBlur
                        fill
                    />
                </FormGroup>
                <FormGroup label='Formula Description' labelInfo='(supports Markdown)'>
                    <EditableMarkdown
                        text={description}
                        placeholder='Description'
                        onChange={value => onChange({ description: value! })}
                        editText={{
                            edit: 'edit',
                            show: 'view',
                        }}
                        inputProps={{ style: { maxHeight: '15rem' } }}
                    />
                </FormGroup>
                <FormGroup label='Extra Fields' labelInfo='(one per line)'>
                    <TextInput
                        multiline
                        value={(fields || []).join('\n')}
                        changeOnBlur
                        placeholder='Extra Fields (one per line)'
                        onChange={value => onChange({ fields: this.fieldFormatter(value!) })}
                        style={{ maxHeight: '10rem' }}
                    />
                </FormGroup>
                <FormGroup label='Changelog'>
                    {formula.changelog?.length
                        ? <HTMLTable condensed striped>
                            <tbody>
                                {formula.changelog.map((row, i) =>
                                    <tr key={i}>
                                        <td>v{row.version}</td>
                                        <td>{row.text}</td>
                                    </tr>
                                )}
                            </tbody>
                        </HTMLTable>
                        : 'No changelog'
                    }
                </FormGroup>
            </div>
        );
    }

    render() {
        return <AdvancedButton
            icon='info-sign'
            text='Formula Info'
            hideTextOnSmallScreen
            tooltip='Change formula metadata and export to JSON'
            popover={{
                content: this.renderContent(),
            }}
        />;
    }

}
