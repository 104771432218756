export function downloadURL (data: string, fileName: string) {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.style.display = 'none';
    a.click();
    a.remove();
};

export function downloadBlob (fileName: string, mimeType: string, data: string) {
    const blob = new Blob([data], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    downloadURL(url, fileName);
    setTimeout(() => window.URL.revokeObjectURL(url), 1000);
};

export function downloadJSON (fileName: string, data: any, { pretty = false } = {}) {
    downloadBlob(
        fileName,
        'application/json',
        JSON.stringify(data, null, pretty ? 4 : undefined)
    );
};
