import '@pinto/react-style-loader/lib/src/styles/global.styl';
import './styles/main.styl';
import './styles/index.scss';

import Builder from './routes/Builder/Builder';
import Ingredients from './routes/Ingredients/Ingredients';
import _ from 'lodash';
import LandingPage from './components/LandingPage';

const { BrowserRouter: Router, Route, Switch, Redirect } = require('react-router-dom');

const App = () => {
    return <Router>
        <Switch>
            <Redirect exact from='/checker' to='/checker/checkerStats' />

            <Route exact path='/ingredients' component={Ingredients} />

            <Route path='/:firstTab?/:secondTab?/:slug?' component={Builder} />
        </Switch>
    </Router>;
}

export default () => <LandingPage render={() => <App />} />;
