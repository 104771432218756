/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import memoizeOne from 'memoize-one';
import { Button } from '@blueprintjs/core';
import { IMultiSelectProps, MultiSelect } from '@blueprintjs/select';
import { produce } from 'immer';

import BaseSearch, { BaseSearchProps, BaseType, createBaseSearchField, SearchOption } from './BaseSearch';
import { SelectOptionKey } from 'src';
import { SelectOptionKeyType } from 'src/propTypes/SelectOptionType';

export interface SearchMultiSelectProps extends BaseSearchProps<SelectOptionKey[]>,
    Partial<IMultiSelectProps<SelectOptionKey[]>> {}

export class SearchMultiSelect extends BaseSearch<SelectOptionKey[], SearchMultiSelectProps> {
    static propTypes = {
        ...BaseSearch.propTypes,
        value: PropTypes.arrayOf(SelectOptionKeyType).isRequired as any,
    }

    readonly SelectComponent = MultiSelect;

    isOptionSelected: BaseType<'isOptionSelected'> = option => !!this.props.value?.includes(option.key);

    handleClear: BaseType<'handleClear'> = () => this.props.onChange([]);

    onItemSelect: BaseType<'onItemSelect'> = option => {
        let { value, onChange } = this.props;
        value = value || [];

        onChange(
            value.includes(option.key)
                ? value.filter(key => key !== option.key)
                : [...value, option.key],
        );
    }

    onRemove: IMultiSelectProps<SelectOptionKey>['onRemove'] = (label, index) =>
        this.props.onChange(
            produce(this.props.value || [], draft => {
                draft.splice(index, 1);
            }),
        );

    createGetItems: BaseType<'createGetItems'> = () =>
        memoizeOne((options: SearchOption[], inputValue: SelectOptionKey[]) => {
            inputValue = inputValue || [];

            const items: any[] = options.map((opt, index) => ({
                ...opt,
                search: (opt.label || '').trim().toLowerCase(),
                index,
            }));

            const itemMap: { [key: string]: any } = _.keyBy(items, 'key');
            const newOptions = inputValue.filter(key => !itemMap[key!]);

            for (const key of newOptions) {
                const opt = { key, label: key, isNew: true };
                items.push(opt);
                itemMap[key!] = { key, label: key, isNew: true };
            }

            return {
                items,
                selectedItems: inputValue.map(key => itemMap[key!]),
            };
        });

    getComponentProps() {
        const { tagInputProps, ...props } = super.getComponentProps();
        return {
            ...props,
            tagInputProps: {
                tagProps: () => ({ minimal: true }),
                ...tagInputProps,
                disabled: this.props.disabled,
                onRemove: this.onRemove,
                rightElement: _.size(this.props.value) > 0
                    ? <Button icon='cross' minimal onClick={this.handleClear} />
                    : undefined,
            },
        };
    }
}

export const SearchMultiSelectField = createBaseSearchField(SearchMultiSelect);

export default SearchMultiSelect;
