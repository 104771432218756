import { S1_div } from 'file:///root/project/node_modules/.cache/react-style-loader/routes_Builder_components_Nav-kwsw.styl';
import { ButtonGroup, Divider } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import type { FormulaOutput } from '@pinto/api-client';
import { NumericInput } from '@pinto/ui';
import _ from 'lodash';

import AdvancedButton from 'src/components/AdvancedButton';
import FormulaAutocomplete from 'src/components/autocomplete/FormulaAutocomplete';
import ProductAutoComplete from 'src/components/autocomplete/ProductAutocomplete';
import type { UiFormulaState } from 'src/typeDefs';
import { useBuilderContext } from 'src/utils/BuilderContext';
import FileInfo from './FileInfo';

export interface NavProps {
    formula: UiFormulaState;
    preview: boolean;
    run: () => void;
    clear: () => void;
    toggleEdit: () => void;
    reset: () => void;
    undo: () => void;
    hasVisibleFunctions: boolean;
    toggleFunctions: () => void;
    handleFileInfoChange: (data: any) => void;
    extraColumn: boolean;
    toggleExtraColumn: () => void;
    ignoreGlobalFilter: boolean;
    toggleIgnoreGlobalFilter: () => void;
    setCollapseDepth: (value: number) => void;
    setFormula: (formula: FormulaOutput) => void;
}

const Nav: React.FC<NavProps> = ({
    formula,
    preview,
    ignoreGlobalFilter,
    extraColumn,
    run,
    clear,
    toggleEdit,
    handleFileInfoChange,
    reset,
    undo,
    toggleFunctions,
    hasVisibleFunctions,
    toggleIgnoreGlobalFilter,
    toggleExtraColumn,
    setCollapseDepth,
    setFormula,
}) => {
    const { pv2, setPartialProduct, loadingState, theme, toggleTheme } = useBuilderContext();
    const collapseDepth = _.get(formula, 'uiOptions.collapseDepth', 6);

    return <Root>
        <ButtonGroup style={{ display: 'flex', width: '100%' }}>
            <AdvancedButton
                icon='reset'
                tooltip='Reset formula to a blank state'
                dialog={{
                    title: 'You sure?',
                    icon: 'delete',
                    body: 'You are about to delete your entire local formula',
                    footer: ({ onClose }) =>
                        <>
                            <AdvancedButton text='Nevermind' onClick={onClose} />
                            <AdvancedButton
                                text="Yes, I know what I'm doing"
                                intent='danger'
                                onClick={() => {
                                    reset();
                                    onClose();
                                }}
                            />
                        </>
                }}
            />
            <Divider />
            <AdvancedButton
                icon='undo'
                tooltip={formula.history.length ? 'Undo 1 step' : 'Change something first to undo'}
                disabled={!formula.history.length}
                onClick={undo}
            />
            <AdvancedButton
                icon='function'
                disabled={!formula.data.functions?.length}
                intent={hasVisibleFunctions ? 'none' : 'primary'}
                tooltip={
                    formula.data.functions?.length
                        ? 'Toggle all function content'
                        : 'Toggle function content ... but there aren\'t any functions to toggle now, are there?'
                }
                onClick={toggleFunctions}
            />
            <AdvancedButton
                tooltip='Toggle extra column'
                icon='add-column-right'
                intent={extraColumn ? 'primary' : 'none'}
                onClick={toggleExtraColumn}
            />
            <AdvancedButton
                tooltip={`${ignoreGlobalFilter ? 'Enable' : 'Disable'} global taxonomy filter`}
                icon='filter'
                intent={ignoreGlobalFilter ? 'none' : 'primary'}
                onClick={toggleIgnoreGlobalFilter}
            />
            <Tooltip2 content='Change default collapse depth'>
                <NumericInput
                    short
                    leftIcon='collapse-all'
                    value={collapseDepth}
                    onChange={val => setCollapseDepth(val || 6)}
                    min={1}
                    max={20}
                    step={1}
                    className='numeric-input-fix'
                />
            </Tooltip2>
            <Divider />
            <div style={{ alignSelf: 'center', marginRight: '.25rem' }} className='hidden-on-small'>Pv2:</div>
            <ProductAutoComplete
                value={pv2}
                onChange={setPartialProduct}
                buttonProps={{
                    intent: pv2?._id ? 'primary' : 'none',
                    loading: !!loadingState,
                }}
            />
            <Divider />
            <AdvancedButton
                text='Run'
                icon='play'
                hideTextOnSmallScreen
                loading={!!loadingState}
                disabled={(preview || !pv2)}
                onClick={run}
                tooltip={
                    pv2
                        ? preview
                            ? 'Reset the preview first by clicking "Clear"'
                            : 'Test the current pv2 against the formula'
                        : 'Select a pv2 first'
                }
            />
            <AdvancedButton
                text='Edit'
                icon='edit'
                hideTextOnSmallScreen
                disabled={!pv2}
                onClick={toggleEdit}
                tooltip={
                    <>
                        Manually edit the raw data before running to get the perfect test case.
                        {!pv2 && <><br />Select a product first to begin editing</>}
                    </>
                }
            />
            <AdvancedButton
                text='Clear'
                icon='clean'
                hideTextOnSmallScreen
                disabled={!preview}
                onClick={clear}
                tooltip='Reset the preview after a run'
            />
            <div style={{ flex: '1 1 0' }} />
            <div style={{ alignSelf: 'center', marginRight: '.25rem' }} className='hidden-on-small'>Formula:</div>
            <FormulaAutocomplete
                value={formula.data as any}
                onChange={setFormula}
            />
            <FileInfo
                onChange={handleFileInfoChange}
                formula={formula.data}
            />
            <Divider />
            <AdvancedButton
                icon='lightbulb'
                onClick={toggleTheme}
                tooltip={theme === 'dark' ? 'Turn on the lights' : 'Turn off the lights'}
            />
        </ButtonGroup>
    </Root>
}

export default Nav;

const Root = S1_div.withComponent("div");