import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import WithFormulaData from '../../../decorators/WithFormulaData';
import { parseQuery } from '../../../utils/utils';

import ExpressionSelector from '../../../expressions/ExpressionSelector/ExpressionSelector';
import TextField from '../../../components/TextField/TextField';

const DEBUG = parseQuery().debug != null;

@WithFormulaData
class BuilderFunction extends PureComponent {

    static propTypes = {
        path: PropTypes.string.isRequired,
        data: PropTypes.shape({
            path: PropTypes.string.isRequired,
        }).isRequired,
        onClone: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
    }

    handleNameChange = name => this.props.data.change('name', name)

    handleDescriptionChange = description => this.props.data.change('description', description)

    handleRemove = () => {
        if (!confirm(`You are about to remove: ${name}`)) return;
        this.props.onRemove();
    }

    render () {
        const {data, path, onClone, onRemove, dispatch, className, ...rest} = this.props;

        return (
            <div {...rest} className={cx('BuilderFunction-root', className)}>
                <div className={'BuilderFunction-remove'} onClick={this.handleRemove}>×</div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!DEBUG ? null :
                        <div className={'BuilderFunction-debug'}>{data.id}</div>
                    }
                    <TextField style={{ flex: '1 1 0' }} inputClassName='BuilderFunction-TextField' defaultValue={data.name} onChange={this.handleNameChange} />
                </div>
                <button className={'BuilderFunction-clone'} onClick={onClone}>clone</button>
                {/*<TextField className='BuilderFunction-TextField' defaultValue={formula.description} onChange={this.handleDescriptionChange} />*/}
                <ExpressionSelector
                    depth={0}
                    path={data.getChildPath('structure')}
                    noRemove={true}
                    className={'BuilderFunction-expression'}
                />
            </div>
        );
    }

}

export default BuilderFunction;
