
const _ = require('lodash');
const { structIterate } = require('./formula');

const assert = (bool, msg) => {
    if (bool) return;
    console.error(msg);
    throw msg;
}

module.exports = formula => {
    assert(formula, 'Invalid root formula');

    const { structure, checker, functions } = formula;

    assert(_.isPlainObject(structure) , 'Invalid root structure');
    validateStruct(structure, ['structure']);

    assert(!checker || _.isPlainObject(checker), 'Invalid checker structure');
    if (checker) validateStruct(checker, ['checker']);

    assert(!functions || Array.isArray(functions), 'Invalid functions array');
    if (functions) functions.forEach((func, index) => validateFunction(func, ['functions', index]));
}

const validateStruct = (struct, path, subPath='root') => {
    const err = msg =>
        `[${path.join('.')}:${subPath} | ${_.get(struct, '$op', '<no-$op>')}] ${msg}`;

    assert(_.isPlainObject(struct) , err('Invalid sub-structure'));
    assert(struct.$op, err('Missing $op'));
    assert(struct.$value, err('Missing $value'));

    if (subPath !== 'root') return;

    structIterate(struct, (subStruct, subPath) => {
        validateStruct(subStruct, path, subPath);
    });
}

const validateFunction = (obj, path) => {
    const flair = `${path.join('.')} | ${_.get(obj, 'id')} | ${_.get(obj, 'name')}`;
    const err = msg => `[${flair}] ${msg}`;

    assert(_.isPlainObject(obj), err('Invalid function'));
    assert(obj.id, err('Invalid function id'));
    assert(obj.name, err('Invalid function name'));
    assert(obj.structure, err('Invalid function structure'));

    validateStruct(obj.structure, [flair]);
}
