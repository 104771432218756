import _ from "lodash";

/**
 * Prepares a builder log for the redux store
 */
export const convertLog = ({log, artifacts, checker}) => {
    const makeList = item => Array.isArray(item) ? item : [item];
    const result = {};

    const filterLogs = _.pickBy(log, (v, k) => k.startsWith('filter'))

    const handle = (ns = 'structure', log = {}, artifacts = {}) => {
        for (let path in log) {
            if (ns !== 'filter' && path.startsWith('filter')) {
                continue;
            }

            let finalPath = !path ? ns : (path[0] === '_' ? (ns + path) : (ns + '.' + path));
            if (ns === 'filter') {
                finalPath = path;
            }

            if (result[finalPath]) throw new Error(`Collision: ${finalPath}`);
            result[finalPath] = {
                path,
                result: log[path],
                artifacts: artifacts[path] ? makeList(artifacts[path]) : null,
            };
        }
    }

    handle('structure', log, artifacts);
    if (checker) handle('checker', checker.log, checker.artifacts);
    if (filterLogs) handle('filter', filterLogs, artifacts);

    return result;
}
