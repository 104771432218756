import {Button} from '@blueprintjs/core';
import type {OnChange} from '@monaco-editor/react';
import {PureComponent} from 'react';
import Editor from 'src/components/Editor';
import Select from 'src/components/Select';
import BuilderContext from 'src/utils/BuilderContext';

export default class ProductEditor extends PureComponent<{
    value: Record<string, any>;
    onChange: (product: Record<string, any>) => void;
    onClose: () => void;
}> {
    static contextType = BuilderContext;
    context!: React.ContextType<typeof BuilderContext>;

    state: { error: null | string, lastValue: any; size: number } = {
        error: null,
        lastValue: null,
        size: SIZES[1][0],
    }

    handleChange: OnChange = text => {
        const setError = (error: string | null) => this.setState(() => ({ error }));

        if (text == null) return setError('Invalid JSON');

        try {
            let json = JSON.parse(text);

            if (!json) return setError('Invalid JSON. Cannot be empty');

            if (typeof json !== 'object' || Array.isArray(json)) {
                return setError('Invalid JSON. Must be an object');
            }

            setError(null);
            this.setState({ lastValue: json });
        } catch (ex) {
            return setError('Invalid JSON. Check error markers in editor');
        }
    }

    render() {
        const { onClose } = this.props;
        const { error, lastValue, size } = this.state;
        const { pv2, setPv2, theme } = this.context;

        return <div className='product-editor'>
            <div className='product-editor-content' style={{ width: `${size}vw` }}>
                <div className='product-editor-actions' data-error={error ? '' : undefined}>
                    <Button
                        disabled={!!error}
                        onClick={() => setPv2(lastValue || pv2)}
                        intent='primary'
                    >Update{`${size > 25 ? ' Pv2' : ''}`}</Button>
                    <label>
                        {`${size > 25 ? 'Editor ' : ''}Size: `}
                        <Select
                            value={size}
                            options={SIZES}
                            onChange={(e: any) => this.setState({ size: parseInt(e.target.value) })}
                        />
                    </label>
                    <div style={{ flex: '1 1 0' }}>
                        {error &&
                            <div style={{ color: 'white' }}>{error}</div>
                        }
                    </div>
                    <Button onClick={onClose} intent='primary'>close</Button>
                </div>

                <Editor
                    onChange={this.handleChange}
                    style={{ width: '100%' }}
                    height='100%'
                    defaultValue={JSON.stringify(pv2, null, 4)}
                    theme={theme === 'dark' ? 'vs-dark' : undefined}
                />
            </div>
        </div>
    }
}

const SIZES: Array<[number, string]> = [
    [25, 'XS'],
    [40, 'S'],
    [60, 'M'],
    [80, 'L'],
];
