import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {getExpression} from '../utils/utils';
import ExpressionSelector from './ExpressionSelector/ExpressionSelector';
import FnExpression from './FnExpression';
import SortableList from '../components/SortableList/SortableList';
import Select from '../components/Select';

export default class ListExpression extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            // TODO
        }).isRequired,
    }

    static KEY = null

    static getStub (data) { return {$op: this.KEY, $value: data || [FnExpression.getStub()]} }

    rootProps = {
        'data-type': this.constructor.KEY,
    }

    render () {
        const {KEY} = this.constructor;
        const {data, data: {ID, change, plain, getChildPath, $value, state, operationOptions}} = this.props;
        const handleAdd = type => {
            const [id, ...args] = type.split(':');
            if (type === 'clipboard') {
                data.clipboardPaste('$value.' + plain.$value.length);
                return;
            }
            change('$value', [...plain.$value, getExpression(id).getStub(args)]);
        }

        const handleSwap = (sourceIndex, targetIndex) => {
            const newValue = [...plain.$value];
            const val = newValue[sourceIndex];
            newValue.splice(sourceIndex, 1);
            newValue.splice(targetIndex, 0, val);
            change('$value', newValue);
        }

        return <div className='expression-list' data-value={KEY}>
            <SortableList onSwap={handleSwap} showHandle={!data.log || !data.log.hasLog}>
                {$value.map((_item, index) =>
                    <ExpressionSelector
                        key={ID + '-' + index}
                        path={getChildPath(`$value.${index}`)}
                        data-last-child={index === $value.length - 1 ? true : undefined}
                        depth={data.depth + 1}
                    />
                )}
            </SortableList>
            {state.readOnly ? null :
                <Select
                    value={null}
                    onChange={event => handleAdd(event.target.value)}
                    options={operationOptions}
                    className={cx('expression-list-add', !$value.length && 'empty')}
                    rootProps={this.rootProps}
                >
                    <span>{$value.length ? '' : '+ Add an expression'}</span>
                </Select>
            }
        </div>
    }
}

export class AndExpression extends ListExpression { static KEY = 'and'; }

export class OrExpression extends ListExpression { static KEY = 'or'; }
