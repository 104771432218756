
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Only generates the props once, on init, for each wrapped component
 * Particularly useful for generating instance-specific selectors with reselect
 */
export default propsGenerator => WrappedComponent =>
    class WithPropsOnce extends PureComponent {
        constructor (props) {
            super(props);
            this.state = propsGenerator(props) || null;
        }

        render () {
            if (!this.state) return <WrappedComponent {...this.props} />;
            return <WrappedComponent {...this.props} {...this.state} />;
        }
    }
