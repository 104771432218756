import {
    structIterate,
    messageIterate,
    isBlock,
    formulaIterate,
    getMaxMessagePriority,
    concatPath,
} from '../utils/formula';
import { setIn, getRandomHash } from '../utils/utils';
import { convertLog } from '../utils/builder';
import _ from 'lodash';
import { getFlagInfo } from '../utils/flags';

/** ACTIONS + ACTION CREATORS **/

export const Actions = {};

const makeAction = (name, argDef=[], props={}) => {
    const type = 'formula/' + name;

    const result = (...args) => {
        const result = {...props, type};
        for (let index = 0; index < argDef.length; ++index) result[argDef[index]] = args[index];
        return result;
    };

    result.type = type;
    Actions[name] = result;

    return result;
}

makeAction('clipboardAdd',      ['struct']);
makeAction('clipboardPaste',    ['path']);
makeAction('makeFunction',      ['path'],           {history: true});
makeAction('remove',            ['path'],           {history: true});
makeAction('setArgs',           ['args']);
makeAction('setFormula',        ['data', 'uiOptions', 'history']);
makeAction('setFunctions',      ['functions'],      {history: true});
makeAction('setIn',             ['path', 'value'],  {history: true});
makeAction('setInMultiple',     ['path', 'value'],  {history: true});
makeAction('setLog',            ['runResult']);
makeAction('setOptions',        ['options']);
makeAction('setPresets',        ['value']);
makeAction('setState',          ['path', 'value']);
makeAction('toggleAll',         ['value']);
makeAction('undo',              []);
makeAction('setCollapseDepth',  ['collapseDepth']);

/** REDUCER **/

export const createDefaultStructure = () => ({
    $op: 'and',
    $value: [
        {
            $op: 'fn',
            $value: 'perServingExists',
            $args: ['calories'],
        } ,
    ],
});

// This gets extended bellow, this is not the default form but meh, code structuring sux
const DEFAULT_STATE = {
    history: [],
    log: null,
    checkerRun: null,
    uiOptions: null,
    data: {
        name: 'Unnamed',
        structure: createDefaultStructure(),
        checker: null,
        functions: [],
        options: [],
        presets: [],
    },
};

export default (state=DEFAULT_STATE, action) => {
    if (action.ns) {
        const newState = updateFormulaState({data: _.get(state, action.ns)}, action).data;
        const result = setIn(state, action.ns, newState);
        if (hasInfiniteLoop(result.data.functions)) return state;
        return result;
    }

    let newState = updateFormulaState(state, action);

    if (hasInfiniteLoop(newState.data.functions)) return state;

    if (!newState.errors) {
        newState = {...newState, errors: getErrors(newState)};
    }

    if (state === newState) return state;

    if (action.history && newState.data.structure !== state.data.structure) {
        newState = setIn(newState, 'history', newState.history.concat(state.data.structure));
    }

    newState.errors = getErrors(newState);

    return newState;
}

const CHECKER_OPERATIONS = [
    ['checker-message', 'checker message', 'Checker'],
    ['checker-list', 'checker list', 'Checker'],
    ['checker-table', 'checker table', 'Checker'],
    ['fn:builder', 'Builder Passed', 'Checker']
];

const getStateHelpers = state => {
    const getInData = (root, path) => _.get(root, concatPath('data', path));
    const setInData = (root, path, value) => {
        const fullPath = concatPath('data', path);
        const result = setIn(root, fullPath, value);

        // Update the state for every element on the path
        const pathArr = _.toPath(fullPath);
        const updateState = getStateUpdate(
            _.omit(state.uiOptions, ['resetState', 'clone']),
            {state: result, isChecker: pathArr.includes('checker')}
        );

        const isSchema = pathArr.slice(0, -1)
            .some((v, i) => _.get(result, pathArr.slice(0, i).concat(['$op'])) === 'schema');

        // Schemas has a similar format, but they should not be iterated upon
        if (!isSchema) {
            for (let index = pathArr.length; index >= 1; --index) {
                const obj = _.get(result, pathArr.slice(0, index));
                if (!obj || !obj.$op) continue;
                structIterate(obj, updateState);
            }
        }

        return result;
    };

    const getStateUpdate = ({checkerMode, hideFunctions, resetState}, {isChecker, state}) => (struct, path, parentStruct) => {
        const defaults = { readOnly: false };

        if (struct.$op === 'call') defaults.hidden = !!hideFunctions;

        if (resetState) struct.state = defaults;
        else {
            struct.state = struct.state || {};
            _.defaults(struct.state, defaults);
        }

        // These are forced-overrides, not defaults

        const isInChecker = !!(checkerMode && isChecker);
        Object.assign(struct.state, {
            isChecker: !!isChecker,
            // readOnly: (!isChecker && checkerMode),
            readOnly: !!state.log,
            otherOperations: isInChecker ? CHECKER_OPERATIONS : null,
            actions: {
                makeFunction: !isChecker && struct.$op !== 'call',
                checker: isInChecker,
                unwrap: isBlock(struct.$op) && _.size(struct.$value) === 1,
                addArtifacts: !struct.$artifacts,
            },
            parentType: !parentStruct ? null : parentStruct.$op,
            parentPath: !parentStruct ? null : parentStruct.state.path,
            path,
        });
    };

    return { getInData, setInData, getStateUpdate };
}

const updateFormulaState = (state, action) => {
    const { getInData, setInData, getStateUpdate } = getStateHelpers(state);

    switch (action.type) {
        case Actions.setIn.type:
            return setInData(state, action.path, action.value);

        case Actions.setInMultiple.type: {
            let result = state;
            for (let key in action.value) {
                result = setInData(result, concatPath(action.path, key), action.value[key]);
            }
            return result;
        }

        case Actions.setState.type:
            return setInData(state, concatPath(action.path, 'state'), action.value);

        case Actions.remove.type: {
            // You can't remove the top level path
            if (!action.path) return state;

            const keyList = _.toPath(action.path);
            const key = keyList[keyList.length - 1];
            const parentPath = keyList.slice(0, -1).join('.');
            let parent = getInData(state, parentPath);

            if (Array.isArray(parent)) {
                const numKey = parseInt(key, 10);
                if (Number.isNaN(numKey)) return state;
                parent = parent.slice(0, numKey).concat(parent.slice(numKey + 1));
            }
            else {
                parent = {...parent};
                delete parent[key];
            }

            return setInData(state, parentPath, parent);
        }

        case Actions.setFormula.type: {
            const uiOptions = _.defaults({}, action.uiOptions, {
                collapseDepth: 6,

                // Whether to clone action.data or not.
                // Changes need to be made to it so if you care about references that disable this
                clone: true,

                // Toggle functions by default
                hideFunctions: false,

                // Whether to nuke any existing state or just _.defaults() it with new props
                resetState: false,

                // Enable checker mode which disabled editing to the core structure
                checkerMode: false,
            });

            const data = uiOptions.clone ? clone(action.data) : action.data;

            const result = {
                data: _.defaults(data, {
                    functions: [],
                    options: [],
                    presets: [],
                    checker: null,
                    filter: null,
                }),
                history: action.history || [],
                log: null,
                uiOptions,
            };

            const updateState = getStateUpdate(uiOptions, {state: result});

            structIterate(result.data.structure, updateState);
            if (result.data.filter) structIterate(result.data.filter, updateState);

            for (let formula of result.data.functions) structIterate(formula.structure, updateState);

            if (uiOptions.checkerMode) {
                result.data.checker = result.data.checker || null;
                structIterate(
                    result.data.checker,
                    getStateUpdate(uiOptions, {state: result, isChecker: true})
                );

                let index = getMaxMessagePriority(result.data.checker);

                messageIterate(result.data.checker, (obj, path) => {
                    obj.$priority = obj.$priority || ++index;
                    obj.$value = _.get(getFlagInfo(obj.$value), ['key'], null);
                });
            }

            return result;
        }

        case Actions.toggleAll.type: {
            let result = state;
            for (let key of ['structure', 'checker', 'filter']) {
                const structure = state.data[key];

                if (!structure) continue;

                structIterate(structure, (item, path) => {
                    if (item.$op !== 'call') return;
                    if (item.state.hidden === action.value) return;
                    const fullPath = concatPath(key, concatPath(path, 'state.hidden'));
                    result = setInData(result, fullPath, !!action.value);
                });
            }
            return result;
        }

        case Actions.undo.type: {
            if (!state.history || !state.history.length) return state;
            const newHistory = Array.from(state.history);
            const newStructure = newHistory.pop();
            return {
                ...state,
                data: {
                    ...state.data,
                    structure: newStructure,
                },
                history: newHistory,
            };
        }

        case Actions.setLog.type: {
            const log = action.runResult && convertLog(action.runResult) || null;
            let result = setIn(state, 'log', log);
            result = setIn(result, 'checkerRun', _.get(action, 'runResult.checker', null));
            delete result.testsRun;

            if (action.runResult && action.runResult.tests) {
                const pass = _.filter(action.runResult.tests, obj => obj.result === true).length;
                const fail = _.filter(action.runResult.tests, obj => obj.result === false).length;
                result = setIn(result, 'testsRun', {
                    allPassed: fail === 0,
                    pass,
                    fail,
                    list: action.runResult.tests,
                });
            }
            result = { ...result, data: { ...result.data, _nested: {} } };

            // clear previous log messages
            for (let subPath of ['structure', 'filter']) {
                const structure = result.data[subPath];

                if (!structure) continue;

                structIterate(structure, (struct, callPath) => {
                    if (struct.$op !== 'call') return;

                    callPath = concatPath(subPath, callPath);

                    if (!result.log) {
                        result = setInData(result, concatPath(callPath, 'state.nestedPath'), null);
                        return;
                    }

                    const funcStruct = _.find(result.data.functions, {id: struct.$value});

                    const safeKey = callPath.replace(/\./g, '-');
                    const newStruct = clone(funcStruct.structure);
                    structIterate(newStruct, obj => {
                        obj.state.readOnly = true;
                    });
                    result.data._nested[safeKey] = newStruct;
                    const path = `_nested[${safeKey}]`;
                    result = setInData(result, concatPath(callPath, 'state.nestedPath'), path);

                    const key = callPath + '_nested';
                    const log = result.log[key] || {};

                    const artifactsMap = {};
                    for (let obj of log.artifacts || []) {
                        const bucket = obj.$map || {};
                        for (let key in bucket) {
                            artifactsMap[key] = artifactsMap[key] || [];
                            Array.isArray(bucket[key]) ?
                                artifactsMap[key].push(...bucket[key]) :
                                artifactsMap[key].push(bucket[key]);
                        }
                    }

                    // Create nested logs + artifacts
                    for (let subPath in log.result || {}) {
                        const finalPath = concatPath(path, subPath);
                        result.log[finalPath] = {
                            path: finalPath,
                            result: log.result[subPath],
                            artifacts: artifactsMap[subPath],
                        };
                    }

                    result = setInData(result, concatPath(subPath, path) + '.state.nestedPath', null);
                });
            }

            return result;
        }

        case Actions.setOptions.type: {
            let result = setIn(state, 'data.options', action.options);
            const map = _.keyBy(result.data.options, 'key');

            // Update presets if options change
            for (let opt of result.data.options) {
                const def = _.find(state.data.options, {key: opt.key});

                if (def && def.default === opt.default) continue;

                result.data.presets = _.map(result.data.presets, obj => ({
                    ...obj,
                    config: _.omitBy({
                        ...obj.config,
                        [opt.key]: opt.default,
                    }, (v, k) => !map[k]),
                }));
            }

            return result;
        }

        case Actions.setPresets.type: return setIn(state, 'data.presets', action.value);

        case Actions.setFunctions.type: {
            let result = setIn(state, 'data.functions', action.functions);
            const updateState = getStateUpdate(result.uiOptions, {state: result});
            const removedFunctions = _.map(
                _.differenceBy(state.data.functions, result.data.functions, 'id'),
                'id'
            );

            // Replace all calls to removed functions with fn:constant:false
            if (removedFunctions.length) {
                formulaIterate(result.data, (obj, path) => {
                    if (obj.$op !== 'call') return;
                    if (!removedFunctions.includes(obj.$value)) return;

                    result = setInData(
                        result,
                        path,
                        { $op: 'fn', $value: 'constant', $args: [ 'false' ] }
                    );
                });
            }

            for (let formula of result.data.functions) structIterate(formula.structure, updateState);

            return result;
        }

        case Actions.setArgs.type: return setIn(state, 'data.args', action.args);

        case Actions.makeFunction.type: {
            const structure = getInData(state, action.path);
            const id = getRandomHash();
            let result = state;

            // create new function
            result = setIn(result, 'data.functions', [
                ...getInData(result, 'functions'),
                {
                    id,
                    name: `Function: ${id}`,
                    args: [],
                    structure,
                }
            ])

            // replace old code with call
            result = setInData(result, action.path, { $op: 'call', $value: id });

            // hide it automatically
            result = setInData(result, concatPath(action.path, 'state.hidden'), true);

            return result;
        }

        case Actions.clipboardAdd.type: return setIn(state, 'clipboard', action.struct);

        case Actions.clipboardPaste.type:
            return !state.clipboard ? state : setInData(state, action.path, cloneStruct(state.clipboard));

        case Actions.setCollapseDepth.type:
            return setIn(state, 'uiOptions.collapseDepth', action.collapseDepth);
    }

    return state;
}

const cloneStruct = struct => {
    const result = clone(struct);

    structIterate(result, obj => {
        delete obj.ID;
    });

    return result;
}

const clone = obj => JSON.parse(JSON.stringify(obj));

const getErrors = ({data: {structure}}) => {
    // TODO: this is disabled till a new format is implemented
    return {};

    const result = {};

    structIterate(structure, (obj, path) => {
        if (isEmpty(obj.$checker)) return;

        const trueEmpty = isEmpty(obj.$checker.true);
        const falseEmpty = isEmpty(obj.$checker.false);
        const templateEmpty = isEmpty(obj.$checker.template);

        const error = msg => {
            result[path] = result[path] || [];
            result[path].push(msg);
        }

        if (trueEmpty && falseEmpty) return;

        if (trueEmpty) error('Missing $checker.true');
        if (falseEmpty) error('Missing $checker.false');
        if (templateEmpty) error('Missing $checker.template');
        else if (obj.$checker.template.includes('{{')) error('$checker.template should be plain text (no {{magic}} permitted)');
    });

    return result;
}

const isEmpty = val => val == null || val === '' || (typeof val === 'string' && val.trim() === '');

const hasInfiniteLoop = (functions=[]) => {
    const map = _.keyBy(functions, 'id');

    const check = ({structure}, visited) => {
        let result = false;
        structIterate(structure, ({$op, $value}) => {
            if ($op !== 'call') return;
            if (!result && visited.includes($value)) {
                const path = visited.concat([$value]).map(id => map[id].name).join(' → ');
                console.warn(`Infinite Loop: ${path}`);
                throw new Error(`Infinite Loop: ${path}`);
                result = true;
                return;
            }
            check(map[$value], [...visited, $value]);
        });
        return result;
    }

    for (let formula of functions) {
        if (check(formula, [formula.id])) return true;
    }

    return false;
}

/* init */
const defaultStateUpdater = getStateHelpers(DEFAULT_STATE).getStateUpdate({}, { state: DEFAULT_STATE });
structIterate(DEFAULT_STATE.data.structure, defaultStateUpdater);
