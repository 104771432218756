
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {withPropsOnChange, defaultProps} from 'recompose';

const NO_VALUE = '[no value]';

const styles = {
    root: {
        display: 'inline-flex',
    },
};

const ALLOWED_TYPES = ['var', 'raw', 'option'];

@defaultProps({
    rawOption: false,
    rawOptionDefault: '',
    renderRawOption: ({onChange, defaultValue}) =>
        <input key={defaultValue} type='text' onBlur={onChange} defaultValue={defaultValue} />,
})
@withPropsOnChange(['options', 'value'], ({options, value, rawOptionDefault, rawOption}) => {
    value = value == null ? {type: 'option', value: null} : value;

    options = options.map((item, index) => ({
        type: 'option',
        ...item,
    }));

    if (rawOption) {
        options = [{
            type: 'raw',
            value: rawOptionDefault,
            label: 'Input value',
            group: 'Misc',
        }].concat(options);
    }

    let match = _.find(
        options,
        value.type === 'raw' ? { type: 'raw' } : _.pick(value, ['type', 'value'])
    );

    if (value.value == null && !match) {
        match = {
            type: 'option',
            value: null,
            label: '[no value]',
            group: 'Misc',
            props: {
                disabled: true,
            },
        };
        options = [match].concat(options);
    }

    options.forEach((opt, index) => { opt.id = '' + index; });

    const label = '' + (match.label || match.value);
    const selectValue = match.id;

    return {
        selectValue,
        value,
        options,
        label,
    };
})
class SelectType extends PureComponent {
    static propTypes = {
        selectValue: PropTypes.string.isRequired,
        value: PropTypes.shape({
            type: PropTypes.oneOf(ALLOWED_TYPES).isRequired,
            value: PropTypes.any,
        }),
        options: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.oneOf(ALLOWED_TYPES).isRequired,
            value: PropTypes.any,
            label: PropTypes.string,
            group: PropTypes.string,
        })).isRequired,
        onChange: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,

        rawOption: PropTypes.bool.isRequired,
        renderRawOption: PropTypes.func.isRequired,
        rawOptionDefault: PropTypes.any.isRequired,
    };

    handleChange = ({target: {value}}) => {
        const {onChange, options} = this.props;
        const match = _.find(options, {id: value});
        if (!match) return;
        onChange(_.pick(match, ['type', 'value']));
    }

    handleRawChange = event => {
        const {onChange} = this.props;
        const value = event instanceof Event ? event.target.value : event;
        onChange({type: 'raw', value});
    }

    renderGroups (options) {
        const groupMap = {};
        for (let opt of options) {
            let {group} = opt;
            group = group != null ? group : 'Default';
            groupMap[group] = groupMap[group] || [];
            groupMap[group].push(opt);
        }

        return Object.keys(groupMap).map(label =>
            <optgroup key={label} label={label}>
                {this.renderOptions(groupMap[label])}
            </optgroup>
        );
    }

    renderOptions (options) {
        return options.map(({value, label, id, props}) =>
            <option {...props} key={id} value={id}>{label || val}</option>
        );
    }

    render () {
        const {
            onChange,
            selectValue,
            value,
            options,
            label,
            rawOption,
            renderRawOption,
            rawOptionDefault,
            className,
            ...props
        } = this.props;

        const children = options.some(item => item.group) ?
            this.renderGroups(options) :
            this.renderOptions(options);

        return <div className={cx('select', className)} style={styles.root}>
            <div className='select-handle'>
                {label}
                <select
                    value={selectValue}
                    {...props}
                    tabIndex='-1'
                    onChange={this.handleChange}
                >
                    {children}
                </select>
            </div>

            {value.type !== 'raw' ? null :
                renderRawOption({defaultValue: value.value, onChange: this.handleRawChange})
            }
        </div>;
    }
}

export default SelectType;
