import _ from 'lodash';
import textBlock from '@pinto/text-block';

import external from '../external';
import type { UiFormulaNode } from 'src/typeDefs';
import type { ExternalData } from 'src/hooks/useExternalData';
import getArgumentType from './getArgumentType';

const varEqualsFormatter = ([varInput, ...rest]: any[]) => [
    varInput,
    <b key='eq'>＝</b>,
    ...rest,
];

export interface Method {
    key: string;
    label: string;
    description: string;
    deprecated?: boolean;
    group?: string;
    showInlineArtifacts?: boolean;
    args: Array<| MethodArg
        | ((config: { formulaOptions: any[]; data: UiFormulaNode }) => MethodArg | null)>;
    formatArgs?: (...args: any[]) => any[];
    argTypes?: string[];
    examples?: Array<{
        name: string;
        result: boolean;
        args: string[];
        argValues?: (string | null)[];
        outputVars?: Record<string, any>;
    }>;
}

export interface MethodArg {
    type: string;
    name: string;
    label: string;
    description: string;

    [key: string]: any;
}

/**
 * Mainly used in FnExpression
 */
const setup = (external: ExternalData) => {
    const basic: Method[] = [
        {
            key: 'if',
            label: 'if',
            group: 'Basic',
            description: 'if',
            args: []
        },
        {
            key: 'and',
            label: 'and',
            group: 'Basic',
            description: 'and',
            args: []
        },
        {
            key: 'or',
            label: 'or',
            group: 'Basic',
            description: 'or',
            args: []
        },
        {
            key: 'for',
            label: 'for each',
            group: 'Basic',
            description: 'for',
            args: []
        }
    ];

    const readWrite: Method[] = [
        {
            key: 'ref',
            label: 'ref',
            group: 'Read/Write',
            description: 'reference',
            args: []
        },
        {
            key: 'hasTaxonomy',
            label: 'Has Taxonomy',
            group: 'Read/Write',
            description: '',
            args: [
                fnArgSelect('taxonomy', 'Taxonomy', undefined, external.taxonomyOptions),
            ],
        },
        {
            key: 'pv2AddAttributes',
            label: 'Pv2: Add Attributes',
            group: 'Read/Write',
            description: textBlock`
                Adds the given attributes to Product.attributes and Pv2.attributes
            `,
            args: [
                fnArg(
                    'text',
                    'attributes',
                    'Attributes',
                    'Accepts a single object or a list of objects',
                    {canBeTextarea: true},
                ),
            ],
        },
        {
            key: 'pv2RemoveAttributes',
            label: 'Pv2: Remove Attributes',
            group: 'Read/Write',
            description: textBlock`
                Removes all attributes with the given type or uid patterns
            `,
            args: [
                fnArg(
                    'text',
                    'attributes',
                    'Attributes',
                    'Accepts a single object or a list of objects',
                    {canBeTextarea: true},
                ),
            ],
        },
        {
            key: 'perServing',
            label: 'Serving',
            group: 'Read/Write',
            deprecated: true,
            description: textBlock`
                Describes a range for the serving size of a specific nutrient
            `,
            args: [
                getArgumentType('NUTRIENT'),
                fnArg(
                    'smalltext',
                    'value',
                    'Value',
                    'Can either be a number for exact matching (example: 40 will match products with exactly 40 of the given nutrient)\n\nIt can also specify a range (example: < 30 or >= 42)',
                ),
            ],
        },
        {
            key: 'perServingExists',
            label: 'Has Serving',
            group: 'Read/Write',
            deprecated: true,
            description: textBlock`
                Checks to see if a product has a serving size defined for a given nutrient (regardless of its value)
            `,
            args: [
                getArgumentType('NUTRIENT'),
            ],
        },
        {
            key: 'export',
            label: 'Export',
            group: 'Read/Write',
            description:
                'List of variables to export. Exported variables will show up in parent expressions when this formula is used as a REF',
            args: [fnArg('text', 'variable names')],
        },
    ]

    const variableRelated: Method[] = [
        {
            key: 'math',
            label: 'MATH',
            group: 'Variable-Related',
            description: textBlock`
                Compare a variable to a numeric value
            `,
            showInlineArtifacts: true,
            args: [getExpressionArg(), fnArg('smalltext', 'value', 'Value')],
        },
        {
            key: 'exists',
            label: 'Exists',
            group: 'Variable-Related',
            description: textBlock`
                Check if the given expression is non-nullable (null, undefined)

                Opationally check if it is also not empty (empty string "", empty array [])
            `,
            showInlineArtifacts: true,
            args: [
                getExpressionArg(),
                fnArg('boolean', 'notEmpty', 'and not empty', ''),
            ],
        },
        {
            key: 'varSet',
            label: 'Set Var',
            group: 'Variable-Related',
            description: textBlock`
                Store a value to be used later on
            `,
            showInlineArtifacts: true,
            args: [
                fnArg('text', 'name', 'Var Name', '', {minWidth: 75}),
                getExpressionArg({canBeTextarea: true}),
            ],
        },
        {
            key: 'varRefSet',
            label: 'Store Ref List',
            group: 'Variable-Related',
            description: textBlock`
                Store one or more reference lists into a variable
            `,
            showInlineArtifacts: true,
            args: [fnArg('text', 'name', 'Var Name', '', {minWidth: 75}), fnArg('ref', 'ref', 'Ref')],
            formatArgs: varEqualsFormatter,
        },
        {
            key: 'option',
            label: 'Option',
            group: 'Variable-Related',
            description: textBlock`
                Use the value of an option
            `,
            args: [
                fnArgSelect('name', 'Name', undefined, [], {
                    getOptions: ({formulaOptions}) =>
                        formulaOptions
                            .filter((obj) => obj.type === 'boolean')
                            .map(({key}) => [key, key]),
                }),
                ({formulaOptions, data: {$args}}) => {
                    if (!$args?.[0]) return null;

                    const opt = _.find(formulaOptions, {key: $args[0]});
                    if (!opt || opt.type !== 'boolean') return null;

                    return fnArgSelect('value', 'Value', undefined, ['true', 'false']);
                },
            ],
        },
        {
            key: 'constant',
            label: 'Constant',
            group: 'Variable-Related',
            description: textBlock`
                A constant value. Useful to force the result of an expression.
            `,
            args: [
                fnArgSelect('value', 'Value', undefined, [
                    ['true', 'True'],
                    ['false', 'False'],
                ]),
            ],
        }
    ]

    const operators: Method[] = [
        {
            key: 'arrayFilter',
            label: 'Array: filter',
            group: 'Operators',
            showInlineArtifacts: true,
            description: textBlock`
                Filter elements of an array based on a dynamic condition. If the condition returns false the element will be removed.
                Filtered values are stored in a separate variable denoted by \`target\`.

                Dynamic variables available in the \`expression\` input:

                - **ITEM**: the value of the current element
                - **KEY**: the index of the current element (starts at 0)
            `,
            args: [
                fnArg('smalltext', 'var', undefined, 'variable name to save filtered array to'),
                fnArg('smalltext', 'target', undefined, 'entity to filter (must be an array)'),
                fnArg('text', 'expression', undefined, textBlock`
                    Expression to filter by.
                    Available vars:

                    - **ITEM**: the value of the current element
                    - **KEY**: the index of the current element (starts at 0)
                `),
            ],
            formatArgs: varEqualsFormatter,
        },
        {
            key: 'arrayFind',
            label: 'Array: find one',
            group: 'Operators',
            showInlineArtifacts: true,
            description: textBlock`
                Finds the first element in the array that matches the given condition.
                Filtered values are stored in a separate variable denoted by \`target\`.

                Dynamic variables available in the \`expression\` input:

                - ITEM - the value of the current element
                - KEY - the index of the current element (starts at 0)
            `,
            args: [
                fnArg('smalltext', 'var'),
                fnArg('smalltext', 'target'),
                fnArg('text', 'expression'),
            ],
            formatArgs: varEqualsFormatter,
        },
        {
            key: 'arrayFindStringAtIndex',
            label: 'Array: find string at index',
            group: 'Operators',
            showInlineArtifacts: true,
            description: textBlock`
                Stores the first result in an array of strings before/after a given position
            `,
            args: [
                fnArg('smalltext', 'var'),
                fnArg('smalltext', 'input'),
                fnArg('smalltext', 'position'),
                fnArg('ref', 'ref', 'find', '', {allowInput: true}),
                fnArg('ref', 'ref', 'exclude', '', {allowInput: true, falsePositives: true}),
            ],
            formatArgs: varEqualsFormatter,
        },
        {
            key: 'stringContains',
            label: 'String: contains',
            group: 'Operators',
            showInlineArtifacts: true,
            description: textBlock`
                Checks if the given string contains the given set of tokens
            `,
            args: [
                fnArg('smalltext', 'var'),
                fnArg('smalltext', 'target'),
                fnArg('ref', 'ref', 'search', '', {allowInput: true}),
                fnArg('ref', 'ref', 'exclude', '', {allowInput: true, falsePositives: true}),
            ],
            formatArgs: varEqualsFormatter,
        },
        {
            key: 'stringContainsWord',
            label: 'String: contains word',
            group: 'Operators',
            showInlineArtifacts: true,
            description: textBlock`
                Checks if the given string contains the given set of words
            `,
            args: [
                fnArg('smalltext', 'var'),
                fnArg('smalltext', 'target'),
                fnArg('ref', 'ref', 'search', '', {allowInput: true}),
                fnArg('ref', 'ref', 'exclude', '', {allowInput: true, falsePositives: true}),
            ],
            formatArgs: varEqualsFormatter,
        },
        {
            key: 'stringRegExp',
            label: 'RegExp',
            group: 'Operators',
            showInlineArtifacts: true,
            description:
                'Must be a valid regexp, including start and end /. Take care of escape characters',
            args: [
                fnArg('smalltext', 'var'),
                fnArg('smalltext', 'target'),
                fnArg('text', 'regexp'),
                fnArg('text', 'exclude'),
            ],
            formatArgs: varEqualsFormatter,
        }
    ]

    const deprecated: Method[] = [
        {
            key: 'schema',
            label: 'schema',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                Schema
            `,
            args: [],
        },
        {
            key: 'name',
            label: 'Name Patterns',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                Look for patterns in the product name.
            `,
            args: [fnArg('text', 'word', 'Word Patterns', 'Separate each substring by ";"')],
        },
        {
            key: 'hasCategory',
            label: 'Has Category',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                Validates products with a specific category
            `,
            args: [
                fnArgSelect(
                    'category',
                    'Category',
                    undefined,
                    [],
                ),
            ],
        },
        {
            key: 'hasSubCategory',
            label: 'Has Sub-Category',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                Validates products with a specific subCategory
            `,
            args: [
                fnArgSelect(
                    'subCategory',
                    'Sub-Category',
                    undefined,
                    [],
                ),
            ],
        },
        {
            key: 'hasIngredientsRef',
            label: 'Reference List',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                Matches the product's ingredients against against well defined classes of ingredients and aliases
            `,
            args: [
                fnArgSelect(
                    'ingredient',
                    'Ingredient',
                    undefined,
                    Object.keys((external as any).ingredientTypes || {}).sort(),
                ),
            ],
        },
        {
            key: 'hasIngredientSubstring',
            label: 'Ingredient Substring',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                Look for patterns in ingredients.

                Example: "phos" would validate all products that have ingredients containing that substring (like polyphosphate or phosphorus)
            `,
            args: [fnArg('text', 'word', 'Word')],
        },
        {
            key: 'hasIngredients',
            label: 'Ingredient',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                A list of ingredients to match against.
                It looks for exact matches (with plural prefixes automatically added, e.g. for "apple" it will also match "apples")

                Use the 2nd textarea to remove false positives.
                **Example:** if you want to match \`oil\`, but not \`essential oil\`, you would add \`oil\` in the first input and \`essential oil\` in the second one
            `,
            args: [
                fnArg('textarea', 'list', 'Ingredient List'),
                fnArg('textarea', 'excludeList', 'Exclude False Positives'),
            ],
        },
        {
            key: 'hasAllergen',
            label: 'Has Allergen',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                Flag a product if it has the given predefined allergen
            `,
            args: [
                fnArgSelect('allergen', 'Allergen', undefined, []),
            ],
        },
        {
            key: 'hasFacilityAllergen',
            label: 'Has Facility Allergen',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                Flag a product if it has the given predefined facility allergen
            `,
            args: [
                fnArgSelect('allergen', 'Allergen', undefined, []),
            ],
        },
        {
            key: 'hasDiet',
            label: 'Has Diet',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                Check if the product has the given predefined diet
            `,
            args: [fnArgSelect('diet', 'Diet', undefined, [])],
        },
        {
            key: 'hasBadge',
            label: 'Has Badge',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                Check if the product has the given predefined badge
            `,
            args: [fnArgSelect('badge', 'Badge', undefined, [])],
        },
        {
            key: 'hasCertification',
            label: 'Has Certification',
            group: 'Deprecated',
            deprecated: true,
            description: textBlock`
                Check if the product has the given certification
            `,
            args: [
                fnArgSelect(
                    'certification',
                    'Certification',
                    undefined,
                    [],
                ),
            ],
        },
        {
            key: 'nutrientDensityScore',
            deprecated: true,
            group: 'Deprecated',
            label: 'Nutrient Density',
            description: textBlock`
                Match products with the given nutrient density score
            `,
            args: [fnArgSelect('score', 'Score', undefined, ['low', 'ok', 'good', 'excellent'])],
        },
        {
            key: 'priceNutrientRatioScore',
            deprecated: true,
            group: 'Deprecated',
            label: 'Price Nutrient Ratio',
            description: textBlock`
                Match products with the given price to nutrient ratio score
            `,
            args: [
                fnArg('select', 'score', 'Score', undefined, {
                    options: [['low'], ['ok'], ['good'], ['excellent']],
                }),
            ],
        },
        {
            key: 'get',
            label: 'Get',
            group: 'Deprecated',
            deprecated: true,
            showInlineArtifacts: true,
            description: textBlock`
                Get nested a path from an object or array
            `,
            args: [fnArg('smalltext', 'var'), fnArg('smalltext', 'target'), fnArg('text', 'path')],
            formatArgs: varEqualsFormatter,
        },
    ]

    const METHODS: Method[] = [
        ...basic,
        ...variableRelated,
        ...operators,
        ...readWrite,
        ...deprecated
    ];

    const getMethod = (name: string) => METHODS.find(obj => obj.key === name);

    return {
        METHODS,
        getMethod,
    };
};

export type MethodArgumentType = 'enum' | 'boolean' | 'select' | 'text' | 'smalltext' | 'ref' | 'textarea' | 'multiselect';

const fnArg = (
    type: MethodArgumentType,
    name: string,
    label: string = name,
    description: string = '',
    custom: Record<string, any> = {},
): MethodArg => ({
    type,
    name,
    label,
    description,
    ...custom,
});

const fnArgMultiselect = (label: string, options: Array<{ key: string; label: string }>) =>
    fnArg('multiselect', label, label, '', {
        options,
        placeholder: label,
    });

const fnArgSelect = (
    name: string,
    label: string,
    description: string | undefined,
    options: any[],
    custom?: {
        getOptions: (config: { formulaOptions: any[]; data: UiFormulaNode }) => any[];
        [key: string]: any;
    },
) =>
    fnArg('select', name, label, description, {
        ...custom,
        options: options.map(str => Array.isArray(str) ? str : [str, str]),
    });

const getExpressionArg = (custom?: Record<string, any>) =>
    fnArg(
        'text',
        'expression',
        'Expression',
        ['Write a math expression that will evaluate to a numeric value',
            '',
            'Supported operations: + - * / ()',
            'Shorthand version of nutrients can be used (e.g. protein instead of nutritionMap.protein.perServing)',
            '',
            'Examples: ',
            '    price / nutritionMap.calories.perServing',
            '    (protein * 4 + fats * 9) / calories',
        ].join('\n'),
        custom
    );

const setupOnce = _.once(() => setup(external));

export const getMethod = (name: string) => setupOnce().getMethod(name);
export const getAllMethods = () => setupOnce().METHODS;
export const getGroupMethods = (group: string) => _.filter(getAllMethods(), ['group', group]);

