import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import external from '../external';
import Select from '../components/Select';

const formulaSelectList = [['', '<none>']].concat(
    external.formulaList
        .map(obj => [obj._id, obj.name + ' - v' + obj.version])
        .sort((a, b) => a[1].localeCompare(b[1])),
);

export default class RefExpression extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            // TODO
            change: PropTypes.func.isRequired,
        }).isRequired,
    };

    static getStub () { return {$op: 'ref', $value: null}; }

    render () {
        const {data: {state, change, $value}} = this.props;

        return <div className='expression-ref'>
            <Select
                value={$value || ''}
                options={formulaSelectList}
                onChange={event => change('$value', event.target.value)}
                title={'Select a global reference'}
                readOnly={state.readOnly}
            />
        </div>;
    }
}
