import {  Root, Handle, Dropdown, DropdownTitle  } from 'file:///root/project/node_modules/.cache/react-style-loader/expressions_FnExpression-VU4w.styl';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';

import {getMethod} from '../utils/methods';
import Select from '../components/Select';
import TextArea from '../components/TextArea/TextArea';
import TextInput from '../components/TextInput';
import { GlobalResourceDrawerHandle } from 'src/components/ResourceDrawer';
import { Switch } from '@blueprintjs/core';

@connect(state => ({
    formulaOptions: state.formula && state.formula.data && state.formula.data.options,
}))
class FnExpression extends PureComponent {

    static propTypes = {
        data: PropTypes.shape({
            // TODO:
        }).isRequired,
        formulaOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    }

    static getStub = ([type='perServing']=[]) => FnExpression.getStubFor(type)

    static getStubFor = name => ({
        $op: 'fn',
        $value: name,
        $args: _.cloneDeep(getMethod(name).args).map(arg => arg.type !== 'match' ? null : arg.list.fill(null)),
    })

    renderArg (item, index) {
        if (typeof item === 'function') item = item(this.props);
        if (!item) return null;

        const {prefix, name, type, label, description, options, list, validate, allowInput, falsePositives, getOptions, ...rest} = item;
        const {data: {ID, change, $value, $args, state}} = this.props;
        const key = (prefix ? [prefix, index] : [index]).join('.');

        const _onChange = value => {
            if (_.get($args, key) === value) return
            return change('$args.' + key, value);
        }

        const onChange = event => _onChange(event.target.value);

        // interactive elements - like dropdowns - need to stay visible between changes
        const keyWithoutArgValue = `${ID}-${$value}-${prefix}-${index}`;

        const props = {
            ...rest,
            key: `${ID}-${$value}-${_.get($args, key)}-${prefix}-${index}`,
            name,
            title: description,
            className: `expression-fn-${type}`,
        };
        const normalProps = {
            ...props,
            readOnly: state.readOnly,
            value: _.get($args, key),
            onChange,
        };
        const inputProps = {
            ...props,
            autoComplete: 'off',
            readOnly: state.readOnly,
            defaultValue: _.get($args, key),
            onBlur: onChange,
        };

        const getExtendedValue = item => {
            const {data} = this.props;
            const {key, value} = item;
            const extra = _.get(data, `rootRef.varTypes[${key}]`, []);
            if (!extra.length) return value;
            return [
                ...extra.map(name => [name, 'args.' + name, 'Variables']),
                ...value,
            ];
        }

        switch (type) {
            // new
            case 'enum': return <Select {...normalProps} options={getExtendedValue(item)} />;
            case 'boolean':
                return <Switch
                    {...normalProps}
                    value={undefined}
                    checked={normalProps.value}
                    onChange={e => _onChange(e.target.checked)}
                    label={label}
                    className='mb0 ml05'
                />;
            // old
            case 'select':
                return <Select
                    {...normalProps}
                    options={item.getOptions ? item.getOptions(this.props) : options}
                />;
            case 'text':
            case 'smalltext':
                return <TextInput
                    className={cx(inputProps.className, type === 'smalltext' && 'small')}
                    placeholder={label}
                    minWidth={60}
                    maxWidth={400}
                    {...inputProps}
                    onBlur={undefined}
                    onChange={_onChange}
                />;
            case 'ref': {
                const showTextField = allowInput && !Array.isArray(normalProps.value);
                const handle = <GlobalResourceDrawerHandle
                    key={keyWithoutArgValue}
                    readOnly={normalProps.readOnly}
                    emptyLabel={allowInput ? null : '[Select List]'}
                    allowEmptyValue={!!allowInput}
                    value={showTextField ? [] : normalProps.value || []}
                    onChange={val => _onChange(_.isEmpty(val) ? null : val)}
                />;

                if (!showTextField) return handle;

                return <React.Fragment key={keyWithoutArgValue}>
                    {handle}
                    <TextInput
                        className='text'
                        minWidth={60}
                        maxWidth={400}
                        placeholder={label}
                        {...inputProps}
                        onChange={value => _onChange(value ? value.trim() : null)}
                    />
                </React.Fragment>
            }
            case 'textarea':
                return <TextArea
                    placeholder={label}
                    cols={35}
                    {...props}
                    readOnly={state.readOnly}
                    defaultValue={inputProps.defaultValue}
                    onChange={(val, event) => onChange(event)}
                />;
            case 'match':
                return <div {...props}>
                    {list.map((arg, subIndex) =>
                        <label key={subIndex}>
                            {arg.label}:
                            {this.renderArg({
                                ...arg,
                                prefix: (prefix || '') + index,
                            }, subIndex)}
                        </label>
                    )}
                </div>;
        }

        throw new Error(`Invalid arg type: '${type}'`);
    }

    render () {
        const { data: { $value, log } } = this.props;
        const fnData = getMethod($value);

        if (!fnData) throw new Error(`Invalid Function name: '${$value}'`);

        const formatArgs = fnData.formatArgs || (args => args);

        return <div className='expression-fn'>
            {formatArgs(fnData.args.map((item, index) => this.renderArg(item, index)))}
            {fnData.showInlineArtifacts && log && <InlineArtifacts log={log} />}
        </div>
    }
}

export default FnExpression;

class InlineArtifacts extends PureComponent {

    static propTypes = {
        log: PropTypes.shape({
            hasLog: PropTypes.bool.isRequired,
        }).isRequired,
    }

    state = { expanded: false }

    renderDropdown (handle, renderContent = (() => handle)) {
        return <div
            onMouseEnter={() => this.setState({ expanded: true })}
            onMouseLeave={() => this.setState({ expanded: false })}
        >
            <Handle>{handle}</Handle>
            {this.state.expanded && <Dropdown>{renderContent()}</Dropdown>}
        </div>
    }

    render () {
        const { log, ...rest } = this.props;

        if (!log || !log.hasLog) return null;

        const value = _.map(log.artifacts, '$value')[0];

        return (
            <Root {...rest}>
                {Array.isArray(value)
                    ? this.renderDropdown(
                        (!value[0] || typeof value[0] === 'string')
                            ? `list[${value.length}] = ${value.join('; ')}`
                            : `list[${value.length}] = ${JSON.stringify(value)}`,
                        () => <React.Fragment>
                            <DropdownTitle>{value.length} items</DropdownTitle>
                            {value.map((x, i) =>
                                <pre key={i} style={{ margin: 0 }}>
                                    {x != null && typeof x === 'object'
                                        ? JSON.stringify(x, null, 4)
                                        : x
                                        }
                                </pre>
                            )}
                        </React.Fragment>,
                    )
                    : this.renderDropdown(
                        JSON.stringify(value),
                        () => <pre style={{ fontSize: '.75rem' }}>{JSON.stringify(value, null, 4)}</pre>,
                    )
                }
            </Root>
        );
    }

}

