import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {connect} from 'react-redux';

import TextField from '../components/TextField/TextField';
import ExpressionSelector from './ExpressionSelector/ExpressionSelector';
import ListExpression from './ListExpression';
import FnExpression from './FnExpression';
import Select from '../components/Select';
import WithFormulaData from '../decorators/WithFormulaData';
import { getRandomName } from '../utils/utils';

const makeGetStub = $op => ([$value, ...$args]) => ({ $op, $value, $args });

export class CheckerMessage extends PureComponent {

    static propTypes = {
        data: PropTypes.object.isRequired,
        placeholder: PropTypes.string,
    }

    static defaultProps = {
        placeholder: 'text:',
    }

    static getStub = (...args) => ({
        $id: 'msg_' + getRandomName(),
        ...makeGetStub('checker-message')(...args),
    })

    static options = [
        ['good', 'Good', 'Flags'],
        ['amazing', 'Amazing', 'Flags'],
        ['warn', 'Warn', 'Flags'],
        ['bad', 'Bad', 'Flags'],
        ['terrible', 'Terrible', 'Flags'],
    ]

    renderLabel = (label, value) =>
        <span className='CheckerSelectMessage' data-type={value}>{label}</span>

    render () {
        const {data, placeholder, path, dispatch, style, ...props} = this.props;

        return (
            <div {...props} style={{...style, display: 'flex', minWidth: '20rem'}}>
                <Select
                    value={data.$value}
                    options={CheckerMessage.options}
                    onChange={event => data.change('$value', event.target.value)}
                    renderLabel={this.renderLabel}
                />
                <TextField
                    defaultValue={data.$args[0]}
                    onChange={val => data.change('$args[0]', val)}
                    placeholder={placeholder}
                    style={{flex: '1 1 0'}}
                />
            </div>
        );

    }
}

export class CheckerVar extends PureComponent {

    static getStub = makeGetStub('checker-var')

    render () {
        const {data} = this.props;

        return (
            <div>
                <TextField
                    defaultValue={data.$args[0]}
                    onChange={val => data.change('$args[0]', val)}
                    placeholder='name:'
                />
                :
                <TextField
                    defaultValue={data.$args[1]}
                    onChange={val => data.change('$args[1]', val)}
                    placeholder='value (math):'
                />
            </div>
        );

    }
}

const selectLog = (state, path, index) => {
    path = path + '.$value.' + index;
    return state.formula.log[path] && state.formula.log[path].result || null;
};

@connect((state, {data: {log, path}}) => ({
    childResult: !log || !log.hasLog ? null :
        selectLog(state, path, 0) + '-' + selectLog(state, path, 1)
}))
class _CheckerTable extends PureComponent {

    static propTypes = {
        childResult: PropTypes.string,
    }

    static getStub = () => ({
        $op: 'checker-table',
        $value: [
            FnExpression.getStub(['perServingExists']),
            FnExpression.getStub(['perServingExists']),
        ],
        $args: [
            CheckerMessage.getStub(['good']),
            CheckerMessage.getStub(['good']),
            CheckerMessage.getStub(['good']),
            CheckerMessage.getStub(['good']),
        ],
    })

    render () {
        const {childResult, data: {depth, getChildPath, log}} = this.props;

        const table = [
            [true, true],
            [true, false],
            [false, true],
            [false, false],
        ];

        const isSelected = (first, second) => {
            const _log = _.get(log, 'artifacts[0]');
            return !!_log && `${first}-${second}` === childResult;
        };

        return (
            <div className='expression-checker-table'>
                <div className='row-center'>
                    <Label>first:</Label>
                    <ExpressionSelector depth={depth + 1} path={getChildPath('$value.0')} />
                </div>
                <div className='row-center'>
                    <Label>second:</Label>
                    <ExpressionSelector depth={depth + 1} path={getChildPath('$value.1')} />
                </div>

                <div className={cx('expression-selector-table-header', 'row-center')}>
                    <Label children='first' />
                    <Label children='second' />
                    <Label children='message' />
                </div>

                {table.map(([first, second], index) =>
                    <div
                        key={index}
                        className={cx(
                            'row-center',
                            isSelected(first, second) && 'expression-checker-table-highlighted'
                        )}
                    >
                        <Label type={first}>{'' + first}</Label>
                        <Label type={second}>{'' + second}</Label>
                        <InlineCheckerMessage
                            path={getChildPath(`$args[${index}]`)}
                            placeholder={`message for ${first} × ${second}`}
                            style={{flex: '1 1 0'}}
                        />
                    </div>
                )}
            </div>
        );

    }
}

export const CheckerTable = _CheckerTable;

export const Label = ({type, children, className, ...rest}) =>
    <div
        {...rest}
        data-value={'' + type}
        className={cx(className, 'expression-selector-table-label')}
    >{children}</div>;

export const InlineCheckerMessage = WithFormulaData(CheckerMessage);

const checkerListSelectOptions = [
    ['always', 'Always'],
    ['never', 'Never'],
    ['some', 'At least one is true'],
    ['all', 'All are true'],
    ['none', 'None are true'],
];

export class CheckerList extends ListExpression {
    static KEY = 'checker-list'

    static getStub (...args) { return { ...ListExpression.getStub.apply(this, args), $trueIf: 'never' }; }

    static renderActions = data =>
        <div>
            <span style={{marginLeft: '.2rem', fontWeight: 'normal'}}>is true when:</span>
            <Select
                value={data.$trueIf}
                options={checkerListSelectOptions}
                onChange={event => data.change('$trueIf', event.target.value)}
            />
        </div>
}
