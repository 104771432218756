import {  Root, Header, Name, ContentContainer, Content  } from 'file:///root/project/node_modules/.cache/react-style-loader/components_TabContent-sRtA.styl';
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withState } from 'recompose';
import _ from 'lodash';

export interface TabContentProps {
    defaultSelected?: string[];
    onChange: (tab: string[]) => void;
    setTab?: (tab: string[]) => void;
    tab: string[];
    children: Array<{
        id: string;
        name: string;
        render: () => React.ReactNode;
    }>;
}

// @ts-ignore
@withState('tab', 'setTab', ({ defaultSelected }) => defaultSelected != null ? defaultSelected : [])
class TabContent extends PureComponent<TabContentProps> {

    static propTypes = {
        defaultSelected: PropTypes.arrayOf(PropTypes.string),
        tab: PropTypes.arrayOf(PropTypes.string).isRequired,
        setTab: PropTypes.func.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.node.isRequired,
            render: PropTypes.func.isRequired,
        })).isRequired,
        onChange: PropTypes.func,
    }

   UNSAFE_componentWillReceiveProps (nextProps: TabContentProps) {
        if (!_.isEqual(nextProps.defaultSelected, this.props.defaultSelected)) {
            nextProps.setTab!(nextProps.defaultSelected!);
        }
    }

    setTab (id: string, append: boolean) {
        const {onChange, setTab, tab} = this.props;
        const newValue: string[] = append
            ? tab.includes(id)
                ? _.without(tab, id)
                : _.uniq(tab.concat(id))
            : [id] ;
        setTab!(newValue);
        if (onChange) onChange(newValue);
    }

    isSelected (id: string) { return this.props.tab.includes(id); }

    render () {
        const { children, tab, setTab, defaultSelected, onChange, ...rest } = this.props;

        const availableTabs = tab.filter(id => _.find(children, { id }));

        return (
            <Root {...rest}>
                <Header>
                    {children.map(({ id, name }) =>
                        <Name
                            key={id}
                            onClick={(event: any) => this.setTab(id, event.metaKey || event.shiftKey)}
                            data-selected={this.isSelected(id)}
                        >{name}</Name>
                    )}
                </Header>

                {tab == null ? null :
                    <ContentContainer data-scrollbar>
                        {availableTabs.map(id =>
                            <Content key={id}>
                                {availableTabs.length < 2 ? null :
                                    <h3>{_.find(children, { id })!.name}</h3>
                                }
                                {_.find(children, { id })!.render()}
                            </Content>
                        )}
                    </ContentContainer>
                }
            </Root>
        );
    }
}

export default TabContent;

