import {Button, ButtonProps} from '@blueprintjs/core';
import {SearchSelect, SearchSelectProps, SelectOption} from '@pinto/ui';
import _ from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';

export interface CreateAutocompleteProps<Item> {
    getIdFromItem?: (item: Item | null | undefined) => string;
    fetchItems: (text: string) => Promise<Item[]>;
    convertItemToOption: (item: Item) => SelectOption;
    createDefaultButtonProps?: (item: Item | null | undefined) => Partial<ButtonProps>;
}

export interface AutocompleteProps<Item> extends Partial<SearchSelectProps> {
    value: Item | null | undefined;
    onChange: (item: Item) => void;
    buttonProps?: Partial<ButtonProps> | ((item: Item | null | undefined) => Partial<ButtonProps>);
}

function createAutocomplete<Item, >({
    getIdFromItem = item => (item as any)?._id,
    fetchItems,
    convertItemToOption,
    createDefaultButtonProps,
}: CreateAutocompleteProps<Item>) {
    const Autocomplete: React.FC<AutocompleteProps<Item>> = React.memo((
        { buttonProps, value, onChange, ...props }
    ) => {
        const [loading, setLoading] = useState(false);
        const [options, setOptions] = useState<Array<SelectOption & { item: Item }>>([]);
        const requestRef = useRef<number>();

        const handleSearch = useCallback(
            _.debounce(async (text: string) => {
                if (text.length <= 2) text = '';

                setLoading(true);

                const requestId = requestRef.current = (requestRef.current || 0) + 1;
                const data = await fetchItems(text);

                if (requestId !== requestRef.current) return; // old request

                setLoading(false);

                setOptions(
                    data.map(item => ({
                        ...convertItemToOption(item),
                        item,
                    }))
                );
            }, 200)
        , []);

        const handleQueryChange = useCallback((text: string) => {
            handleSearch(text);
        }, []);

        useEffect(() => {
            if (!value) handleQueryChange('');
        }, []);

        useEffect(() => {
            setOptions(value ? [{ ...convertItemToOption(value), item: value }] : []);
            handleSearch.cancel();
            setLoading(false);
        }, [value]);

        return <SearchSelect
            fill={false}
            {...props}
            value={getIdFromItem(value)}
            options={options}
            onChange={(_id, { item }) => onChange(item)}
            onQueryChange={handleQueryChange}
            disableFilter
            popoverProps={{
                position: 'bottom-left',
            }}
            inputProps={{
                autoFocus: true,
            }}
            menuProps={{
                large: true,
            }}
            renderTarget={() => {
                const option = options.find(opt => opt.key === getIdFromItem(value));
                const finalButtonProps = {
                    ...createDefaultButtonProps?.(option?.item),
                    ...(typeof buttonProps === 'function' ? buttonProps(option?.item) : buttonProps),
                };

                return option
                    ? <Button
                        loading={loading}
                        text={option?.label ?? 'No Label'}
                        intent='primary'
                        {...finalButtonProps}
                    />
                    : <Button
                        loading={loading}
                        {...finalButtonProps}
                    />
            }}
        />
    });

    return Object.assign(Autocomplete, {
        fetchItems,
    });
}



export default createAutocomplete;
