const _ = require('lodash');

export const getRandomHash = (size=8) =>
    Math.floor(Math.abs(10000 + Math.random() * 1000 * 1000 * 1000)).toString(32).slice(-1 * size);

const ESCAPE_REG_REG = /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g;
export const escapeRegExp = str => str.replace(ESCAPE_REG_REG, '\\$&');

export const getExpression = key => {
    switch (key) {
        case 'fn':              return require('../expressions/FnExpression').default;
        case 'and':             return require('../expressions/ListExpression').AndExpression;
        case 'or':              return require('../expressions/ListExpression').OrExpression;
        case 'if':              return require('../expressions/IfExpression').default;
        case 'for':             return require('../expressions/ForExpression').default;
        case 'ref':             return require('../expressions/RefExpression').default;
        case 'call':            return require('../expressions/CallExpression').default;
        case 'checker-message': return require('../expressions/CheckerExpression').CheckerMessage;
        case 'checker-list':    return require('../expressions/CheckerExpression').CheckerList;
        case 'checker-table':   return require('../expressions/CheckerExpression').CheckerTable;
        case 'checker-var':     return require('../expressions/CheckerExpression').CheckerVar;
    }
    return null;
};

export const getRandomName = (() => {
    const nameWords = [
        [
            'angry',
            'black',
            'blue',
            'clever',
            'cyan',
            'flamboyant',
            'fluffy',
            'green',
            'happy',
            'magenta',
            'mean',
            'orange',
            'purple',
            'red',
            'rotund',
            'sad',
            'senior',
            'serious',
            'white',
            'yellow',
        ],
        [
            'allergy',
            'aubergine',
            'avocado',
            'banana',
            'blueberry',
            'blues',
            'canada',
            'cantaloupe',
            'dudley',
            'granola',
            'mango',
            'panda',
            'papaya',
            'pizza',
        ],
    ];

    return () => nameWords.map(arr => _.sample(arr)).join('_') + '_' + Math.floor(Math.random() * 100)
})();

export const parseQuery = () =>
    window.location.search.slice(1)
        .split('&')
        .filter(str => str.trim().length)
        .map(str => str.trim().split('='))
        .reduce((obj, arr) => {
            obj[arr[0]] = decodeURIComponent(arr[1]);
            return obj;
        }, {});

export const deepSelect = (obj, props) => {
    const result = {};
    for (let key of props) {
        result[key] = _.get(obj, key);
    }
    return result;
}

export const setIn = (root, path, value) => {
    if (!path) return root;

    const arr = _.toPath(path);
    const check = obj => obj != null && typeof obj === 'object';
    const clone = obj => !check(obj) ? obj : Array.isArray(obj) ? Array.from(obj) : {...obj};

    if (!check(root)) return root;
    const result = clone(root);
    let parent = result;
    for (let index = 0; index < arr.length - 1; ++index) {
        if (!check(parent)) return root;
        const key = arr[index];
        parent[key] = clone(parent[key]);
        parent = parent[key];
    }
    if (!check(parent)) return root;
    parent[arr[arr.length - 1]] = value;
    return result;
}

export const prettyProduct = obj => {
    const result = {...obj};

    if (result.placeList) {
        result.placeList = result.placeList.map(obj =>
            typeof obj === 'string' ? obj : `${obj.type}: ${obj.description}`
        );
    }

    if (result.topology) {
        result.topology = result.topology.map(({category, subCategory}) =>
            !subCategory ? category : `${category} - ${subCategory}`
        ).sort();
    }

    for (let key of ['badgeList', 'dietList', 'certificationList', 'fdaBadgeList']) {
        if (!result[key]) continue;
        result[key] = result[key].map(obj => obj.name);
    }

    if (result.computeOperations) {
        result.computeOperations = result.computeOperations.map(({type, prop, value}) => [
            type,
            prop,
            typeof value === 'object' ? value.name : value,
        ])
    }

    return result;
}

export const errorHandler = (message, file, row, col, ex) => {
    const $ = sel => document.querySelector(sel);

    let container = $('#error');
    if (!container) {
        container = document.createElement('div');
        container.setAttribute('id', 'error');
        document.body.insertBefore(container, document.body.firstChild);
    }
    if (!message) {
        $('#error').remove();
        return;
    }
    let errorStr = null;
    const isObject = message && typeof message === 'object';
    if (isObject && message.debug) errorStr = message.debug.map(obj => typeof obj === 'string' ? obj : obj.stack).join('\n\n');
    else if (isObject && message.errors) errorStr = message.errors[0];
    else if (ex) errorStr = ex.stack || ex.message || ex.toString();
    else if (message.errors) errorStr = message.errors.join('\n');
    else if (message.stack) errorStr = message.stack;
    else errorStr = typeof message === 'object' ? JSON.stringify(message, null, 4) : message;

    const stack = (ex && ex.stack) || (message && message.stack);
    if (stack) console.warn(stack);

    $('#error').innerHTML = errorStr;
    $('#error').style.display = 'block';
    $('#error').scrollIntoViewIfNeeded();
}
