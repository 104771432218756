
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const Exclamation = ({message}) =>
    <span className='exclamation'>
        <span>❗</span>
        <span className='exclamation-message'>{message}</span>
    </span>

export default Exclamation;
