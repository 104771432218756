type AnyObject = { [key: string]: any };

export type LocalStorageOptions<T extends AnyObject> = {
    namespace: string;
    getDefaults?: () => Partial<T>;
    store?: AnyObject;
};

/**
 * LocalStorage interface that has 2 main features:
 * - it automatically stores JSON and returns parsed objects
 * - it is namespaced by default, i.e. all objects are serialized and stored in a single key in LocalStorage so it avoids clashes with other localStorage implementations
 */
export class LocalStorage<T extends AnyObject = AnyObject> {
    options: Required<LocalStorageOptions<T>>;

    constructor({ namespace, getDefaults, store }: LocalStorageOptions<T>) {
        this.options = {
            namespace,
            getDefaults: getDefaults || (() => ({})),
            store: store || global.localStorage,
        };
    }

    /**
     * Returns all keys stores in the store
     */
    getAll(): Partial<T> {
        try {
            return {
                ...this.options.getDefaults(),
                ...JSON.parse(this.options.store[this.options.namespace]),
            };
        } catch (ex) {
            return this.options.getDefaults();
        }
    }

    /**
     * Returns the value of a single or a default if not found
     */
    get<K extends keyof T, D extends T[K]>(key: K, defaultValue?: D): T[K] | undefined {
        const obj = this.getAll();
        return key in obj ? obj[key] : defaultValue;
    }

    /**
     * Resets all the given values in the store to the given object
     */
    setAll(obj: T) {
        this.options.store[this.options.namespace] = JSON.stringify({ ...this.getAll(), ...obj });
    }

    /**
     * Save any number of keys in the store
     */
    set(diff: Partial<T>) {
        this.options.store[this.options.namespace] = JSON.stringify({
            ...this.getAll(),
            ...diff,
        });
    }

    delete(key: keyof T) {
        this.set({ [key]: undefined } as any);
    }

    /**
     * Deletes all the data in the store
     */
    reset() {
        delete this.options.store[this.options.namespace];
    }
}

export default LocalStorage;
