import _ from 'lodash';
import React from 'react';
import { HTMLDivProps } from 'src/typeDefs';
import { markdownToReact } from '..';

export class Markdown extends React.PureComponent<{ text: string | null | undefined; } & HTMLDivProps> {
    render() {
        const { text, ...props } = this.props;
        return markdownToReact(text, props);
    }
}
