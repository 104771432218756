import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class TextInput extends PureComponent {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onInput: PropTypes.func,
        maxWidth: PropTypes.number.isRequired,
        minWidth: PropTypes.number.isRequired,
        canBeTextarea: PropTypes.bool,
    }

    static defaultProps = {
        maxWidth: 200,
        minWidth: 10,
    }

    state = {
        isTextArea: false,
    }

    componentDidMount () {
        setTimeout(() => { this.resize(); }, 100);
    }

    componentDidUpdate () {
        setTimeout(() => { this.resize(); }, 10);
    }

    get isTextArea() {
        const { canBeTextarea, defaultValue } = this.props;
        const { isTextArea } = this.state;

        return defaultValue && String(defaultValue).includes('\n')
            ? true
            : canBeTextarea && isTextArea;
    }

    resize () {
        const { minWidth, maxWidth } = this.props;

        if (!this._ref) return;

        if (this.isTextArea) {
            this._ref.style.height = Math.min(200, this._ref.scrollHeight) + 'px';
            return
        }

        if (maxWidth) {
            this._ref.style.width = '';
            this._ref.style.width = _.clamp(this._ref.scrollWidth + 10, minWidth, maxWidth) + 'px';
        }
    }

    handleRef = elem => this._ref = elem

    handleInput = event => {
        const {onInput} = this.props;
        this.resize();
        if (onInput) onInput(event.target.value, event);
    }

    handleChange = event => {
        const {onChange, defaultValue} = this.props;
        const {value} = event.target;
        if (!value && defaultValue == null || value === defaultValue) return;
        onChange(value, event);
    }

    render () {
        const {onChange, onInput, defaultValue, minWidth, maxWidth, canBeTextarea, className, ...rest} = this.props;

        const props = {
            key: defaultValue,
            defaultValue: defaultValue,
            ...rest,
            ref: this.handleRef,
            onBlur: this.handleChange,
            onInput: this.handleInput,
            className: 'input',
        };

        let input = <input {...props} type='text' />;

        if (this.isTextArea) {
            input = <textarea
                {...props}
                style={{ ...props.style, minWidth: '20rem', fontFamily: 'courier, tahoma, arial', fontSize: '.75rem' }}
                rows={3}
                onKeyDown={e => {
                    if (e.key === 'Tab') {
                        insertTextAtCursor(this._ref, '    ');
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
            />;
        }

        return <React.Fragment>
            {input}
            {(this.isTextArea || canBeTextarea) &&
                <label style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <input
                        type='checkbox'
                        checked={this.isTextArea}
                        title='Convert to a text area'
                        onChange={e => {
                            this.setState({ isTextArea: e.target.checked });
                            setTimeout(() => this._ref && this._ref.focus(), 20);
                        }}
                    />
                    <span>large</span>
                </label>
            }
        </React.Fragment>;
    }

}

function insertTextAtCursor(el, text) {
    let val = el.value, endIndex, range, doc = el.ownerDocument;
    if (typeof el.selectionStart == 'number' && typeof el.selectionEnd == 'number') {
        endIndex = el.selectionEnd;
        el.value = val.slice(0, endIndex) + text + val.slice(endIndex);
        el.selectionStart = el.selectionEnd = endIndex + text.length;
    } else if (doc.selection != 'undefined' && doc.selection.createRange) {
        el.focus();
        range = doc.selection.createRange();
        range.collapse(false);
        range.text = text;
        range.select();
    }
}
