import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';

import SelectType from '../components/SelectType';
import ExpressionSelector from './ExpressionSelector/ExpressionSelector';
import getArgumentType from 'src/utils/getArgumentType';

@connect((state, {data}) => {
    return {
        functionPath: data.state.hidden ? null :
            data.log.hasLog && data.state.nestedPath ? data.state.nestedPath :
            'functions.' + _.findIndex(state.formula.data.functions, {id: data.$value}) + '.structure',
    };
})
class CallExpression extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({}).isRequired,
        functionPath: PropTypes.string,
    }

    static getStub ([$value=null]=[]) { return {$op: 'call', $value, $args: []}; };

    handleFunctionChange = event => {
        const {data: {change}} = this.props;
        change({
            $value: event.target.value,
            $args: [],
        });
    }

    renderArg = ({name, type}, index) => {
        const {data: {$args, rootRef, change}} = this.props;
        const props = {
            key: name + type + index,
            value: $args[index],
            onChange: (value) => {
                const old = $args[index];
                if (old && old.type === value.type && old.value === value.value) return;
                return change('$args.' + index, value);
            },
        };

        const item = getArgumentType(type);

        const getOptions = () => {
            const {key, value, type} = item;
            const extra = _.get(rootRef, `varTypes[${key}]`, []);
            return [
                ...extra.map(name => [name, 'args.' + name, 'Variables']),
                ...(value || []),
            ].map(([value, label, group]) => ({
                value,
                label,
                group,
            }));
        }

        switch (item.type) {
            // new
            case 'enum': return <SelectType {...props} options={getOptions()} />;
            case 'number':
                return (
                    <SelectType
                        {...props}
                        options={getOptions()}
                        rawOption={true}
                        renderRawOption={renderNumberInput}
                    />
                );
        }

        throw new Error(`Invalid arg type: '${item.type}'`);
    }

    render () {
        const {functionPath, data: {state, $value, depth}} = this.props;

        if (!state.hidden && !functionPath) throw new Error(`Invalid function: ${$value}`);

        return <div className='expression-call'>
            {state.hidden ? null :
                <ExpressionSelector depth={depth + 1} path={functionPath} />
            }

            {
                /*
                !$value ? null : def.args.map((item, index) =>
                <span key={index}>
                    {this.renderArg(item, index)}
                </span>
                )
                */
            }
        </div>
    }
}

const renderNumberInput = ({onChange, defaultValue}) =>
    <input
        key={defaultValue}
        type='number'
        onBlur={event => onChange(parseFloat(event.target.value))}
        defaultValue={defaultValue}
        style={{maxWidth: '3rem', marginLeft: '.5rem'}}
    />;

export default CallExpression;
