
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import './Options.scss';

import SortableList from '../../components/SortableList/SortableList';
import TextField from '../../components/TextField/TextField';
import TextArea from '../../components/TextArea/TextArea';

import {AddNewItem} from '../../ui';

export default class Options extends PureComponent {

    static propTypes = {
        setOptions: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
        })).isRequired,
    }

    state = {
        error: null,
    }

    setOptions (obj) {
        this.setState({error: validate(obj)});
        this.props.setOptions(obj);
    }

    handleChange = (index, val) => {
        const {options} = this.props;
        const newOptions = Array.from(options);
        newOptions[index] = val;
        this.setOptions(_.filter(newOptions));
    }

    handleSwap = (a, b) => {
        const {options} = this.props;
        const newOptions = Array.from(options);
        const old = newOptions[a];
        newOptions[a] = newOptionsp[b];
        newOptions[b] = old;
        this.setOptions(newOptions);
    }

    handleAdd = () => {
        const {options} = this.props;
        this.setOptions([...options, {type: 'boolean', default: true}]);
    }

    render () {
        const {setOptions, options, className, ...rest} = this.props;
        const {error} = this.state;

        return (
            <div {...rest} className={cx('Options-root', className)}>
                {!error ? null :
                    <div className={'Options-error'}>{error}</div>
                }

                <SortableList onSwap={this.handleSwap}>
                    {options.map((data, index) =>
                        <Item
                            key={index}
                            data={data}
                            onChange={val => this.handleChange(index, val)}
                            onSectionDescriptionChange={(section, value) =>
                                this.setOptions(_.filter(options).map(obj => ({
                                    ...obj,
                                    sectionDescription: obj.section === section ? value : obj.sectionDescription,
                                })))
                            }
                        />
                    )}
                </SortableList>

                <AddNewItem onClick={this.handleAdd}>+ add a new option </AddNewItem>
            </div>
        );
    }
}

class Item extends PureComponent {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onSectionDescriptionChange: PropTypes.func.isRequired,
        data: PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.oneOf(['int', 'boolean']),
            default: PropTypes.any,
        }).isRequired,
    }

    onChange (key, value) {
        const {data, onChange} = this.props;
        const update = {...data, [key]: value};
        if (key === 'type' && value !== data.type) update.default = value === 'boolean' ? true : 0;
        onChange(update);
    }

    handleRemove = () => {
        const {onChange, data: {key}} = this.props;
        if (!confirm(`You sure you want to remove: "${key || 'no name'}"?`)) return;
        onChange(null);
    }

    render () {
        const {data, onChange, className, onSectionDescriptionChange, ...rest} = this.props;

        const getProps = (key, defaultValue, parseValue=(x=>x)) => ({
            key: key + '-' + defaultValue,
            defaultValue,
            placeholder: key,
            onChange: value => this.onChange(key, parseValue(value)),
            className: cx('Options-field', 'Options-key-' + key),
        });

        const renderField = (Klass, key, data, defaultValue) =>
            <Klass
                key={key + '-' + defaultValue}
                data={data}
                defaultValue={defaultValue}
                onChange={val => this.onChange(key, val)}
                className={'Options-field'}
            />;

        let KlassValue, extraFields, parser;
        if (data.type === 'boolean') KlassValue = BooleanField;
        else if (data.type === 'int') {
            KlassValue = IntField;
            extraFields = [
                <Label small key={1} name='min:'>
                    <TextField type='number' {...getProps('min', data.min, parseNumberValue)} />
                </Label>,
                <Label small key={2} name='max:'>
                    <TextField type='number' {...getProps('max', data.max, parseNumberValue)} />
                </Label>,
                <Label small key={3} name='step:'>
                    <TextField type='number' {...getProps('step', data.step, parseNumberValue)} />
                </Label>,
                <Label small key={4} name='label:'>
                    <TextField
                        {...getProps('label', data.label)}
                        placeholder='e.g: {{value}} netCarbs'
                    />
                </Label>,
            ];
        }

        return (
            <div {...rest} className={cx('Options-item', className)}>
                <Label row name={<b>key</b>} style={{ background: '#eef' }}>
                    <TextField {...getProps('key', data.key)} style={{flex: '1 1 0'}}/>
                    <div className={'Options-remove'} onClick={this.handleRemove}>delete</div>
                </Label>

                <Label name={<b>private</b>} title='Private options will not be exposed to the user'>
                    <select {...getProps('private', data.private, ({ target: { value } }) => value === 'true')}>
                        <option>false</option>
                        <option>true</option>
                    </select>
                </Label>

                <Label name='type'>
                    <select
                        {...getProps('type', data.type, undefined, 'onChange')}
                        onChange={event => this.onChange('type', event.target.value)}
                    >
                        <option>boolean</option>
                        <option>int</option>
                    </select>
                </Label>

                <Label name='section'>
                    <TextField {...getProps('section', data.section)} />
                </Label>

                <Label name='sectionDescription'>
                    <TextField
                        {...getProps('sectionDescription', data.sectionDescription)}
                        onChange={value => onSectionDescriptionChange(data.section, value)}
                    />
                </Label>

                <Label name='name'>
                    <TextField {...getProps('name', data.name)} />
                </Label>

                <Label name='description'>
                    <TextArea {...getProps('description', data.description)} />
                </Label>

                <Label name='default'>
                    {renderField(KlassValue, 'default', data, data.default)}
                </Label>

                {!_.size(extraFields) ? null :
                    <Label name='extra'>{extraFields}</Label>
                }
            </div>
        );
    }
}

const Label = ({ name, children, row, small, ...rest }) =>
    <div {...rest} className={'Options-label'}>
        <div className={'Options-label-name'} style={!small ? null : {flexBasis: '3rem'}}>{name}</div>
        <div className={'Options-label-children'} style={!row ? null : {flexDirection: 'row'}}>{children}</div>
    </div>;

const parseNumberValue = str => Number(str);

const parseBooleanValue = str => str === 'true' ? true : false;

export const BooleanField = ({data, onChange, ...rest}) =>
    <select {...rest} key={rest.defaultValue} onChange={event => onChange(parseBooleanValue(event.target.value))}>
        <option>true</option>
        <option>false</option>
    </select>;

export const IntField = ({data, onChange, ...rest}) =>
    <TextField
        {...rest}
        key={rest.defaultValue}
        type='number'
        title={JSON.stringify(data, null, 4)}
        step={1}
        min={data.min}
        max={data.max}
        onChange={value => onChange(parseNumberValue(value))}
    />;

const extractValue = json => {
    const result = {};
    for (let item of json) {
        result[item.key] = item.default;
    }
    return result;
}

const validate = json => {
    if (!json) throw new Error('Missing json argument');

    const usedKeys = {};

    if (!json || typeof json !== 'object') return 'Invalid data object';
    if (!Array.isArray(json)) return 'Options must be an array';

    for (let index = 0; index < json.length; ++index) {
        const item = json[index];

        if (!item || typeof item !== 'object') return `Invalid item at position ${index}. Expected an object`;

        const {key} = item;

        if (!key) return `Item at position ${index} must has a "key" property`;

        const id = key.toLowerCase();
        if (usedKeys[id]) return `Multiple options with the same key "${key}" are not allowed`;
        usedKeys[id] = true;

        if (!/^[a-z_][a-z0-9_]/i.test(key)) return `Invalid key named "${key}". It can only contain letters numbers and _`;

        if (!item.type) return `Invalid ${key}: missing "type" property`;

        if (!/^(boolean|int)$/.test(item.type)) return `Invalid ${key}: type must be one of [boolean, int]`;

        if (item.default == null) return `Invalid ${key}: it should have a "default" property`;

        if (!item.name || !item.name.trim()) return `Invalid ${key}: it should have a "name"`;

        if (!item.description || !item.description.trim()) return `Invalid ${key}: it should have a "description"`;

        if (item.type === 'int') {
            if (item.min == null) return `${key}.min is not defined`;
            if (item.max == null) return `${key}.max is not defined`;
            if (item.step == null) return `${key}.step is not defined`;
            if (item.label == null || !item.label.trim()) return `${key}.label is not defined`;
        }
    }

    return null;
}
