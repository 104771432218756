import _ from 'lodash';
import external from '../external';

type ArgType = 'NUTRIENT' | 'NUMBER';

const createMap = _.once(() => {
    const base: Record<ArgType, { name: string; type: 'enum' | 'number', value?: any[] }> = {
        NUTRIENT: {
            name: 'Nutrient',
            type: 'enum',
            value: Array.from(external.nutrients).sort().map(value => [
                value,
                value[0].toUpperCase() + value.replace(/[A-Z]/g, str => ' ' + str).slice(1),
            ]),
        },

        NUMBER: {
            name: 'Number',
            type: 'number',
        },
    };

    return _.mapValues(
        base,
        (item: any, key) => {
            item.key = key;
            switch (item.type) {
                case 'enum':
                    console.assert(
                        Array.isArray(item.value) && item.value.length > 0,
                        `[${item.key}] enum type must have a non-empty "value" property`
                    );
                    const map = _.keyBy(item.enum, '0');
                    item.validate = (str: any) =>
                        typeof str !== 'string' ? 'Not a string' :
                            !map[str] ? 'Not a valid value' :
                                null;
                    break;
                case 'number':
                    item.validate = (num: any) => typeof num === 'number' && !Number.isNaN(num);
                    break;
                default:
                    throw new Error(`[${item.key}] Invalid base type: "${item.type}"`);
            }
            return item;
        }
    );
})

export default function getArgumentType(type: ArgType) {
    return createMap()[type];
}
