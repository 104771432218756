import { S1_HTMLTable, S2_div, S3_td, S4_td, S5_span } from 'file:///root/project/node_modules/.cache/react-style-loader/routes_Builder_ProductPreview-lTI.styl';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {prettyProduct, deepSelect} from '../../utils/utils';
import {EditConfigDialog} from './EditConfig';
import { Button, HTMLTable } from '@blueprintjs/core';
import external from 'src/external';
import type { Pv2AttributeStructOutput, Pv2Output } from '@pinto/api-client';

export default class ProductPreview extends PureComponent<{
    product: Pv2Output & {
        __vars: Record<string, any>;
        __exports: Record<string, any>;
        __pv2AddAttributes: Pv2AttributeStructOutput[];
        __pv2AddAttributesError: any;
        __pv2RemoveAttributes: string[];
    };
    usedProps: string[];
    configProps: Record<string, any>;
    className?: string;
}> {

    static propTypes = {
        product: PropTypes.object.isRequired,
        usedProps: PropTypes.arrayOf(PropTypes.string).isRequired,
        configProps: PropTypes.object.isRequired,
    }

    renderValue (name: string, value: any) {
        if (React.isValidElement(value)) return value;

        const mapping = (fn: (...args: any[]) => any) =>
            <div>
                {(value || []).map((item: any, index: number) =>
                    fn(item, { index, key: index, last: index === value.length - 1 }))
                }
            </div>;

        switch (name) {
            case 'pv2RemoveAttributes':
                if (!Array.isArray(value) || value.some(x => typeof x !== 'string')) {
                    return <pre>
                        <div className='error'>Must be an array of strings</div>
                        {JSON.stringify(value)}
                    </pre>;
                }
                break;
            case 'pv2Attributes':
                if (!Array.isArray(value)) {
                    return <pre>
                        <div className='error'>Must be an array</div>
                        {JSON.stringify(value)}
                    </pre>;
                }
                value = value.map(attr => ({ type: attr.type, ..._.omit(attr, '_id') }));
                break;
            case 'taxonomies':
                return <div>
                    {value.map((id: string) =>
                        <div key={id}>{external.taxonomies[id] || 'no name: ' + id}</div>
                    )}
                </div>;
        }

        if (Array.isArray(value) && (!value.length || typeof value[0] != 'object')) {
            return stringify(value.map(x => ' → ' + x));
        }

        if (Array.isArray(value)) {
            return mapping((value, { index, last, ...props }) =>
                <pre {...props}>
                    {value && typeof value === 'object'
                        ? JSON.stringify(value, null, 4)
                            .split('\n')
                            .slice(1, -1)
                            .map(x => x.slice(4))
                            .join('\n')
                        : stringify(value)
                    }
                    {!last && <hr />}
                </pre>
            );
        }

        return stringify(value);
    }

    render () {
        const {
            product: { __vars, __exports, __pv2AddAttributes, __pv2AddAttributesError, __pv2RemoveAttributes, ...product},
            usedProps,
            configProps,
            className,
            ...rest
        } = this.props;

        const data = prettyProduct(
            deepSelect(
                product,
                usedProps.filter(prop => !/^options\.?/.test(prop)).concat('options')
            )
        );

        const prettyName = (name: string, value: any) => {
            if (Array.isArray(value)) name = `${name} (${_.size(value)})`;
            return name;
        }

        const renderRow = (name: string, cls?: string, value: any = data[name]) =>
            <tr key={name} className={cls}>
                <NameTd>{prettyName(name, value)}</NameTd>
                <CodeTd>{this.renderValue(name, value)}</CodeTd>
            </tr>;

        const pv2AttributesError = __pv2AddAttributesError ?
            <><br/><div className='error'>{__pv2AddAttributesError}</div></> : null;

        return (
            <div {...rest} className='builder-preview'>
                <Table bordered condensed striped>
                    <tbody>
                        {__exports && renderRow('Exported Vars', 'var', <ObjectToggle data={__exports} />)}
                        {__pv2RemoveAttributes &&
                            renderRow('Pv2: Remove Attrs', 'var', __pv2RemoveAttributes)
                        }
                        {__pv2AddAttributes &&
                            renderRow('Pv2: Add Attributes', 'var', <><ObjectToggle rawJson data={__pv2AddAttributes} />{pv2AttributesError}</>)
                        }
                        {Object.keys(__vars).map(n => renderRow(n, 'var', __vars[n]))}
                        {Object.keys(data).filter(k => !(k in __vars)).map(n => renderRow(n))}
                    </tbody>
                </Table>

                <EditConfigDialog {...configProps as any} />
            </div>
        );
    }

}

const highlight = (type: string, value: any, content: React.ReactNode = value) =>
    <Datum data-type={type} data-value={value}>{content}</Datum>;

const stringify = (item: any, nestedIndent = 0) => {
    const indent = ' '.repeat(nestedIndent);

    const addIndent = (val: any) => {
        const i = indent ? indent + '→ ' : indent;

        return (
            typeof val === 'string'
                ? i + val
                : <span>{i}{val}</span>
        );
    };

    if (item == null) return highlight('null', 'null');

    const type = typeof item;
    let result: React.ReactNode = JSON.stringify(item, null, 2);

    if (type === 'boolean') return highlight('boolean', result);
    if (type === 'number') return highlight('number', parseFloat(item.toFixed(4)));

    if (type === 'string') result = (result as string).slice(1, -1);
    else if (type === 'object') {
        if (Array.isArray(item) && !item.length) result = '[]';
        else if (!Object.keys(item).length) result = '{}';
        else if (Array.isArray(item)) {
            // result = result.split('\n').slice(1, -1).map(line => line.slice(2)).join('\n');
            result = (
                <div style={{maxHeight: '10rem', overflowY: 'auto'}}>
                    {item.map((value, index) =>
                        <div key={index}>{addIndent(stringify(value, nestedIndent + 4))}</div>
                    )}
                </div>
            );
        } else {
            result = (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    {_.map(item, (value, key) =>
                        <div key={key}>
                            {indent + key + ': '}
                            {addIndent(stringify(value, nestedIndent + 4))}
                        </div>
                    )}
                </div>
            );
        }
    }

    if (typeof result === 'string') result = result.trim();

    return !result ? '[empty]' : result;
};

class ObjectToggle extends PureComponent<{ expanded?: boolean; rawJson?: any; data: any }> {

    static propTypes = {
        data: PropTypes.oneOfType([
            PropTypes.object.isRequired,
            PropTypes.array.isRequired,
        ]),
        expanded: PropTypes.bool,
        rawJson: PropTypes.bool,
    }

    state = {
        expanded: !!this.props.expanded,
        rawJson: !!this.props.rawJson,
    }

    render () {
        const { data, rawJson: _1, expanded: _2, ...rest } = this.props;
        const { expanded, rawJson } = this.state;

        const btn = (message: React.ReactNode, onClick: any) =>
            <Button
                small
                minimal
                onClick={onClick}
                intent='primary'
                icon='eye-off'
            >
                {message}
            </Button>;

        const toggleDisplay = () => this.setState({ expanded: !expanded });
        const toggleFormat = () => this.setState({ rawJson: !rawJson });

        return (
            <div {...rest}>
                {
                    expanded
                        ? <>
                            <div>
                                {btn(<>Hide all <b>{_.size(data)}</b> properties </>, toggleDisplay)}
                                {btn(
                                    rawJson ? '● Show prettified data' : '● Show raw JSON',
                                    toggleFormat
                                )}
                            </div>
                            {rawJson
                                ? <pre>{JSON.stringify(data, null, 4)}</pre>
                                : stringify(data)
                            }
                        </>
                        : btn(<>Show <b>{_.size(data)}</b> properties </>, toggleDisplay)
                }
            </div>
        );
    }

}

const Table = S1_HTMLTable.withComponent(HTMLTable);
export const Info = S2_div.withComponent("div");
const NameTd = S3_td.withComponent('td');
const CodeTd = S4_td.withComponent('td');
const Datum = S5_span.withComponent('span');