
export class IndexDB {
    constructor () {
        this.id = 'Builder';
        this.storeID = 'Store';
        this.initPromise = null;
        this.init();
    }

    init () {
        if (!this.initPromise) {
            this.initPromise = new Promise((resolve, reject) => {
                // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
                const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

                // Open (or create) the database
                const open = indexedDB.open(this.id, 1);

                // Create the schema
                open.onupgradeneeded = () => {
                    this.db = open.result;
                    this.db.createObjectStore(this.storeID, { keyPath: 'id' });
                    // var index = store.createIndex('NameIndex', ['name.last', 'name.first']);
                };

                open.onsuccess = () => {
                    this.db = open.result;
                    resolve(this.db);
                };

            });
        }

        return this.initPromise;
    }

    close () {
        this.db && this.db.close();
        this.db = null;
        this.store = null
        this.tx = null;
        this.initPromise = null;
    }

    getStore = async () => {
        const db = await this.init();
        const tx = db.transaction(this.storeID, 'readwrite');
        const store = tx.objectStore(this.storeID);
        return { store, tx };
    }

    getById = async id => {
        const { store } = await this.getStore();
        return new Promise((resolve, reject) => {
            const getID = store.get(id);
            getID.onsuccess = () => resolve(getID.result);
            getID.onError = error => reject(error);
        });
    }

    put = async data => {
        const { store, tx } = await this.getStore();
        store.put(data);
        return tx.complete;
    }
}

const DB = new IndexDB();
DB.init();

export default DB;
