
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import _ from 'lodash';

import FormulaReducer from './Formula';

export const getStore = initialState =>
    createStore(
        combineReducers({
            formula: FormulaReducer,
        }),
        initialState || {},
        compose(
            applyMiddleware(
                thunkAlternative
            ),
            typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ?
                window.__REDUX_DEVTOOLS_EXTENSION__() :
                x => x
        ),
    );

const thunkAlternative = ({ dispatch, getState }) => next => action => {
    if (typeof action === 'function') return action({ dispatch, getState });
    return next(action);
};

export const store = getStore({
    formula: undefined,
});
