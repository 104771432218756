import {  Root, Content, Description  } from 'file:///root/project/node_modules/.cache/react-style-loader/components_ResourceDrawer_ResourcePreview-zFrM.styl';
import { AnchorButton, Button, Classes, H4, H5, HTMLTable } from '@blueprintjs/core';
import { Markdown } from '@pinto/ui';
import _ from 'lodash';
import external from 'src/external';
import ResourceIcon from '../ResourceIcon';
import type { ParsedResource } from 'src/hooks/useExternalData';

export const ResourcePreview: React.FC<{
    resource: ParsedResource;
    onClose: () => void;
}> = ({ resource, onClose }) =>
    <Root>
        <div className={Classes.DRAWER_HEADER}>
            <ResourceIcon resource={resource} noBlankIcon />
            <H4>{formatHeader(resource)}</H4>
            <Button
                minimal
                icon='cross'
                onClick={onClose}
            />
        </div>
        {resource.description &&
            <Description>
                <Markdown text={resource.description} />
            </Description>
        }
        <Content data-type={resource.type}>
            {formatItem(resource)}
            {!!resource.totalChildren &&
                resource.children
                    .map(id => external.refMap[id])
                    .filter(item => item)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(child =>
                        <>
                            <H5>{formatHeader(child)}</H5>
                            {formatItem(child)}
                        </>
                    )
            }
        </Content>
    </Root>;

const formatHeader = (resource: ParsedResource) =>
    <div className='row-center'>
        <AnchorButton
            target='_blank'
            href={`https://admin.pinto.co/builder/resources?text=${resource._id}`}
            icon='link'
            minimal
            small
            className='mr025'
        />
        {resource.name} — {resource.totalItems} items{!!resource.totalChildren && `, ${resource.totalChildren} children`}
    </div>;

const formatItem = (item: ParsedResource) => {
    const value: Array<string | Record<string, any>> = item.value;

    if (!item.type || item.type === 'list') {
        return (value as string[]).sort().map((str, index) => <div key={index} data-striped>{str}</div>);
    }

    if (item.type === 'table') {
        const props: any[] = item.config && item.config.props ||
            _.uniq(_.flatMap(value, _.keys)).map(key => ({ key }));

        return <HTMLTable condensed striped interactive>
            <thead>
                <tr>{props.map(({ key }) => <td key={key}>{key}</td>)}</tr>
            </thead>
            <tbody>
                {(value as Record<string, any>[]).map((row, index) =>
                    <tr key={index}>
                        {props.map(({ key }) => <td key={key}>{row[key]}</td>)}
                    </tr>
                )}
            </tbody>
        </HTMLTable>
    }
    return 'type not handled: ' + item.type;
}

