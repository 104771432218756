import { LocalStorage } from '@pinto/ui';
import type { RawFormulaData } from 'src/typeDefs';

export const LS = new LocalStorage<{
    slug: string | null;
    theme: 'light' | 'dark';
    structure: RawFormulaData | null;
    batchFilter: Record<string, any>;
}>({
    namespace: 'builder',
    getDefaults: () => ({
        theme: 'light',
        slug: null,
        structure: null,
        batchFilter: {
            isPublished: true,
            createdDate: { $gte: '2019-01-01' },
        },
    })
});

// @ts-ignore
window.__LS = LS;
