
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './TextFields.scss';

export default class TextField extends PureComponent {

    static propTypes = {
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onChange: PropTypes.func.isRequired,
        onInput: PropTypes.func,
        validation: PropTypes.shape({
            required: PropTypes.bool,
            pattern: PropTypes.instanceOf(RegExp),
            patternMessage: PropTypes.string,
            // A function that takes the value and returns an error message (or null)
            fn: PropTypes.func,
        }),
        inputClassName: PropTypes.string,
        inputStyle: PropTypes.object,
    }

    state = {
        error: null,
    }

    handleChange = event => {
        const {target: {value}} = event;
        const {defaultValue, onChange, validation} = this.props;

        if (value === defaultValue || (value === '' && defaultValue == null)) return;

        let error = null;
        if (validation) {
            const {required, pattern, patternMessage, fn} = validation;
            error = required && value.trim() === '' ? 'This field is required' :
                pattern && !pattern.test(value) ?
                    patternMessage || `Does not match the pattern: ${pattern.source}` :
                fn && fn(value) ? fn(value) :
                null;
        }

        this.setState({error});

        if (error) return;

        onChange(value, event);
    }

    handleInput = event => {
        const {onInput} = this.props;

        if (!onInput) return;

        onInput(event.target.value, event);
    }

    render () {
        const {defaultValue, onChange, validation, inputClassName, inputStyle, style, className, ...rest} = this.props;
        const {error} = this.state;

        return (
            <span style={style} className={cx('TextField-root', className)}>
                <input
                    type='text'
                    {...rest}
                    key={defaultValue}
                    defaultValue={defaultValue}
                    onBlur={this.handleChange}
                    onInput={this.handleInput}
                    className={cx('TextField-field', inputClassName)}
                    style={inputStyle}
                />

                {!error ? null :
                    <div className={'TextField-error'}>
                        <div>{error}</div>
                    </div>
                }
            </span>
        );
    }

}
