import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Menu, Popover, Button, Intent } from '@blueprintjs/core';
import { FieldInput, FieldMeta, FormFieldInputType, FormFieldMetaType } from '@pinto/react-form';

import SelectOptionType, { SelectOptionKeyType } from 'src/propTypes/SelectOptionType';
import { SelectMenuOption, SelectOptionKey } from 'src/typeDefs';

export interface SelectMenuProps {
    value?: SelectOptionKey;
    onChange: (key: SelectOptionKey, option?: SelectMenuOption) => any;
    options: SelectMenuOption[];
    /**
     * The option handle to show for when no option is selected (or for when `value` is not part of `options`)
     */
    emptyOption?: SelectMenuOption;
    /**
     * Props to pass to the Blueprint Button handle
     */
    buttonProps?: { [key: string]: any };
    /**
     * Function to get the label of the handle based on the selected option
     */
    getButtonText?: (option: SelectMenuOption) => React.ReactNode;
    disabled?: boolean;
    /**
     * Blueprint intent of the button handle for when an option is selected
     * Defaults to `primary`
     */
    selectedIntent?: Intent;
}

export interface SelectMenuFieldProps extends Omit<SelectMenuProps, 'value'> {
    input: FieldInput;
    meta: FieldMeta;
    [key: string]: any;
}

export class SelectMenu extends React.PureComponent<SelectMenuProps & { [key: string]: any }> {
    static propTypes = {
        value: SelectOptionKeyType,
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(SelectOptionType.isRequired).isRequired,
        emptyOption: SelectOptionType,
        buttonProps: PropTypes.object,
        getButtonText: PropTypes.func.isRequired,
        selectedIntent: PropTypes.string,
    };

    static defaultProps: Partial<SelectMenuProps> = {
        emptyOption: {
            key: null,
            label: '[select]',
        },
        getButtonText: option => option.label || String(option.key),
        selectedIntent: 'primary',
    };

    render() {
        const {
            value,
            onChange,
            options,
            emptyOption,
            buttonProps,
            getButtonText,
            disabled,
            selectedIntent,
            ...rest
        } = this.props;
        const finalValue = value == null || value === '' ? null : value;
        const finalOptions = [...options];

        if (emptyOption && !_.find(finalOptions, { key: emptyOption.key })) {
            finalOptions.unshift(emptyOption);
        }

        let match = _.find(finalOptions, { key: finalValue });
        let intent;

        if (match) {
            if (!emptyOption || match.key !== emptyOption.key) {
                intent = selectedIntent || ('primary' as const);
            }
        } else {
            intent = 'danger' as const;
            match = {
                key: finalValue,
                label: `Invalid option: ${finalValue}`,
            };
        }

        return (
            <Popover
                position='bottom'
                {...rest}
                disabled={disabled}
                content={
                    <Menu style={{ maxHeight: 410, overflowY: 'auto' }}>
                        {finalOptions.map(item => (
                            <Menu.Item
                                {...item.props}
                                key={item.key}
                                text={item.label}
                                icon={item.icon}
                                disabled={item.key === value}
                                onClick={() => onChange(item.key, item)}
                            />
                        ))}
                    </Menu>
                }
                target={
                    <Button
                        intent={intent}
                        text={getButtonText!(match)}
                        rightIcon='chevron-down'
                        icon={match.icon}
                        disabled={disabled}
                        {...buttonProps}
                    />
                }
            />
        );
    }
}

export default SelectMenu;

export class SelectMenuField extends React.PureComponent<SelectMenuFieldProps> {
    static propTypes = {
        input: FormFieldInputType.isRequired,
        meta: FormFieldMetaType.isRequired,
    };

    handleChange = (...args: Parameters<SelectMenuProps['onChange']>) => {
        const { input, onChange } = this.props;
        input.onChange(args[0]);
        if (onChange) onChange(...args);
    };

    render() {
        const { input, meta, ...rest } = this.props;

        return <SelectMenu {...rest} value={input.value} onChange={this.handleChange} />;
    }
}
