import styled, {css} from 'styled-components';
import {mapProps} from '../../ui';

export const IngredientsRoot = styled.div`
    padding: 1rem;
`;

export const Result = styled.div`
    display: flex;
    align-items: flex-start;
    padding: .25rem;
    line-height: 1rem;

    &:nth-child(2n) {
        background: #f6f6f6;
    }
`;

export const ExpandButton = styled.div`
    flex: 0 0 auto;
    color: crimson;
    width: 1.5rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    cursor: pointer;
    user-select: none;
`;

export const Filter = styled.div`
    color: royalblue;
    margin-left: .5rem;
    cursor: pointer;

    ${mapProps({
        selected: css`
            color: black;
            font-weight: bold;
            cursor: default;
            pointer-events: none;
        `,
    })}
`;

export const FieldWrapper = styled.div`
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
`;

export const Link = styled.a`
    color: royalblue;
    font-size: 1rem;
    cursor: pointer;

    & + & { margin-left: 1rem; }

    &:hover { color: crimson; }
`;

export const Count = styled.a`
    color: gray;
    font-size: .75rem;
`;
