import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FnExpression from'./FnExpression';
import ExpressionSelector, { opIsBlock } from './ExpressionSelector/ExpressionSelector';
import TextInput from '../components/TextInput';
import Select from '../components/Select';
import { Label } from './ExpressionSelector/styles';

export default class ForExpression extends PureComponent {

    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    static getStub = () => ({
        $op: 'for',
        $on: null,
        $argName: 'item',
        $value: FnExpression.getStub(),
    });

    static renderActions = ({ $on, $argName, change, availableVariables }) =>
        <div style={{
            background: '#FFCFE0',
            marginLeft: '.5rem',
            paddingLeft: '.25rem',
            color: 'black',
            fontWeight: 'normal',
        }}>
            {/*<b>Variable:</b>*/}
            <Select
                value={$on}
                renderLabel={() =>
                    <div style={{ minWidth: '3rem', textAlign: 'center', padding: '0 .25rem' }}>
                        {$on || '[select var]'}
                    </div>
                }
                onChange={({ target: { value } }) => change('$on', value)}
                options={_.sortBy(Object.entries(availableVariables).map(([ key ]) => [key, key]), 1)}
            />
            <b>{' as '}</b>

            <TextInput
                onChange={value => change('$argName', value)}
                defaultValue={$argName}
                placeholder={$argName}
            />
        </div>

    render () {
        const { data: { depth, getChildPath, $value } } = this.props;

        return (
            <div className='expression-for'>
                <ExpressionSelector
                    noRemove
                    dontIndent
                    path={getChildPath('$value')}
                    depth={depth + 1}
                />
                {$value && opIsBlock($value.$op, $value.$value)
                    // disabled for now
                    ? null &&  <Label
                        data-type='for'
                        style={{
                            display: 'inline-block',
                            borderRadius: 0,
                            marginLeft: '-.5rem',
                        }}
                    >end for each</Label>
                    : null
                }
            </div>
        );
    }

}
