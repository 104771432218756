import { Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import type { FormulaResourceOutput } from '@pinto/api-client';
import type { HTMLDivProps } from '@pinto/ui';

export interface ResourceIconProps extends Omit<HTMLDivProps, 'resource'> {
    resource: Pick<FormulaResourceOutput, 'name' | 'type'>;
    noBlankIcon?: boolean;
}

const ResourceIcon: React.FC<ResourceIconProps> = ({ resource, noBlankIcon, ...rest }) => {
    const type = resource?.name.includes('falsePositive') ? 'falsePositive' :
        resource.type === 'table' ? 'table' :
        'blank';

    if (type === 'blank' && noBlankIcon) return null;

    return <span {...rest} style={{ flex: '0 0 auto' }}>{ICONS[type]}</span>;
}

const ICONS = {
    falsePositive: <Tooltip2 content='This is a falsePositive resource, it is used to exclude matches'>
        <Icon icon='exclude-row' />
    </Tooltip2>,
    table: <Tooltip2 content='This is a table resource'>
        <Icon icon='grid-view' />
    </Tooltip2>,
    blank: <Icon icon='blank' />,

} as const;

export default ResourceIcon;
