import {api, Pv2Output} from '@pinto/api-client';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import type { ResourceDrawerProps } from 'src/components/ResourceDrawer';
import { LS } from './LocalStorage';

export interface BuilderContextType {
    /** fully populated product ready to be used in the backend */
    pv2?: Pv2Output | null;
    setPartialProduct: (partialProduct: Pv2Output) => void;
    setPv2: (pv2: Pv2Output) => void;
    onProductChange: (callback: () => void) => void;
    loadingState: string | null;
    setLoadingState: (loadingState: string | null) => void;
    theme: 'light' | 'dark';
    toggleTheme: () => void;
    resourceDrawer: null | ResourceDrawerProps;
    setResourceDrawer: (props: null | Omit<ResourceDrawerProps, 'isOpen'>) => void;
}

const BuilderContext = React.createContext<BuilderContextType>({} as any);

export default BuilderContext;

const { Pv2 } = api.getModels();

export const useBuilderContext = () => useContext(BuilderContext);

export const BuilderContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const changeListener = useRef<undefined | (() => void)>();

    const setPartialProduct = useCallback(async (partialProduct: Pv2Output) => {
        if (!partialProduct) return;
        update({ loadingState: 'fetching pv2 data' });
        const pv2 = await Pv2.findById(partialProduct._id, {
            populate: ['images', 'companies'],
            select: [
                '-createdBy',
            ],
        });

        update({ pv2 });
    }, []);

    const [state, setState] = useState<BuilderContextType>({
        pv2: null,
        setPartialProduct,
        setPv2: pv2 => update({ pv2 }),
        onProductChange: (cb) => { changeListener.current = cb; },
        loadingState: null,
        setLoadingState: loadingState => update({ loadingState }),
        theme: LS.get('theme')!,
        toggleTheme: () =>
            update(({ theme }) => ({
                theme: theme === 'light' ? 'dark' : 'light',
            })),
        resourceDrawer: null,
        setResourceDrawer: props =>
            update({
                resourceDrawer: props
                    ? {
                        isOpen: true,
                        // @ts-ignore
                        onClose: () => update({ resourceDrawer: null }),
                        ...props,
                    }
                    : null,
            })
    });

    const update = (diff: Partial<BuilderContextType> | ((state: BuilderContextType) => Partial<BuilderContextType>)): void =>
        typeof diff === 'function'
            ? setState(state => ({ ...state, ...diff(state) }))
            : setState(state => ({ ...state, ...diff }));

    useEffect(() => {
        if (state.pv2) changeListener.current?.();
    }, [state.pv2]);

    useEffect(() => {
        document.body.classList.remove('bp4-dark');
        if (state.theme === 'dark') document.body.classList.add('bp4-dark');
        LS.set({ theme: state.theme });
    }, [state.theme]);

    return <BuilderContext.Provider value={state}>
        {children}
    </BuilderContext.Provider>
}
