import _ from 'lodash';
import type { UiFormulaNode } from 'src/typeDefs';
import ExpressionDocs from './ExpressionDocs';
const { Actions: FormulaActions } = require('../../redux/Formula');
const { parseQuery, getExpression, getRandomName } = require('../../utils/utils');

const DEBUG = parseQuery().debug === 'true';

const ExpressionOptions: React.FC<{
    data: UiFormulaNode,
    onNotChange: () => void;
    noRemove?: boolean;
}> = ({ data, onNotChange, noRemove }) =>
    <div className='ExpressionSelector-options'>
        <label className={'ExpressionSelector-option-action'}>
            <input type='checkbox' checked={!!data.$not} onChange={onNotChange} />
            not
        </label>
        {!DEBUG || data.state.isChecker ? null :
            <Item
                text='debug dis'
                style={{ color: 'blue' }}
                onClick={() => {
                    const { store } = require('../../redux/store');
                    store.dispatch(FormulaActions.setFormula({
                        name: 'DEBUG: ' + data.path,
                        structure: data.plain,
                        ..._.pick(store.getState().formula.data, ['functions', 'options']),
                    }));
                }} />
        }

        {!data.state.actions.makeFunction ? null :
            <Item text='Make Function' onClick={data.makeFunction} bold />
        }

        <Item text='Add to clipboard' onClick={data.clipboardAdd} bold />

        {!data.state.actions.unwrap ? null :
            <Item text='Unwrap' onClick={data.unwrap} bold />
        }

        {data.$comment ?
            <Item text='remove comment' red onClick={() => data.change('$comment', undefined)} />
            :
            <Item text='add comment' onClick={() => data.change('$comment', 'No Comment')} />
        }

        {!data.state.actions.addArtifacts ? null :
            <Item text='Store Artifacts' onClick={() => data.change('$artifacts', getRandomName())} />
        }

        {!data.$checker ? null :
            <Item text='remove checker' red onClick={() => data.change('$checker', undefined)} />
        }

        {!data.state.actions.checker || data.$checker ? null :
            <Item
                text='add checker'
                onClick={() =>
                    data.change('$checker', {
                        true: getExpression('checker-message').getStub(['good']),
                        false: getExpression('checker-message').getStub(['bad']),
                    })
                }
            />
        }
        {noRemove ? null : <Item text='remove' red onClick={data.remove} />}

        <ExpressionDocs data={data} />
    </div>;

export default ExpressionOptions;

const Item: React.FC<{
    text: string;
    onClick: () => void;
    red?: boolean;
    bold?: boolean;
    style?: React.CSSProperties;
}> = ({ text, onClick, red, bold, style, ...props }) =>
    <div
        {...props}
        onClick={onClick}
        className={'ExpressionSelector-option-action'}
        style={Object.assign({}, style, !red ? null : { color: 'red' }, !bold ? null : { fontWeight: 'bold' })}
    >{text}</div>;
