import { S1_div, S2_Menu, S3_div } from 'file:///root/project/node_modules/.cache/react-style-loader/components_ResourceDrawer_ResourceDrawer-4csk.styl';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonGroup, Checkbox, Drawer, Menu, Tag } from '@blueprintjs/core';
import { TextInput } from '@pinto/ui';
import _ from 'lodash';

import external from '../../external';
import { DrawerItem } from './DrawerItem';
import { ResourcePreview } from './ResourcePreview';
import type { ParsedResource } from 'src/hooks/useExternalData';

export interface ResourceDrawerProps {
    handleId?: string;
    isOpen: boolean;
    value: string[];
    onChange: (value: string[]) => void;
    falsePositives?: boolean;
    onClose: () => void;
}

export interface SearchResource extends ParsedResource {
    search: string;
}

export const ResourceDrawer: React.FC<ResourceDrawerProps> = React.memo(({
    isOpen,
    value,
    onChange,
    falsePositives = false,
    onClose,
}) => {
    const data = getResourceRefDropdownOptions(falsePositives);
    const [filter, setFilter] = useState<string | null>('');
    const [focus, setFocus] = useState<string | null>(null);
    const [deprecated, setDeprecated] = useState(false);
    const [expanded, setExpanded] = useState<string | null>(null);

    const [filteredOptions, isVisible] = useMemo(() => {
        let filteredOptions: SearchResource[];

        if (!filter || filter.length <= 2) filteredOptions = data;
        else {
            filteredOptions = data.filter(option =>
                (deprecated || !option.search.includes('deprecated')) &&
                option.search.includes(filter)
            );
        }

        setFocus(filteredOptions[0]?._id ?? null);
        return [filteredOptions, Object.fromEntries(filteredOptions.map(item => [item._id, true]))];
    }, [data, filter, deprecated]);

    const map = useMemo(() => _.keyBy(data, '_id'), [data]);
    const mappedValue = value
        .map(id => map[id])
        .filter(x => x)
        .sort((a, b) => a.name.localeCompare(b.name));

    const toggle = useCallback((option: SearchResource) =>
        onChange(
            value.includes(option._id)
                ? value.filter(str => str !== option._id)
                : [...value, option._id]
        ),
        [value]
    );
    const selectedMap = Object.fromEntries(value.map(value => [value, true]));

    return <Drawer
        isOpen={isOpen}
        size='35rem'
        onClose={() => {
            onClose();
            setExpanded(null);
        }}
        canEscapeKeyClose
        isCloseButtonShown
        canOutsideClickClose
        shouldReturnFocusOnClose
        title={`${mappedValue.length} resources selected`}
        icon='cube'
    >
        {expanded && <ResourcePreview resource={external.refMap[expanded]} onClose={() => setExpanded(null)} />}
        <Content>
            <TextInput
                value={filter}
                onChange={setFilter}
                large
                placeholder='Filter'
                fill={false}
                autoFocus
                onKeyDown={(event: KeyboardEvent) => {
                    const len = filteredOptions.length;
                    const index = filteredOptions.findIndex(item => item._id === focus);
                    switch (event.key) {
                        case 'ArrowDown': setFocus(filteredOptions[(index + 1) % len]._id); break;
                        case 'ArrowUp': setFocus(filteredOptions[(len + index - 1) % len]._id); break;
                        case 'Enter':  toggle(filteredOptions[index]); break;
                    }
                }}
                rightElement={
                    <Button
                        intent='primary'
                        text='Apply & Close'
                        style={{ position: 'relative', left: -18 }}
                        onClick={onClose}
                    />
                }
            />

            <ButtonGroup className='my05'>
                <div style={{ flex: '1 1 0' }} />
                <Checkbox
                    checked={deprecated}
                    label='Show DEPRECATED'
                    onChange={() => setDeprecated(!deprecated)}
                    className='mb0'
                />
            </ButtonGroup>

            <Selected>
                {mappedValue.map(item =>
                    <Tag
                        key={item._id}
                        minimal
                        interactive
                        onRemove={() => toggle(item)}
                        onClick={() => toggle(item)}
                    >{item.name}</Tag>
                )}
            </Selected>

            <DrawerMenu
                data={data}
                focus={focus}
                selectedMap={selectedMap}
                isVisible={isVisible}
                toggle={toggle}
                setExpanded={setExpanded}
                expanded={expanded}
            />
        </Content>
    </Drawer>
});
ResourceDrawer.displayName = 'ResourceDrawer';

const DrawerMenu: React.FC<{
    data: SearchResource[];
    focus: string | null;
    selectedMap: Record<string, boolean>;
    isVisible: Record<string, boolean>;
    toggle: (option: SearchResource) => void;
    setExpanded: (option: string | null) => void;
    expanded: string | null;
}> = ({ data, focus, selectedMap, isVisible, toggle, setExpanded, expanded }) =>
    <StyledMenu>
        {data.map(item =>
            <DrawerItem
                key={item._id}
                resource={item}
                focused={item._id === focus}
                selected={!!selectedMap[item._id]}
                visible={isVisible[item._id]}
                onToggle={toggle}
                onExpand={setExpanded}
                expanded={item._id === expanded}
            />
        )}
    </StyledMenu>;

const getResourceRefDropdownOptions = _.memoize((falsePositives: boolean): SearchResource[] => {
    const list = falsePositives
        ? external.formulaResourceList.filter((x: any) => x.name.includes('falsePositive'))
        : external.formulaResourceList;

    return list.map(item => ({
        ...item,
        search: item.name.toLowerCase(),
    }));
});

const Content = S1_div.withComponent('div');
const StyledMenu = S2_Menu.withComponent(Menu);
const Selected = S3_div.withComponent('div');