import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import {withPropsOnChange} from 'recompose';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';

import {Actions, createDefaultStructure} from '../../redux/Formula';
import {LS} from '../../utils/LocalStorage';
import DB from '../../utils/IndexDB';
import {errorHandler, parseQuery} from '../../utils/utils';
import {cleanFormula, structIterate} from '../../utils/formula';
import validateFormula from '../../utils/validateFormula';

import {AddNewItem} from '../../ui';
import ExpressionSelector from '../../expressions/ExpressionSelector/ExpressionSelector';
import Exclamation from '../../components/Exclamation';
import ProductPreview from './ProductPreview';
import FunctionManager from './FunctionManager/FunctionManager';
import TabContent from '../../components/TabContent';
import CheckerMessages from './CheckerMessages/CheckerMessages';
import ProductEditor from './ProductEditor';
import Nav from './components/Nav';
import BuilderContext, {BuilderContextProvider} from 'src/utils/BuilderContext';
import memoizeOne from 'memoize-one';
import {api, legacyApi} from '@pinto/api-client';
import ProductAutoComplete from 'src/components/autocomplete/ProductAutocomplete';
import {GlobalResourceDrawer} from 'src/components/ResourceDrawer';
import {Spinner} from '@blueprintjs/core';
import useExternalData from 'src/hooks/useExternalData';

export default props => {
    const { done } = useExternalData();

    if (!done) return <Spinner />;

    return <BuilderContextProvider>
        <BuilderPage {...props} />
    </BuilderContextProvider>;
}

@connect(
    state => ({ formula: state.formula }),
    _.pick(Actions, ['setFormula', 'undo', 'setLog', 'setOptions', 'setPresets', 'toggleAll', 'setIn', 'setCollapseDepth'])
)
@withPropsOnChange(['formula'], ({formula}) => {
    return {
        checkerMessages: _.get(formula, 'checkerRun.messages'),
        testsRun: _.get(formula, 'testsRun', null),
    };
})
@withRouter
class BuilderPage extends PureComponent {
    static contextType = BuilderContext;

    static propTypes = {
        undo: PropTypes.func.isRequired,
        setFormula: PropTypes.func.isRequired,
        setLog: PropTypes.func.isRequired,
        setOptions: PropTypes.func.isRequired,
        toggleAll: PropTypes.func.isRequired,
        setIn: PropTypes.func.isRequired,
        setCollapseDepth: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
    }

    state = {
        usedProps: [],
        currentConfig: {},
        initialized: false,
        edit: false,
        extraColumn: LS.get('extraColumn', true),
        ignoreGlobalFilter: LS.get('ignoreGlobalFilter', false),
    }

    constructor (props) {
        super(props);

        window.Builder = this;
        window.LS = LS;
        window._ = _;
        window.DB = DB;
    }

    async componentDidMount() {
        const [,,, slug] = window.location.pathname.split('/');

        if (slug) {
            try {
                const list = await ProductAutoComplete.fetchItems(slug);
                this.context.setPartialProduct(list[0]);
                this.context.setLoadingState(null);
            } catch (ex) {
                console.error(ex);
            }
        }

        setTimeout(() => {
            this.context.onProductChange(() => this.run({}));
        }, 100);
    }

   UNSAFE_componentWillReceiveProps (nextProps) {
        const options = _.get(nextProps, 'formula.data.options', []);
        if (_.get(this.props, 'formula.data.options') !== options){
            this.setState({
                currentConfig: _.mapValues(_.keyBy(options, 'key'), 'default'),
            });
        }
    }

    componentDidUpdate (prevProps) {
        const { formula } = this.props;

        if (this.state.initialized && (formula?.data !== prevProps.formula?.data)) {
            DB.put({ id: 'formula', formula: _.pick(formula, ['data', 'history']) });
            this.setState({
                preview: null,
                usedProps: null,
                result: null,
            });
        }
    }

    setFormula (data, options) {
        this.props.setFormula(data, {
            // hideFunctions: true,
            // resetState: true,
            checkerMode: true,
            ...options,
        });
    }

    UNSAFE_componentWillMount () {
        DB.getById('formula').then(({ formula } = {}) => {
            if (formula) this.setFormula(formula.data, undefined, formula.history);
            this.setState({ initialized: true });
        });
    }

    makeUsedProps (props) {
        return _.uniq(['name', 'slug', 'topology', ...props]);
    }

    async run ({ tests = false } = {}) {
        const { setLog, formula: { data } } = this.props;
        const { pv2 } = this.context;
        this.context.setLoadingState('running formula');

        if (pv2.slug) this.goTo({ slug: pv2.slug });

        errorHandler();

        try {
            const res = await api.post('/admin/builder-runner', {
                data: {
                    ...(tests ? cleanFormula(data) : {...cleanFormula(data), tests: undefined}),
                    ignoreGlobalFilter: this.state.ignoreGlobalFilter,
                },
                pv2,
                options: this.state.currentConfig,
            })

            setLog(res);

            this.setState({
                preview: {
                    builder: {
                        ...res.doc,
                        __vars: res.vars,
                        __exports: res.exports,
                        __pv2AddAttributes: res.pv2AddAttributes,
                        __pv2AddAttributesError: res.error || '',
                        __pv2RemoveAttributes: res.pv2RemoveAttributes,
                    },
                    checker: { ...res.doc, __vars: _.get(res, ['checker', 'vars']) },
                },
                usedProps: this.makeUsedProps(res.props),
                result: res,
            });
        } catch (ex) {
            errorHandler(ex);
        }

        this.context.setLoadingState(null);
    }

    handleRun = async opts => {
        const { slug } = this.getUrl();

        if (slug) {
            // todo
        }

        await this.run(opts);
    }

    handleClear = () => {
        const {setLog} = this.props;
        setLog(null);
        this.setState({
            preview: null,
            usedProps: null,
            result: null,
            // stats: null,
        });
    }

    handleEditToggle = () => { this.setState({ edit: !this.state.edit });}

    handleBatchClick = filter => {
        const {formula: {data}} = this.props;

        errorHandler();

        this.setState({status: {...this.state.status, running: true}});

        legacyApi.post('/admin/runner-parse', {data})
        .then(({props}) =>
            legacyApi.post('/builder/filter', {
                formula: cleanFormula(data),
                options: this.state.currentConfig,
                withMatched: true,
                props: this.makeUsedProps(props),
                filter,
            })
        )
        .then(stats => {
            const makeList = map =>
                Object.keys(map).sort((a, b) => map[b] - map[a]).map(key => [key, map[key]]);

            this.setState({
                stats: {
                    ...stats,
                    passedList: makeList(stats.passedMap),
                    failedList: makeList(stats.failedMap),
                    filteredList: makeList(stats.filteredMap),
                },
            }, () => this.goToTabIfNeeded('secondTab', 'stats'));
        })
        .catch(errorHandler)
        .then(() => this.setState({ status: {...this.state.status, running: false} }))
    }

    handleDrop = (event) => {
        event.preventDefault();
        if (!event.dataTransfer || !event.dataTransfer.files) return;
        const [file] = event.dataTransfer.files;
        if (!file) return;
        const name = file.name.replace(/\.[^.]+$/, '');
        const reader = new FileReader();
        reader.onload = ({target: {result}}) => {
            const formula = {name, ...JSON.parse(result)};

            try {
                validateFormula(formula);
            } catch (ex) {
                errorHandler(ex);
                return;
            }

            this.setFormula(formula);
        }
        reader.readAsText(file);
    }

    handleFileInfoChange = (fileInfo) => {
        const { formula: { data } } = this.props;

        this.setFormula({
            ...data,
            ...fileInfo,
        });
    }

    handleSelectFormula = data => {
        this.setFormula({
            ...data,
            structure: JSON.parse(data.structure),
            checker: !data.checker ? undefined : JSON.parse(data.checker),
            filter: !data.filter ? undefined : JSON.parse(data.filter),
            functions: !data.functions ? undefined : JSON.parse(data.functions),
        });
    }

    handleReset = () => {
        this.setFormula({
            name: 'Reset',
            structure: createDefaultStructure(),
        });
    }

    handleColumnToggle = () => {
        const extraColumn = !this.state.extraColumn;
        this.setState({ extraColumn })
        LS.set({ extraColumn });
    }

    goTo (obj) { return this.props.history.push(this.getUrlString(obj)); }

    getUrlString (obj) {
        obj = _.defaults({}, obj, this.getUrl());

        const {slug} = obj;
        const firstTab = obj.firstTab.join(':');
        const secondTab = obj.secondTab.join(':');

        const q = location.search || '';

        if (slug) return `/${firstTab}/${secondTab}/${slug}${q}`;

        return `/${firstTab}/${secondTab}${q}`;
    }

    getUrl () {
        const {match: {params}} = this.props;

        const defaults = {
            firstTab: 'builder',
            secondTab: 'preview',
            slug: null,
        };

        const result = _.defaults({}, params, defaults);
        const availableTabs = this.getAvailableTabs();

        const set = id => {
            result[id] = (result[id] || '').split(':');
            if (result[id].length < 2 && !availableTabs[result[id][0]]) result[id] = defaults[id].split(':');
        }

        set('firstTab');
        set('secondTab');

        return result;
    }

    getAvailableTabs () {
        const {checkerMessages, formula} = this.props;
        const {preview, stats} = this.state;

        return {
            builder: true,
            functions: true,
            checker: true,
            checkerStats: true,
            options: true,
            tests: true,
            presets: !!_.size(formula.data.options),
            checkerMessages: !!checkerMessages,
            preview: !!preview,
            stats: !!stats,
        };
    }

    goToTabIfNeeded (type, tab) {
        const url = this.getUrl();

        if (url.firstTab.includes(tab) || url.secondTab.includes(tab)) return;

        this.goTo({[type]: [tab]});
    }

    getHasVisibleFunctions = memoizeOne((structure, checker) => {
        let hasVisible = false;
        structIterate(
            {  $op: 'and', $value: _.filter([structure, checker]) },
            item => {
                hasVisible = hasVisible || (item.$op === 'call' && !item.state.hidden);
            },
        );

        return hasVisible;
    });

    render () {
        const {
            undo,
            toggleAll,
            formula,
            setPresets,
            checkerMessages,
            setOptions,
            setIn,
            setCollapseDepth,
            match: {params},
        } = this.props;
        const {
            preview,
            usedProps,
            stats,
            status,
            initialized,
            extraColumn,
            result,
            ignoreGlobalFilter,
            edit,
        } = this.state;
        const { pv2 } = this.context;
        const { data } = formula;

        if (!initialized) return null;

        const url = this.getUrl();

        const checkParams = id => _.isEqual((params[id] || '').split(':'), url[id])

        if (!checkParams('firstTab') || !checkParams('secondTab')) {
            return <Redirect to={this.getUrlString()} />
        }

        if (!data) return <div>waiting...</div>;

        const isRunning = !!status && !!status.running;
        const hasVisibleFunctions = this.getHasVisibleFunctions(formula.data.structure, formula.data.checker);

        const isChecker = params.firstTab === 'checker' || params.secondTab === 'checker';

        const contentProps = {
            setIn,
            checkerMessages: checkerMessages,
            formula: formula,
            preview: !preview ? null : (
                isChecker ? preview.checker || preview.builder : preview.builder
            ),
            isChecker,
            stats: stats,
            usedProps: usedProps,
            setOptions: setOptions,
            setPresets,
            handleRun: this.handleRun,
            configProps: {
                options: formula.data.options,
                values: this.state.currentConfig,
                onChange: currentConfig => this.setState({currentConfig}),
            },
        };

        return <div
            className='builder'
            onDragOver={event => event.preventDefault()}
            onDragStart={event => event.preventDefault()}
            onDragEnter={event => event.preventDefault()}
            onDrop={this.handleDrop}
        >
            {edit && <ProductEditor onClose={() => this.setState({ edit: false })} />}
            <Nav
                formula={formula}
                preview={!!preview}
                clear={this.handleClear}
                run={() => this.run()}
                toggleEdit={() => this.setState({ edit: !edit })}
                handleFileInfoChange={this.handleFileInfoChange}
                reset={this.handleReset}
                undo={undo}
                hasVisibleFunctions={hasVisibleFunctions}
                toggleFunctions={() => toggleAll(hasVisibleFunctions)}
                toggleExtraColumn={this.handleColumnToggle}
                extraColumn={extraColumn}
                ignoreGlobalFilter={ignoreGlobalFilter}
                toggleIgnoreGlobalFilter={() =>
                    this.setState(
                        { ignoreGlobalFilter: !ignoreGlobalFilter },
                        () => LS.set({ ignoreGlobalFilter: this.state.ignoreGlobalFilter }),
                    )
                }
                collapseDepth={_.get(formula, 'uiOptions.collapseDepth', 6) }
                setCollapseDepth={val => setCollapseDepth(val || 6) }
                setFormula={this.handleSelectFormula}
            />

            {!formula.errors || !Object.keys(formula.errors).length ? null :
                <div className={'builder-errors'}>
                    You have {Object.keys(formula.errors).length} error(s) in your structure. <br />
                    Look for <Exclamation message='Mouse over will show you the errors' /> bellow to get details
                </div>
            }

            {result && (
                result.filteredOutByGlobalFilter
                    ? <div className='builder-filter-message'>
                        Formula is disabled for this product's taxonomies via the
                        {' '}
                    <a target='_blank' href='https://admin.pinto.co/configs#builder:formula-filters'>builder:formula-filters</a> Config
                    </div>
                    : result.filteredOutByGlobalFilter
                        ? <div className='builder-filter-message'>Disabled out by the builder filter</div>
                        : null
            )}

            <div className={cx('builder-content', formula.log == null && 'builder-with-colors')}>
                {result && result.errors.length &&
                    <div className='builder-schema-errors'>
                        {result.errors.map(e => (<span><strong>{e.attributeType}</strong>&mdash;{e.message}</span>))}
                    </div>}
                <div className='builder-content-container'>
                    <Content
                        {...contentProps}
                        selectedTab={url.firstTab}
                        setSelectedTab={val => this.goTo({firstTab: val})}
                        availableTabs={{
                            ...this.getAvailableTabs(),
                            ...(!extraColumn ? null : _.mapValues(_.keyBy(url.secondTab), () => false))
                        }}
                    />
                    {!extraColumn ? null :
                        <Content
                            {...contentProps}
                            selectedTab={url.secondTab}
                            setSelectedTab={val => this.goTo({secondTab: val})}
                            availableTabs={{...this.getAvailableTabs(), ..._.mapValues(_.keyBy(url.firstTab), () => false)}}
                        />
                    }
                </div>
            </div>

            {!parseQuery().debug ? null :
                <pre>
                    <h3>structure:</h3>
                    {JSON.stringify(cleanFormula(data).structure, null, 4)}
                    <h3>functions</h3>
                    {JSON.stringify(data.functions.map(cleanFormula), null, 4)}
                    <h3>checker</h3>
                    {JSON.stringify(cleanFormula(data).checker, null, 4)}
                    <h3>tests</h3>
                    {JSON.stringify(_.map(data.tests, cleanFormula), null, 4)}
                </pre>
            }

            <GlobalResourceDrawer />
        </div>
    }
}

const Content = ({
    availableTabs,
    selectedTab,
    setSelectedTab,
    checkerMessages,
    formula,
    stats,
    preview,
    usedProps,
    setOptions,
    configProps,
    setPresets,
    setIn,
    isChecker,
    handleRun,
}) =>
    <TabContent defaultSelected={selectedTab} onChange={setSelectedTab} className={'Content'}>{
        _.filter([
            {
                id: 'stats',
                name: <div className='tab-highlight'>Batch Stats</div>,
                render: () => <BatchStats stats={stats} configProps={configProps} />,
            },

            {
                id: 'checkerMessages',
                name: <div className='tab-highlight'>Checker Messages</div>,
                render: () => <CheckerMessages data={checkerMessages} />
            },

            {
                id: 'preview',
                name: <div className='tab-highlight'>Preview</div>,
                render: () =>
                    <ProductPreview
                        isChecker={isChecker}
                        configProps={configProps}
                        product={preview}
                        usedProps={usedProps}
                    />,
            },

            {
                id: 'builder',
                name: 'Builder',
                render: () =>
                    formula.data.filter ?
                        <div>
                            <h3>Filter:</h3>
                            <ExpressionSelector depth={0} path='filter' />

                            <h3>Builder:</h3>
                            <ExpressionSelector depth={0} path='structure' noRemove={true} />
                        </div>
                        :
                        <div>
                            <AddNewItem
                                onClick={() =>
                                    setIn('filter', { $op: 'fn', $value: 'constant', $args: [ 'true' ] })
                                }
                            >+ add a filter</AddNewItem>
                            <ExpressionSelector depth={0} path='structure' noRemove={true} />
                        </div>,
            },

            {
                id: 'functions',
                name: 'Functions',
                render: () => <FunctionManager className='builder-with-colors' />,
            },
        ], x => x && !!availableTabs[x.id])
    }</TabContent>;
