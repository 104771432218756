import _ from 'lodash';
import memoizeOne from 'memoize-one';
import { ISuggestProps, Suggest } from '@blueprintjs/select';

import BaseSearch, {
    BaseSearchProps,
    BaseType,
    createBaseSearchField,
    SearchOption,
} from './BaseSearch';
import { SelectOptionKey } from 'src/typeDefs';

export interface SearchSuggestProps
    extends BaseSearchProps<SelectOptionKey>,
        Partial<ISuggestProps<SelectOptionKey>> {
    showSelected?: boolean;
}

export class SearchSuggest extends BaseSearch<SelectOptionKey, SearchSuggestProps> {
    readonly SelectComponent = Suggest;

    isOptionSelected: BaseType<'isOptionSelected'> = option => this.props.value === option.key;

    handleClear: BaseType<'handleClear'> = () => this.props.onChange(null);

    onItemSelect: BaseType<'onItemSelect'> = option => this.props.onChange(option.key, option);

    createGetItems: BaseType<'createGetItems'> = () =>
        memoizeOne((options: SearchOption[], inputValue) => {
            const items = options.map((opt, index) => ({
                ...opt,
                search: (opt.label || '').trim().toLowerCase(),
                index,
            }));

            if (inputValue != null && !_.find(items, { key: inputValue })) {
                // @ts-ignore
                items.push({
                    key: inputValue,
                    label: `(custom) ${inputValue}`,
                    isNew: true,
                    buttonProps: {
                        intent: 'danger',
                    },
                });
            }

            return {
                items,
            };
        });

    inputValueRenderer = (item: SearchOption) => (this.props.showSelected ? item.label : '');
    getComponentProps() {
        return {
            ...super.getComponentProps(),
            inputValueRenderer: this.inputValueRenderer,
            selectedItem: this.props.selectedItem
        };
    }
}

export default SearchSuggest;

export const SearchSuggestField = createBaseSearchField(SearchSuggest);
