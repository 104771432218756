
import styled, {css} from 'styled-components';
import _ from 'lodash';

export const mapProps = map => {
    const onProp = (prop, result) => props => {
        const value = _.get(props, prop);
        if (value != null) {
            return value === false ? null : _.isFunction(result)
                ? result(value, prop, props)
                : result !== true ? result : value;
        }
    };

    map = _.map(map, (fn, prop) => onProp(prop, fn));

    return props => _.map(map, fn => fn(props));
};


export const FlexItem = styled.div`
    ${mapProps({
        grow: css`flex: 1 1 0;`,
        auto: css`flex: 0 0 auto!important;`,
        flex: css`display: flex;`,
    })}
`;

export const AddNewItem = styled.div`
    display: inline-block;
    margin-top: .5rem;
    color: royalblue;
    cursor: pointer;
`;

/** @deprecated */
export const Button = styled(FlexItem)`
    display: inline-block;
    border: 0;
    border-radius: .5rem;
    box-shadow: 0 0 1px #aaa inset;
    background: #fafafa;
    padding: .3rem 1rem;
    font-size: .8rem;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    transition: background .2s ease, color .2s ease, transform .1s ease;
    outline: none;

    &:active { transform: translateX(2px) translateY(2px); }

    ${mapProps({
        block: css`display: block;`,
        hover: css`
            &:hover, &:focus {
                background: darkturquoise;
                color: white;
            }
        `,
        primary: css`
            background: royalblue;
            color: white;
        `,
        green: css`
            background: lightgreen;
        `,
        delete: css`
            background: crimson;
            color: white;

            &:hover, &:focus {
                background: darkred;
                color: white;
            }
        `,
        centered: css`
            display: block;
            margin: 0 auto;
            padding: .5rem;
            width: 10rem;
            font-size: 1rem;
            text-align: center;
        `,
        disabled: css`
            background: #ccc!important;
            cursor: not-allowed;
        `,
        margin: val => css`margin-top: ${val}rem; margin-bottom: ${val}rem`,
    })}
`;

export const LabelName = styled.div`
    flex: 0 0 auto;
    margin-right: .5rem;
    min-width: 5rem;
    font-weight: bold;
    ${mapProps({
        size: size => css`min-width: ${size}rem;`,
    })}
`;

export const Label = styled.label`
    display: flex;
    align-items: center;

    & + & {
        margin-top: .5rem;
    }

    > :not(${LabelName}) {
        flex: 1 1 0;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: center;

    ${mapProps({
        margin: val => css`margin: ${val}rem 0;`,

        'margin-top': val => css`margin-top: ${val}rem;`,

        flexStart: css`align-items: flex-start`,
    })}
`;

export const Err = styled(FlexItem)`
    color: crimson;
`;

export const Hr = styled.div`
    border-top: 2px solid #ccc;
    margin: 1.25rem 0;
`;
