import { setup } from '@pinto/api-client';

export default function setupApiClient (token?: string) {
    setup({
        apiLegacyUrl: process.env.REACT_APP_API_LEGACY_URL,
        apiV2Url: process.env.REACT_APP_API_V2_URL,
    });

    if (token != null) {
        setup({
            apiV2Token: token,
            apiLegacyToken: token,
        });
    }
}
