
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './TextArea.scss';

export default class TextArea extends PureComponent {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onInput: PropTypes.func,
        maxHeight: PropTypes.number.isRequired,
    }

    static defaultProps = {
        maxHeight: 160,
    }

    componentDidMount () {
        setTimeout(() => { this.resize(); }, 10);
    }

    resize () {
        const {maxHeight} = this.props;

        if (!this._ref) return;

        this._ref.style.height = '';
        this._ref.style.height = Math.min(this._ref.scrollHeight, maxHeight) + 'px';
    }

    handleRef = elem => this._ref = elem

    handleInput = event => {
        const {onInput} = this.props;
        this.resize();
        if (onInput) onInput(event.target.value, event);
    }

    handleChange = event => {
        const {onChange, defaultValue} = this.props;
        const {value} = event.target;
        if (!value && defaultValue == null || value === defaultValue) return;
        onChange(value, event);
    }

    render () {
        const {onChange, onInput, value, maxHeight, className, ...rest} = this.props;

        return (
            <textarea
                rows={1}
                {...rest}
                ref={this.handleRef}
                className={cx('TextArea-root', this.props.readOnly && 'TextArea-readOnly', className)}
                onBlur={this.handleChange}
                onInput={this.handleInput}
            />
        );
    }

}
