
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FnExpression from './FnExpression';
import ExpressionSelector, { Container } from './ExpressionSelector/ExpressionSelector';

export default class IfExpression extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            // TODO
        }).isRequired,
    };

    static getStub = () => ({
        $op: 'if',
        $value: FnExpression.getStub(),
        $then: FnExpression.getStub(),
        $else: { ...FnExpression.getStub(['constant']), $args: [ 'false' ] },
    })

    render () {
        const {data: { depth, getChildPath, getChildLogResult }} = this.props;

        return <div className='expression-if'>
            <ExpressionSelector depth={depth + 1} noRemove={true} path={getChildPath('$value')} />

            <Container
                displayArgs={{
                    type: 'if',
                    parentType: 'if',
                    result: getChildLogResult('$then'),
                    root: true,
                }}
                label='Then:'
                style={{marginLeft: '-.5rem'}}
            />

            <ExpressionSelector depth={depth + 1} noRemove={true} path={getChildPath('$then')} />

            <Container
                displayArgs={{
                    type: 'if',
                    parentType: 'if',
                    result: getChildLogResult('$else'),
                    root: true,
                }}
                label='Else:'
                style={{marginLeft: '-.5rem'}}
            />

            <ExpressionSelector depth={depth + 1} data-last-child noRemove={true} path={getChildPath('$else')} />
        </div>;
    }
}
