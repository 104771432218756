import { S1_div, S2_div } from 'file:///root/project/node_modules/.cache/react-style-loader/components_SortableList_SortableList-RmQk.styl';
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

export default class SortableList extends PureComponent {
    static propTypes = {
        onSwap: PropTypes.func.isRequired,
    }

    state = {
        draggingIndex: null,
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState({ draggingIndex: null });
        this.props.onSwap(oldIndex, newIndex);
    }

    onSortStart = ({ index }) => this.setState({ draggingIndex: index })

    render () {
        const { onSwap, ...rest } = this.props;
        const { draggingIndex } = this.state;

        return (
            <SortableListContainer
                {...rest}
                lockAxis='y'
                useDragHandle={true}
                onSortStart={this.onSortStart}
                onSortEnd={this.onSortEnd}
                draggingIndex={draggingIndex}
                lockToContainerEdges={true}
            />
        );
    }
}

@SortableContainer
class SortableListContainer extends PureComponent {

    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.node).isRequired,
        showHandle: PropTypes.bool.isRequired,
        visibleHandle: PropTypes.bool.isRequired,
        handleLeft: PropTypes.string,
        draggingIndex: PropTypes.number,
    }

    static defaultProps = {
        showHandle: true,
        visibleHandle: false,
    }

    render () {
        const {
            onSwap,
            showHandle,
            visibleHandle,
            handleLeft,
            draggingIndex,
            className,
            ...props
        } = this.props;

        return <div {...props}>
            {this.props.children.map((child, index) =>
                <Item
                    key={index}
                    visibleHandle={visibleHandle}
                    handleLeft={handleLeft}
                    showHandle={showHandle}
                    index={index}
                    data-disabled={draggingIndex != null && draggingIndex !== index}
                >
                    {child}
                </Item>
            )}
        </div>
    }
}

const Item = SortableElement(
    ({ showHandle, visibleHandle, handleLeft, children, ...rest }) =>
        <ItemRoot {...rest}>
            {!showHandle? null :
                <Handle
                    data-visible={visibleHandle}
                    style={!handleLeft ? null : { left: handleLeft }}
                />
            }

            {children}
        </ItemRoot>
);

const Handle = SortableHandle(props => <HandleRoot {...props} />);

const ItemRoot = S1_div.withComponent("div");
const HandleRoot = S2_div.withComponent("div");