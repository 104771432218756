import {Icon} from '@blueprintjs/core';
import {api, CompanyOutput, ImageOutput, Pv2Output} from '@pinto/api-client';
import isMongoId from 'src/utils/isMongoId';
import createAutocomplete from './createAutocomplete';
import {useEffect, useState} from "react";

const {Pv2} = api.getModels({forceV2: true});

const ProductAutoComplete = createAutocomplete<Pv2Output>({
    convertItemToOption: item => {
        const brand = item.brand as unknown as CompanyOutput
        return {
            key: item._id,
            label: item.name,
            props: {
                icon: <ProductIcon data={item}/>,
                label: brand?.name,
            },
            item,
        }
    },

    fetchItems: text => {
        return Pv2.find({
            limit: 10,
            populate: ['images', 'brand'],
            select: ['name', 'brand', 'slug', 'images'],
            // sort: 'name', // sort is a massive perf hit on the db
            query: text
                ? isMongoId(text)
                    ? {_id: text}
                    : {
                        $or: [
                            {slug: text},
                            {name: '~' + text},
                        ]
                    }
                : {}
        })
    },

    createDefaultButtonProps: value =>
        value
            ? {
                icon: <ProductIcon data={value} size={20}/>,
                style: {whiteSpace: 'nowrap'},
                text: <div
                    style={{
                        minWidth: '3rem',
                        maxWidth: '15rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {value.name}
                    <small className='hidden-on-small'><b> by</b> {(value.brand && (value.brand as unknown as CompanyOutput).name) || '—'}
                    </small>
                </div>,
            }
            : {
                text: 'Search for Pv2 by Name, slug, id',
            },
});

export default ProductAutoComplete;

const ProductIcon: React.FC<{ data: Pv2Output; size?: number }> = ({data, size = 32}) => {
    const [imageSrc, setImageSrc] = useState<string>('');
    useEffect(() => {

        const thumbnail = (data.images as unknown as ImageOutput[]).find(i => i?.side === 'front')
        let imageSrc = thumbnail?.urls.find(u => u.type = 'thumbnail')?.url || '';
        const fetchData = async () => {
            const imageData: { urls: { type: string; id: string; }[] } | undefined = await api.get(`/image/${thumbnail?._id}/urls`);
            if (imageData) {
                const thumbnailUrl = imageData.urls.find(u => u.type === 'thumbnail');
                setImageSrc(thumbnailUrl?.id || '');
            }
        }


        if (imageSrc.includes('aws') && data.images) {
            fetchData();
        } else {
            setImageSrc(imageSrc);
        }
    }, [data])

    return (
        <Icon
            icon={
                <img
                    alt={'Image'}
                    src={imageSrc}
                    height={size}
                    width={size}
                />
            }
        />)
};
