import { FormulaOutput, FormulaResourceOutput, api } from '@pinto/api-client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { shamefullySetExternalData } from 'src/external';

const { Taxonomy, FormulaResource, Formula } = api.getModels({forceV2:true});

export interface ExternalData {
    nutrients: string[];
    formulaResourceList: ParsedResource[];
    formulaList: Array<Pick<FormulaOutput, '_id' | 'name' | 'version'>>;
    refMap: Record<string, ParsedResource>;
    taxonomies: Record<string, string>; // id ==> breadcrumb
    taxonomyOptions: Array<[key: string, label: string]>;
}

export interface ParsedResource<Type extends FormulaResourceOutput['type'] = any> extends Omit<FormulaResourceOutput, 'value'> {
    value: Type extends 'list'
        ? string[]
        : Type extends 'table'
        ? Record<string, any>[]
        : never;
}

export default function useExternalData(): { done: false, data: null } | { done: true, data: ExternalData } {
    const [done, setDone] = useState(false);
    const [data, setData] = useState<null | ExternalData>(null);

    useEffect(() => {
        void (async () => {
            const [taxonomyList, formulaList, rawFormulaResourceList] = await Promise.all([
                Taxonomy.find({ select: ['_id', 'breadcrumb'], limit: 10e3 }),
                Formula.find({ select: ['_id', 'version', 'name'], limit: 10e3 }),
                FormulaResource.find({ limit: 10e3 }),
            ]);

            const formulaResourceList = rawFormulaResourceList.map(f => ({
                ...f,
                value: JSON.parse(f.value),
            }))

            const data: ExternalData = {
                taxonomies: Object.fromEntries(taxonomyList.map(t => [t._id, t.breadcrumb!])),
                taxonomyOptions: taxonomyList.map(t => [t._id, t.breadcrumb!]),
                formulaList,
                formulaResourceList,
                refMap: _.keyBy(formulaResourceList, '_id'),
                nutrients,
            };

            shamefullySetExternalData(data);
            setData(data);
            setDone(true);
        })();
    }, []);

    if (done) return { done, data: data! };

    return { done, data: null };
}

const nutrients = [
    'addedSugar',
    'biotin',
    'caffeine',
    'calcium',
    'calories',
    'carbohydrates',
    'chloride',
    'cholesterol',
    'choline',
    'chromium',
    'copper',
    'fatCalories',
    'fiber',
    'folate',
    'folicAcid',
    'insolubleFiber',
    'iodine',
    'iron',
    'lycopene',
    'magnesium',
    'manganese',
    'molybdenum',
    'monounsaturatedFat',
    'niacin',
    'pantothenicAcid',
    'phosphorus',
    'polyunsaturatedFat',
    'potassium',
    'protein',
    'riboflavin',
    'saturatedFat',
    'selenium',
    'sodium',
    'solubleFiber',
    'sugar',
    'sugarAlcohol',
    'thiamin',
    'totalFat',
    'transFat',
    'vitaminA',
    'vitaminB12',
    'vitaminB6',
    'vitaminC',
    'vitaminD',
    'vitaminE',
    'vitaminK',
    'zinc',
];
