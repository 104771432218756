import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

export default class Select extends PureComponent {
    static propTypes = {
        value: PropTypes.any,
        options: PropTypes.arrayOf(PropTypes.array).isRequired,
        readOnly: PropTypes.bool,
        children: PropTypes.element,
        renderLabel: PropTypes.func.isRequired,
    }

    static defaultProps = {
        renderLabel: (label, _value) => label,
    }

    renderGroups (options) {
        const groupMap = {};
        for (let [key, name, group, props] of options) {
            group = group != null ? group : null;
            groupMap[group] = groupMap[group] || [];
            groupMap[group].push([key, name, null, props]);
        }

        return <React.Fragment>
            {groupMap['null'] && this.renderOptions(groupMap['null'])}
            {Object.keys(_.omit(groupMap, 'null')).map(label =>
                <optgroup key={label} label={label}>
                    {this.renderOptions(groupMap[label])}
                </optgroup>
            )}
        </React.Fragment>

    }

    renderOptions (options) {
        return options.map(([val, label, group, props]) =>
            <option {...props} key={val} value={val}>{label || val}</option>
        );
    }

    render () {
        const {options, value, readOnly, children, renderLabel, className, rootProps, ...props} = this.props;

        let label = '[no value]';
        const obj = options.find(([val]) => val === value);
        if (obj) label = obj[1] || obj[0];
        else if (value) {
            label = <span style={{ color: 'red' }}>[invalid value: {value}]</span>;
        }

        let childOptions;
        if (options.some(item => item[2])) childOptions = this.renderGroups(options)
        else childOptions = this.renderOptions(options);

        return <div {...rootProps} className={cx('select', readOnly && 'select-readOnly', className)}>
            {children ? children :
                <div className='select-handle'>{renderLabel(label, value)}</div>
            }
            {readOnly ? null :
                <select value={value == null ? '' : value} {...props} tabIndex='-1'>
                    {value != null ? null : <option disabled value=''>[no value]</option>}
                    {childOptions}
                </select>
            }
        </div>;
    }
}
