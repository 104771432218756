import {api, FormulaOutput} from '@pinto/api-client';
import isMongoId from 'src/utils/isMongoId';
import createAutocomplete from './createAutocomplete';

const {Formula} = api.getModels({forceV2: true});

const FormulaAutocomplete = createAutocomplete<FormulaOutput>({
    fetchItems: text =>
        Formula.find({
            limit: 20,
            sort: 'name',
            query: text
                ? isMongoId(text)
                    ? {_id: text}
                    : {
                        name: '~' + text,
                        isBuilderV2: true,
                    }
                : {isBuilderV2: true,},
        }),

    convertItemToOption: item => ({
        key: item._id,
        label: item.name,
        props: {
            label: (item.tags || []).map(tag =>
                <span key={tag} className='AutoComplete-tag' data-name={tag}>{tag}</span>
            ),
        },
    }),

    createDefaultButtonProps: value =>
        value
            ? {
                text: <div
                    style={{
                        minWidth: '3rem',
                        maxWidth: '15rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {value.name}
                </div>,
            }
            : {text: 'Load saved Formula'}

});

export default FormulaAutocomplete;
