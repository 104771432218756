import {  Handle, Content, Result, Args, Arg, ArgValue  } from 'file:///root/project/node_modules/.cache/react-style-loader/expressions_ExpressionSelector_ExpressionDocs-m56s.styl';
import { Markdown } from '@pinto/ui';
import type { UiFormulaNode } from 'src/typeDefs';
import { getMethod, Method, MethodArg } from 'src/utils/methods';
import serializeToJs from 'serialize-to-js';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';

import React from 'react';
import _ from 'lodash';
import { HTMLTable } from '@blueprintjs/core';

const ExpressionDocs: React.FC<{ data: UiFormulaNode }> = ({ data }) => {
    if (data.$op !== 'fn') return null;

    const method = getMethod(data.$value);
    if (!method) return null;

    return <Popover2
        content={<DocsContentInner method={method} />}
        lazy
        interactionKind='hover'
        position='right'
        minimal
    >
        <Handle className='ExpressionSelector-option-action'>Docs</Handle>
    </Popover2>
}

export default ExpressionDocs;

const DocsContentInner: React.FC<{ method: Method }> = ({ method }) => {
    const hasVars = _.some(method.examples, item => !_.isEmpty(item.outputVars));
    const header = <Markdown text={method.description} />;

    if (!method.examples?.length) return <Content>{header}</Content>;

    return <Content>
        {header}
        <h3>Examples:</h3>
        <HTMLTable interactive condensed>
            <thead>
                <tr>
                    <th></th>
                    <th>name</th>
                    <th>args</th>
                    {hasVars && <th>var result</th>}
                </tr>
            </thead>
            <tbody>
                {method.examples?.map((example, i) => {
                    const vars = hasVars && example.outputVars
                        ? Object.entries(example.outputVars)
                        : [];
                    return <tr key={i}>
                        <td>
                            <Tooltip2 content={example.result ? 'return true' : 'returns false'}>
                                <Result data-result={!!example.result} />
                            </Tooltip2>
                        </td>
                        <td data-result={!!example.result}>{example.name}</td>
                        <td>
                            <Args>
                                {example.args?.map((arg, i) =>
                                    <React.Fragment key={i}>
                                        <Argument
                                            key={i}
                                            value={arg}
                                            info={typeof method.args[i] === 'function'
                                                ? undefined
                                                : method.args[i] as MethodArg
                                            }
                                            argType={method.argTypes?.[i]}
                                            argValue={example.argValues?.[i]!}
                                        />
                                        {i !== example.args.length - 1 && ','}
                                    </React.Fragment>
                                )}
                            </Args>
                        </td>
                        {hasVars &&
                            <td>{example.outputVars &&
                                // assume a single var always and don't print the key for brevity
                                vars.map(([k, v]) =>
                                    <Arg key={k}>{serializeToJs(v)}</Arg>
                                )
                            }</td>
                        }
                    </tr>
                })}
            </tbody>
        </HTMLTable>
    </Content>
}

const Argument: React.FC<{
    value: any;
    argType?: string;
    argValue?: string;
    info?: {
        name: string;
        label: string;
        description?: string;
    };
}> = ({ value, argType, argValue, info: { description, name } = {} }) => {
    if (Array.isArray(value)) {
        value = value.map((item, i) => /^[\da-f]{24}$/.test(item) ? `id_${i}` : String(item)).join(', ');
    }

    return <Arg Tag='span'>
        <Tooltip2
            content={
                <Markdown
                    text={
                        [
                            (argType ? `**${argType} **` : '') + `_${name}_: ` + description,
                            argValue,
                        ].filter(x => x).join('\n\n')
                    }
                />
            }
        >
            <ArgValue Tag='span'>
                {value && typeof value === 'object' ? serializeToJs(value) : value}
            </ArgValue>
        </Tooltip2>
    </Arg>;
}

