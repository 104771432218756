import {  Root, Message, Icon, Phrase  } from 'file:///root/project/node_modules/.cache/react-style-loader/routes_Builder_CheckerMessages_CheckerMessages-H8wY.styl';
import { getFlagInfo } from '../../../utils/flags';

export default ({data}) =>
    <Root>
        {!data || !data.length ? <div>no messages</div> :
            data.map((flag, index) =>
                <Message
                    key={index}
                    data-type={flag.type}
                >
                    <Icon style={{ backgroundColor: getFlagInfo(flag.type).color }}/>
                    {renderMessage(flag, getFlagInfo(flag.type).color)}
                </Message>
            )
        }
    </Root>;

const renderMessage = ({ message, formattedMessage }, color) => {
    if (!formattedMessage) return message;

    const prettyPrint = (node, props) =>
        <Phrase
            {...props}
            data-type={node.type}
            style={node.type === 'bold' ? { color } : null}
        >
            {Array.isArray(node.value)
                ? node.value.map((child, key) => prettyPrint(child, { key }))
                : node.value
            }
        </Phrase>

    return prettyPrint(formattedMessage);
}

