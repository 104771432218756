import React from 'react';
import MonacoEditor, { EditorProps, OnChange, OnMount } from '@monaco-editor/react';
import _ from 'lodash';

// import { AppContext } from 'utils/AppContext';

const DEFAULT_OPTIONS: EditorProps['options'] = {
    scrollBeyondLastLine: false,
    // wordWrap: 'on',
    minimap: { enabled: false },
    tabSize: 4,
    fontSize: 12,
};

export type MonacoEditorProps = EditorProps & {
    onBlur?: (value: string) => void;
    style?: React.CSSProperties;
    minHeight?: number;
    maxHeight?: number;
}

export default class Editor extends React.PureComponent<MonacoEditorProps> {
    // static contextType = AppContext;
    // context!: React.ContextType<typeof AppContext>;
    editor!: any;
    lastValue?: string;
    lastChangeValue?: string;

    static defaultProps = {
        minHeight: 48,
        maxHeight: 480,
    }

    state = {
        height: this.props.minHeight || 48,
    }

    handleChange: OnChange = (value, ev) => {
        this.lastValue = value;
        this.props.onChange?.(value, ev);
    }

    handleMount: OnMount = (editor, monaco) => {
        const { onMount, onBlur, defaultValue = '', height, minHeight, maxHeight } = this.props;

        this.editor = editor;
        onMount?.(editor, monaco);

        this.lastValue = this.lastValue || defaultValue;
        if (onBlur && this.lastValue != null && this.lastValue !== this.lastChangeValue) {
            this.lastChangeValue = this.lastValue;
            editor.onDidBlurEditorText(() => onBlur(this.lastValue!));
        }

        // enable auto-resize if a fixed height is not provided
        if (!height && minHeight && maxHeight) {
            const updateHeight = () => {
                const contentHeight = _.clamp(editor.getContentHeight(), minHeight, maxHeight);
                const container = editor.getContainerDomNode();
                editor.layout({ height: contentHeight, width: container.offsetWidth });
                this.setState({ height });
            }
            editor.onDidContentSizeChange(updateHeight);
            updateHeight();
        } else this.setState({ height: this.props.height });
    }

    render() {
        const { className, style, options, ...props } = this.props;
        const { height } = this.state;

        return <div className={className} style={{ ...style, height, border: '1px solid #ccc' }}>
            <MonacoEditor
                defaultLanguage='json'
                options={options ? { ...DEFAULT_OPTIONS, ...options } : DEFAULT_OPTIONS}
                // {...this.context.editor.value}
                height={height /* must be before ...props */}
                {...props}
                onChange={this.handleChange}
                onMount={this.handleMount}
            />
        </div>;
    }
}
