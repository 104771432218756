import _ from 'lodash';
import { PureComponent } from 'react';
import {withState} from 'recompose';

import {Row, FlexItem, Button, Label, LabelName} from '../../ui';
import {
    IngredientsRoot,
    Result,
    Filter,
    FieldWrapper,
    ExpandButton,
    Link,
    Count,
} from './Ingredients.styled.js';
import { errorHandler, escapeRegExp } from '../../utils/utils';
import { downloadCSV } from '../../utils/download';

import TextArea from '../../components/TextArea/TextArea';
import { legacyApi } from '@pinto/api-client';

const MAX = 250;
const COMPILED_SYMBOL = Symbol('compiled');
const SPACE_REG = /\s+/g;

const sanitize = str => (str || '').trim().toLowerCase();

const makeRegList = (arr, flags='i', {extended=false, sort=false, prefix=false, suffix=false}={}) => {
    if (!arr || arr.length < 1) throw new Error('Invalid size, expected at least 1');
    if (sort) arr = Array.from(arr).sort((a, b) => b.length - a.length);
    const str = arr.map(escapeRegExp).join('|');
    const extra = extended ? '(?:[^;]*(?:;|$))' : '';
    return new RegExp(
        `${prefix ? '^' : ''}\\b(?:${str})(?:e?s)?(?:\\b|$)${extra}${suffix ? '$' : ''}`,
        flags
    );
};

export default class Ingredients extends PureComponent {

    state = {
        value: null,
        excludeValue: null,
        selected: null,
        limit: MAX,
        filter: null,
        showInverse: false,
        extendedReg: true,

        ingredientList: null,
        fullIngredientList: null,
        sanitizedIngredientList: null,
        majorityIngredientList: null,
        nameList: null,
        bucket: 'fullIngredientList',
    }

    async UNSAFE_componentWillMount () {
        const ingredientDistinct = key =>
            legacyApi.post('/admin/aggregate/Product', [
                { $project: { ingredient: '$' + key } },
                { $unwind: '$ingredient' },
                { $group: {
                    _id: '$ingredient',
                    count: { $sum: 1 },
                } },
                { $sort: { count: -1 } },
            ])
            .then(list => list.map(x => ({ str: x._id, count: x.count })));

        try {
            const [
                ingredientList,
                fullIngredientList,
                sanitizedIngredientList,
                majorityIngredientList,
                nameList,
            ] = await Promise.all([
                ingredientDistinct('ingredientList'),
                ingredientDistinct('fullIngredientList'),
                ingredientDistinct('sanitizedIngredientList'),
                ingredientDistinct('majorityIngredientList'),
                legacyApi.get('/product', { distinct: 'name' })
                    .then(list => list.map(str => ({ str, count: 1 }))),
            ]);

            const clean = arr =>  arr.map(x => ({ ...x, clean: sanitize(x.str) }));

            this.setState({
                ingredientList: clean(ingredientList),
                fullIngredientList: clean(fullIngredientList),
                sanitizedIngredientList: clean(sanitizedIngredientList),
                majorityIngredientList: clean(majorityIngredientList),
                nameList: clean(nameList),
            });
        } catch (ex) {
            errorHandler(ex);
        }
    }

    filter (value=this.state.value, excludeValue=this.state.excludeValue) {
        const {bucket, extendedReg} = this.state;
        const data = this.state[bucket];

        const getReg = (str, regMap=_.identity) => {
            if (!str) return null;

            const arr = _.uniq((str || '').split(/[\n;]+/).map(sanitize).map(regMap))
                .filter(str => str && str.length >= 3)
                .sort((a, b) => b.length - a.length);

            if (!arr.length) return null;

            const regStr = extendedReg ? makeRegList(arr, 'g') : arr.map(escapeRegExp).join('|');
            return new RegExp(regStr, 'g');
        }

        const reg = getReg(value);
        const excludeReg = getReg(excludeValue);

        if (!reg) return this.setState({value: null, selected: [], filteredOut: []});

        const filteredOut = [];

        const selected = data.map(({str, clean, count}) => {
            const matchList = [];

            let source = clean;
            if (excludeReg) {
                const newClean = clean.replace(excludeReg, ' ').replace(SPACE_REG, ' ').trim();
                clean = newClean;
            }

            while (true) {
                const match = reg.exec(clean);

                if (!match) break;

                matchList.push({
                    text: match[0],
                    pos: match.index,
                });
            }

            if (!matchList.length) {
                filteredOut.push(clean);
                return null;
            }

            let template = clean;
            const valueMap = {};

            for (let index = matchList.length - 1; index >= 0; --index) {
                const id = `<<${index}>>`;
                const {text, pos} = matchList[index];
                template = template.slice(0, pos) + id + template.slice(pos + text.length);
                valueMap[id] = text;
            }

            return {
                source,
                str,
                count,
                clean,
                template,
                valueMap,
                hasFalsePositives: source !== clean,
                start: matchList[0].pos === 0,
                end: matchList[matchList.length - 1].pos +
                    matchList[matchList.length - 1].text.length === clean.length,
                middle: matchList.some(({pos, text}) =>
                    pos > 0 && pos + text.length < clean.length
                ),
            };
        })
        .filter(x => !!x);

        this.setState({
            limit: MAX,
            value,
            selected,
            filteredOut,
        });
    }

    filterDebounced = _.debounce(this.filter, 200)

    handleChange = value => this.filterDebounced(value)

    handleExcludeChange = value =>
        this.setState({ excludeValue: value }, () => this.filterDebounced())

    handleBucketChange = event =>
        this.setState({ bucket: event.target.value }, () => this.filter(this.state.value))

    handleInverseChange = event => this.setState({ showInverse: event.target.checked })

    handleExtendedChange = event =>
        this.setState({ extendedReg: event.target.checked }, () => this.filter(this.state.value))

    handleDownload = () => {
        const {value, showInverse, selected, filteredOut, bucket} = this.state;

        let hashed = 'ingredient-' + value.replace(/[^a-z0-9-_]/ig, ' ').replace(/\s+/g, '_');

        if (showInverse) {
            return downloadCSV(
                `${hashed}-inverse.csv`,
                [bucket],
                filteredOut.map(str => ({[bucket]: str}))
            );
        }

        downloadCSV(
            `${hashed}.csv`,
            ['source', 'products', 'withoutFalsePositives', 'hasFalsePositives', 'matches'],
            selected.map(obj => ({
                source: obj.source,
                products: obj.count,
                withoutFalsePositives: obj.clean,
                hasFalsePositives: obj.hasFalsePositives ? '✔︎' : '',
                matches: Object.values(obj.valueMap),
            }))
        );
    }

    render () {
        const {
            fullIngredientList,
            selected,
            filter,
            value,
            limit,
            filteredOut,
            showInverse,
            bucket,
            extendedReg,
        } = this.state;

        if (!fullIngredientList) return <IngredientsRoot>Loading Ingredients ...</IngredientsRoot>;

        let resultList = selected;

        if (showInverse) resultList = Array.from(filteredOut).sort();
        else {
            if (filter) resultList = _.filter(resultList, filter);
        }

        const moreThanMax = _.size(resultList) > limit;
        resultList = moreThanMax ? resultList.slice(0, limit) : resultList;

        let link = `/model/product/query?select=name,slug,${bucket}&query=` + encodeURIComponent(JSON.stringify({
            [bucket]: { $in: _.uniq((selected || []).slice(0, 500).map(x => x.str)) },
        }));

        if (showInverse) {
            // do nothing
        } else {
            const compileTemplate = obj => {
                obj[COMPILED_SYMBOL] = obj[COMPILED_SYMBOL] ||
                    {
                        data: obj,
                        source: obj.hasFalsePositives ? obj.source : null,
                        html: _.reduce(
                            obj.valueMap,
                            ((result, text, key) => result.replace(key, `<b style="color:crimson">${text}</b>`)),
                            obj.template
                        ),
                    }

                return obj[COMPILED_SYMBOL];
            };

            resultList = !resultList ? [] : resultList.map(compileTemplate);
        }

        const renderFilter = (name, label=name) =>
            <Filter selected={filter === name} onClick={() => this.setState({ filter: name })}>
                {label}
            </Filter>;

        return (
            <IngredientsRoot>
                <Row flexStart>
                    <FieldWrapper>
                        <TextArea
                            placeholder='search for'
                            onChange={_.noop}
                            onInput={this.handleChange}
                            autoFocus
                            maxHeight={300}
                            style={{marginBottom: '1rem', padding: '1rem', fontSize: '1rem'}}
                        />

                        <TextArea
                            placeholder='false positives'
                            onChange={_.noop}
                            onInput={this.handleExcludeChange}
                            maxHeight={300}
                            style={{marginBottom: '1rem', padding: '1rem', fontSize: '1rem'}}
                        />

                        <Label>
                            <LabelName>Search on:</LabelName>
                            <select
                                onChange={this.handleBucketChange}
                                style={{marginLeft: '.5rem'}}
                            >
                                <option>fullIngredientList</option>
                                <option>ingredientList</option>
                                <option>sanitizedIngredientList</option>
                                <option>majorityIngredientList</option>
                                <option>nameList</option>
                            </select>
                        </Label>

                        <Label>
                            <LabelName>Show things that didn't match</LabelName>
                            <input type='checkbox' checked={showInverse} onChange={this.handleInverseChange} />
                        </Label>

                        <Label title='This is the type of search builder uses. It only matches full words and it also matches "tomatoes" when searching for "tomato". Disabling this will do a simple string that will match partial words'>
                            <LabelName>Use extended search</LabelName>
                            <input type='checkbox' checked={extendedReg} onChange={this.handleExtendedChange} />
                        </Label>
                    </FieldWrapper>

                    <FlexItem grow style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        {   !selected || !value ? 'Start typing to get results' :
                            !selected.length ? 'No results' :
                            <div style={{whiteSpace: 'pre-wrap'}}>
                                {showInverse ? null :
                                    <h2>
                                        {!moreThanMax ? null : `Showing ${resultList.length} / `}
                                        {selected.length} results for "{(value || '').split('\n').join('; ').replace(/\s+/g, ' ').trim().slice(0, 50)}"
                                    </h2>
                                }

                                {showInverse ? null :
                                    <Row margin={0.5}>
                                        Show matches at:
                                        {renderFilter(null, 'all')}
                                        {renderFilter('start', `start (${_.filter(selected, 'start').length})`)}
                                        {renderFilter('end', `end (${_.filter(selected, 'end').length})`)}
                                        {renderFilter('middle', `middle (${_.filter(selected, 'middle').length})`)}
                                    </Row>
                                }

                                <div style={{margin: '1rem 0'}}>
                                    <Link href={link}>Product Query</Link>
                                    <Link onClick={this.handleDownload}>Download to CSV</Link>
                                </div>

                                {resultList.map((item, i) =>
                                    <ResultToggle
                                        key={i}
                                        html={item.html || item}
                                        text={item.data.clean}
                                        source={item.source}
                                        count={item.data.count}
                                        bucket={bucket}
                                    />
                                )}

                                {!moreThanMax ? null :
                                    <Button block primary margin={1} centered
                                        onClick={() => this.setState({limit: limit + MAX})}
                                    >
                                        Show {MAX} More
                                    </Button>
                                }
                            </div>
                        }
                    </FlexItem>
                </Row>
            </IngredientsRoot>
        );
    }

}

const ResultToggle = withState('visible', 'setVisible', false)(
    ({text, html, source, visible, setVisible, count, bucket}) =>
        <Result>
            <FlexItem grow dangerouslySetInnerHTML={{__html: visible && source ? source : html}} />

            <Count
                href={`https://admin.pinto.co/query/Product/query?query=${encodeURIComponent(JSON.stringify({ [bucket]: `~\\b${text}e?s?\\b` }))}`}
                target='_blank'
            >{count} products</Count>

            {!source ? null :
                <ExpandButton title='Show full content' onClick={() => setVisible(!visible)}>
                    [{visible ? '-' : '+'}]
                </ExpandButton>
            }
        </Result>
);
