import type { ExternalData } from './hooks/useExternalData';

let external: ExternalData = { loading: true } as any;

export default external;

// still hacky, but less so
// now hooks/useExternalData fetches it properly, and it also shamelessly overrides it here
export const shamefullySetExternalData = (data: ExternalData) => {
    Object.assign(external, data);
}
