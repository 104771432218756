import _ from 'lodash';
import { useBuilderContext } from 'src/utils/BuilderContext';
import { ResourceDrawer } from './ResourceDrawer';

export const GlobalResourceDrawer: React.FC = () => {
    const ctx = useBuilderContext();

    return <ResourceDrawer
        value={[]}
        onChange={_.noop}
        onClose={_.noop}
        {...ctx.resourceDrawer}
        isOpen={!!ctx.resourceDrawer}
    />
};
